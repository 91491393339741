import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { groupBy } from 'lodash';
import ReportService from '../../../services/ReportService';
import { Report, ReportCategory } from '../../../models/Report';
import TransactionRecord from '../../../models/TransactionRecord';
import { RedemptionActions } from '../../../store/Redemption';
import { selectRedeemedReportsList } from '../../../store/Redemption/selectors';
import styles from '../../../styles/modules/RedemptionTab.module.scss';
import moment from 'moment';
import MyReportMonth from '../components/MyReportMonth/MyReportMonth';
import { useTranslation } from 'react-i18next';

interface DisplayReports {
	month: string;
	displayMonth: string;
	reports: Report[];
}

interface Props {
	redeemedReportsList?: TransactionRecord[];
	fetchRedeemedReportsList: () => void;
}

const ReportsTab = ({ redeemedReportsList, fetchRedeemedReportsList }: Props): JSX.Element => {
	const { t } = useTranslation();
	const [displayReports, setDisplayReports] = useState<DisplayReports[] | null>(null);
	const [reportCategories, setReportCategories] = useState<ReportCategory[] | null>(null);

	useEffect(() => {
		ReportService.fetchReportCategories()
			.then(({ data: { data } }) => setReportCategories(data))
			.catch((err) => console.log(err.message));
	}, []);

	useEffect(() => {
		fetchRedeemedReportsList();
	}, [fetchRedeemedReportsList]);

	useEffect(() => {
		if (redeemedReportsList) {
			const grouped = groupBy(redeemedReportsList, (record) => moment(record.createdAt).format('YYYY MMMM|YYYYMMMM'));
			const groupedArr: DisplayReports[] = Object.entries(grouped)
				.map(([m, r]) => ({ month: m.split('|')[1], displayMonth: m.split('|')[0], reports: r.flatMap((tr) => tr.reports) }))
				.sort((a, b) => +b.month - +a.month);
			setDisplayReports(groupedArr);
		}
	}, [redeemedReportsList]);

	return (
		<div className={styles.redemptionTab}>
			{displayReports?.map(({ month, displayMonth, reports }, i) => (
				<MyReportMonth
					key={month}
					displayMonth={displayMonth}
					reports={reports}
					reportCategories={reportCategories}
					defaultExpanded={i === 0}
				/>
			))}
			{displayReports !== null && displayReports.length === 0 && (
				<p>{t('redemption:noRedeemedReport')}</p>
			)}
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	redeemedReportsList: selectRedeemedReportsList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchRedeemedReportsList: () => dispatch(RedemptionActions.fetchRedeemedReportsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTab);
