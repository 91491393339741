import {action} from 'typesafe-actions';
import {Mail} from '../../models/Mail';
import {SystemProperty} from '../../models/SystemProperty';

export const FETCH_MAIL = 'profile/FETCH_MAIL';
export const FETCH_MAIL_SUCCESS = 'profile/FETCH_MAIL_SUCCESS';
export const FETCH_MAIL_FAIL = 'profile/FETCH_MAIL_FAIL';

export const FETCH_ALL_BOOKMARKS = 'profile/FETCH_ALL_BOOKMARKS';
export const FETCH_ALL_BOOKMARKS_SUCCESS =
  'profile/FETCH_ALL_BOOKMARKS_SUCCESS';
export const FETCH_ALL_BOOKMARKS_FAIL = 'profile/FETCH_ALL_BOOKMARKS_FAIL';

export const FETCH_SYSTEM_PROPERTY = 'profile/FETCH_SYSTEM_PROPERTY';
export const FETCH_SYSTEM_PROPERTY_SUCCESS =
  'profile/FETCH_SYSTEM_PROPERTY_SUCCESS';
export const FETCH_SYSTEM_PROPERTY_FAIL = 'profile/FETCH_SYSTEM_PROPERTY_FAIL';

export const FETCH_TRANSACTION_HISTORY = 'profile/FETCH_TRANSACTION_HISTORY';
export const FETCH_TRANSACTION_HISTORY_SUCCESS =
  'profile/FETCH_TRANSACTION_HISTORY_SUCCESS';
export const FETCH_TRANSACTION_HISTORY_FAIL =
  'profile/FETCH_TRANSACTION_HISTORY_FAIL';

export const fetchMail = (page: number) => action(FETCH_MAIL, {page});
export const fetchMailSuccess = (mail: Mail[]) =>
  action(FETCH_MAIL_SUCCESS, {mail});
export const fetchMailFail = (error: string) =>
  action(FETCH_MAIL_FAIL, {error});

export const fetchAllBookmarks = () => action(FETCH_ALL_BOOKMARKS);
export const fetchAllBookmarksSuccess = (bookmarkList: any[]) =>
  action(FETCH_ALL_BOOKMARKS_SUCCESS, {bookmarkList});
export const fetchAllBookmarksFail = (error: string) =>
  action(FETCH_ALL_BOOKMARKS_FAIL, {error});

export const fetchSystemProperty = () => action(FETCH_SYSTEM_PROPERTY);
export const fetchSystemPropertySuccess = (systemProperty: SystemProperty) =>
  action(FETCH_SYSTEM_PROPERTY_SUCCESS, {systemProperty});
export const fetchSystemPropertyFail = (error: string) =>
  action(FETCH_SYSTEM_PROPERTY_FAIL, {error});

export const fetchTransactionHistory = () => action(FETCH_TRANSACTION_HISTORY);
export const fetchTransactionHistorySuccess = (transactionHistory: any[]) =>
  action(FETCH_TRANSACTION_HISTORY_SUCCESS, {transactionHistory});
export const fetchTransactionHistoryFail = (error: string) =>
  action(FETCH_TRANSACTION_HISTORY_FAIL, {error});
