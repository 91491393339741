import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import BACK_BUTTON from '../../assets/images/backBtn.png';
import BOOKMARK_BUTTON from '../../assets/images/bookmarkBtn.png';
import BOOKMARKED_BUTTON from '../../assets/images/bookmarkedBtn.png';
import './WrapperHeader.scss';

interface Props {
	pageType: 'news' | 'reports';
	isBookmarked?: boolean;
	onClickBookmark?: () => void;
}

const WrapperHeader = ({ pageType, isBookmarked, onClickBookmark }: Props): JSX.Element => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<header id="wrapper-header">
			<div className="common-header-container justify-align-center">
				<div className="width-control">
					<div className="wrapper-header-content">
						<button onClick={() => history.goBack()} type="button">
							<img src={BACK_BUTTON} alt="Back" />
						</button>
						<h2>{t(`navigation:${pageType}`)}</h2>
						{pageType === 'news' && (
							<button type="button" onClick={onClickBookmark}>
								<img src={isBookmarked ? BOOKMARKED_BUTTON : BOOKMARK_BUTTON} alt={isBookmarked ? 'Bookmarked' : 'Bookmark'} />
							</button>
						)}
					</div>
				</div>
			</div>
		</header>
	);
};

export default WrapperHeader;
