import {ActionType} from 'typesafe-actions';
import * as NewsActions from './actions';
import {News} from '../../models/News';

export interface State {
  news: News[];

  selectedNews: News | null;

  error?: string;
}

export const initialState: State = {
  news: [],

  selectedNews: null,
};

type NewsActionsType = ActionType<typeof NewsActions>;

export function reducer(
  state: State = initialState,
  action: NewsActionsType,
): State {
  switch (action.type) {
    case NewsActions.FETCH_NEWS:
    case NewsActions.FETCH_NEWS_BY_ID: {
      return {
        ...state,
        error: undefined,
      };
    }
    case NewsActions.FETCH_NEWS_SUCCESS:
    case NewsActions.FETCH_NEWS_BY_ID_SUCCESS:
    case NewsActions.FETCH_NEWS_FAIL:
    case NewsActions.FETCH_NEWS_BY_ID_FAIL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
