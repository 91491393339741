import { Backdrop, Fade } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/styles';
import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import selectShowLoadingOverlay from '../../store/LoadingOverlay/selector';
import './LoadingOverlay.scss';

interface Props {
  show: boolean;
}

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxWidth: '90%',
    outline: '0',
    pointerEvents: 'none',
    padding: '1.5rem',
    zIndex: 999,
  },
}));

const LoadingOverlay = ({ show }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={show}
      onClose={() => null}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={show}>
        <div className={classes.paper}>
          <ClipLoader color="#fff" />
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  show: selectShowLoadingOverlay(state),
});

export default connect(mapStateToProps)(LoadingOverlay);
