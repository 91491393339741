import { takeLatest, put, call } from 'redux-saga/effects';
import * as ProfileActions from './actions';
import BookmarkService from '../../services/BookmarkService';
import UserService from '../../services/UserService';
// import {selectMail} from './selectors';

export function* handleFetchMail() {
  // action: ActionType<typeof ProfileActions.fetchMail>,
  try {
    // placeholder
    // console.log(action);

    // const response = yield call(MailService.fetchMail, action.payload.page);

    // const {
    //   data: {
    //     data: {data},
    //   },
    // } = response;

    // const mail = yield select(selectMail);

    // const newMailArr = mail.concat(data);

    // console.log('handleFetchMail', newMailArr);

    // yield put(ProfileActions.fetchMailSuccess(newMailArr));
  } catch (err) {
    yield put(ProfileActions.fetchMailFail(err.message));
  }
}

export function* watchFetchMail() {
  yield takeLatest(ProfileActions.FETCH_MAIL, handleFetchMail);
}

export function* handleFetchAllBookmarks() {
  // action: ActionType<typeof ProfileActions.fetchAllBookmarks>,
  try {
    // placeholder
    // console.log('helloFrom handle FetchAllBookmarks', action);

    const response = yield call(BookmarkService.fetchAllBookmarks);

    const {
      data: {
        data: {data},
      },
    } = response;

    // console.log('AllBookmarksSaga', data);

    yield put(ProfileActions.fetchAllBookmarksSuccess(data));
  } catch (err) {
    yield put(ProfileActions.fetchAllBookmarksFail(err.message));
  }
}

export function* watchFetchAllBookmarks() {
  yield takeLatest(ProfileActions.FETCH_ALL_BOOKMARKS, handleFetchAllBookmarks);
}

export function* handleFetchSystemProperty() {
  // action: ActionType<typeof ProfileActions.fetchSystemProperty>,
  try {
    // placeholder
    // console.log(action);

    const response = yield call(UserService.getSystemProperty);

    const {
      data: {data},
    } = response;

    // console.log('handleFetchSystemProperty', data);

    yield put(ProfileActions.fetchSystemPropertySuccess(data));
  } catch (err) {
    yield put(ProfileActions.fetchSystemPropertyFail(err.message));
  }
}

export function* watchFetchSystemProperty() {
  yield takeLatest(
    ProfileActions.FETCH_SYSTEM_PROPERTY,
    handleFetchSystemProperty,
  );
}

export function* handleFetchTransactionHistory() {
  // action: ActionType<typeof ProfileActions.fetchTransactionHistory>,
  try {
    // placeholder
    // console.log(action);

    const response = yield call(UserService.getTransactionHistory);

    const {
      data: {
        data: {data},
      },
    } = response;

    // console.log('handleFetchTransactionHistory', data);

    yield put(ProfileActions.fetchTransactionHistorySuccess(data));
  } catch (err) {
    yield put(ProfileActions.fetchTransactionHistoryFail(err.message));
  }
}

export function* watchFetchTransactionHistory() {
  yield takeLatest(
    ProfileActions.FETCH_TRANSACTION_HISTORY,
    handleFetchTransactionHistory,
  );
}
