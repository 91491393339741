import React from 'react';
import { Link } from 'react-router-dom';
import CLOSE_ICON from '../../assets/images/closeBtnCircle.png';
import NEXT_ICON from '../../assets/images/nextBtn.png';
import './LongItemCard.scss';

interface LinkBtnProps {
	to?: string;
	onClick?: () => void;
	children?: React.ReactNode;
}

const LinkBtn = ({ to, onClick, children }: LinkBtnProps): JSX.Element => (
	to ? <Link to={to} className="long-item-card-content">{children}</Link>
	: <button type="button" className="long-item-card-content" onClick={onClick}>{children}</button>
);

interface Props {
	to?: string;
	img?: string;
	title?: string;
	subTitle?: string;
	roundImg?: boolean;
	showArrow?: boolean;
	onClose?: () => void;
	onClick?: () => void;
}

const LongItemCard = ({ to, img, title, subTitle, roundImg, showArrow, onClose, onClick }: Props): JSX.Element => (
	<div className="long-item-card">
		<LinkBtn to={to} onClick={onClick}>
			<div className={`long-item-card-img ${roundImg ? 'mod-round' : ''}`}>
				<img src={img} alt="" />
			</div>
			<div className="long-item-card-text">
				<span className="long-item-card-title">{title}</span>
				{!!subTitle && <span className="long-item-card-subtitle">{subTitle}</span>}
			</div>
			{showArrow && (
				<div className="long-item-card-arrow">
					<img src={NEXT_ICON} alt=">" />
				</div>
			)}
		</LinkBtn>
		{!!onClose && (
			<button className="long-item-card-close-button" type="button" onClick={onClose}>
				<img src={CLOSE_ICON} alt="x" />
			</button>
		)}
	</div>
);

export default LongItemCard;
