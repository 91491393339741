import {call, put, takeLatest} from 'redux-saga/effects';
import ProductMoreContent from '../../models/ProductMoreContent';
import ProductsService from '../../services/ProductsService';
import * as ProductsActions from './actions';

export function* handleFetchProductsType() {
  try {
    const response = yield call(ProductsService.fetchProductsTypes);

    const {
      data: {data},
    } = response;

    yield put(ProductsActions.fetchProductTypesSuccess(data));
  } catch (err) {
    yield put(ProductsActions.fetchProductTypesFail(err.message));
  }
}

export function* watchFetchProductsType() {
  yield takeLatest(
    ProductsActions.FETCH_PRODUCT_TYPES,
    handleFetchProductsType,
  );
}

export function* handleFetchMembership() {
  try {
    const response = yield call(ProductsService.fetchMembership);

    const {
      data: {
        data: {data},
      },
    } = response;

    // console.log('handleFetchMembership', data);

    yield put(ProductsActions.fetchMembershipSuccess(data));
  } catch (err) {
    yield put(ProductsActions.fetchMembershipFail(err.message));
  }
}

export function* watchFetchMembership() {
  yield takeLatest(ProductsActions.FETCH_MEMBERSHIP, handleFetchMembership);
}

export function* handleFetchPackageAll() {
  try {
    const response = yield call(ProductsService.fetchPackageAll);

    const {
      data: {
        data: {data},
      },
    } = response;

    // console.log('FETCH_PACKAGE_ALL', data);

    yield put(ProductsActions.fetchPackageAllSuccess(data));
  } catch (err) {
    yield put(ProductsActions.fetchPackageAllFail(err.message));
  }
}

export function* watchFetchPackageAll() {
  yield takeLatest(ProductsActions.FETCH_PACKAGE_ALL, handleFetchPackageAll);
}

export function* handleFetchMoreContent() {
  try {
    const response = yield call(ProductsService.fetchMoreContent);

    const {
      data: {data},
    } = response;

    // console.log('handleFetchMoreContent', data);

    yield put(ProductsActions.fetchMoreContentSuccess(data.filter((d: ProductMoreContent) => d.isEnabled)));
  } catch (err) {
    yield put(ProductsActions.fetchMoreContentFail(err.message));
  }
}

export function* watchFetchMoreContent() {
  yield takeLatest(ProductsActions.FETCH_MORE_CONTENT, handleFetchMoreContent);
}
