import { ActionType } from "typesafe-actions";
import { UserV2 } from "../../models/User";
import * as AuthActions from "./actions";

export interface State {
  test: string | null;
  accessToken: string | null;
  user: UserV2 | undefined;
  selectedLang: string;
  isGuest: boolean;

  error?: string;
}

export const initialState: State = {
  test: null,
  selectedLang: "en",
  accessToken: null,
  user: undefined,

  isGuest: false,
};

type AuthActionsType = ActionType<typeof AuthActions>;

export function reducer(
  state: State = initialState,
  action: AuthActionsType
): State {
  switch (action.type) {
    case AuthActions.SWITCH_LANGUAGE_FROM_APP:
      return {
        ...state,
        selectedLang: action.payload.language,
      };
    case AuthActions.FETCH_USER:
    case AuthActions.CHANGE_LANGUAGE:
    case AuthActions.LOGIN: {
      return {
        ...state,
        isGuest: false,
        error: undefined,
      };
    }
    case AuthActions.FETCH_USER_SUCCESS:
      return {
        ...state,
        isGuest: false,
        user: action.payload.user,
      };
    case AuthActions.CHANGE_LANGUAGE_SUCCESS:
    case AuthActions.LOGIN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isGuest: false,
      };
    }
    case AuthActions.SET_GUEST: {
      return {
        ...state,
        error: undefined,
      };
    }
    case AuthActions.SET_GUEST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case AuthActions.FETCH_USER_FAIL:
    case AuthActions.CHANGE_LANGUAGE_FAIL:
      return {
        ...state,
        error: action.payload.error,
      };
    case AuthActions.SET_GUEST_FAIL:
    case AuthActions.LOGIN_FAIL: {
      return {
        ...initialState,
        error: action.payload.error,
      };
    }

    case AuthActions.LOGOUT:
      return initialState;

    default:
      return state;
  }
}
