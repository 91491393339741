import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import CommonHeader from '../../components/CommonHeader/CommonHeader';
import FormErrorContainer from '../../components/FormErrorContainer/FormErrorContainer';
import InfoModal from '../../components/InfoModal/InfoModal';
import MainLayout from '../../components/MainLayout/MainLayout';
import RoundButton from '../../components/RoundButton/RoundButton';
import SuccessContainer from '../../components/SuccessContainer/SuccessContainer';
import { emailRegex } from '../../helpers/helpers';
import UserService from '../../services/UserService';
import styles from '../../styles/modules/FormStyles.module.scss';
import '../Login/Login.scss';

interface SignupFormInputs {
  email?: string;
  password?: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  areaCode?: string;
  phone?: string;
}

const Signup = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		register,
    watch,
		handleSubmit,
		formState: { errors }
	} = useForm<SignupFormInputs>({ mode: 'onChange', defaultValues: { areaCode: '-' } });
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [popupMessage, setPopupMessage] = useState<string | null>(null);

  const onSubmit = (data: SignupFormInputs) => {
    let { email, password, firstName, lastName, areaCode, phone } = data;
    if (!email || !password || !firstName || !lastName || !areaCode || !phone) return;
    const submitBody = { email, password, firstName, lastName, phone: `${areaCode} ${phone}` };
    setSubmitting(true);
    UserService.register(submitBody)
      .then(({ data: { message }}) => setPopupMessage(message))
      .catch(({ message }) => setSubmitError(message))
      .finally(() => setSubmitting(false));
  };

	return (
    <>
      <InfoModal isOpen={!!popupMessage} onClose={() => setPopupMessage(null)}>
        <SuccessContainer
          title={t('register:registrationSuccess').toUpperCase()}
          content={'You’ve successfully registered!'}
          okText={t('register:goToLogin')}
          onOk={() => history.push('/')}
        />
      </InfoModal>
      <MainLayout title={t('login:register')}>
        <CommonHeader />
        <div className="page-main justify-align-center">
          <div className="width-control">
            <div className={styles.formContainer}>
              <form className={`${styles.form} login-form`} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.generalInputContainer}>
                  <div className={styles.signupInputItem}>
                    <div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputEmail}`}>
                      <input
                        {...register(
                          'email',
                          {
                            required: { value: true, message: t('systemMsg:emptyEmail') },
                            pattern: { value: emailRegex, message: t('systemMsg:invalidEmailAddress') },
                          })}
                        type="text"
                        placeholder={t('register:fields:email')}
                      />
                    </div>
                    <FormErrorContainer errors={errors} name="email" />
                  </div>
                  <div className={styles.signupInputItem}>
                    <div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputPassword}`}>
                      <input
                        {...register(
                          'password',
                          {
                            required: { value: true, message: t('systemMsg:emptyPassword') },
                            validate: (v: string) => (v.length >= 6 && v.length <= 12) || `${t('register:errors:pwLimit')}`,
                          },
                        )}
                        type="password"
                        placeholder={t('register:fields:password')}
                        maxLength={12}
                      />
                    </div>
                    <FormErrorContainer errors={errors} name="password" />
                  </div>
                  <div className={styles.signupInputItem}>
                    <div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputPassword}`}>
                      <input
                        {...register(
                          'confirmPassword',
                          {
                            validate: (v: string) => v === watch('password') || `${t('register:errors:pwShouldMatch')}`,
                          },
                        )}
                        type="password"
                        placeholder={t('register:fields:confirmPassword')}
                      />
                    </div>
                    <FormErrorContainer errors={errors} name="confirmPassword" />
                  </div>
                  <div className={styles.signupInputItem}>
                    <div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputEmail}`}>
                      <input
                        {...register(
                          'firstName',
                          {
                            required: { value: true, message: t('register:errors:firstNameRequired') },
                            validate: (v: string) => v.length <= 32 || `${t('register:errors:nameMaxLimit')}`,
                          },
                        )}
                        type="text"
                        placeholder={t('register:fields:firstName')}
                        maxLength={32}
                      />
                    </div>
                    <FormErrorContainer errors={errors} name="firstName" />
                  </div>
                  <div className={styles.signupInputItem}>
                    <div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputEmail}`}>
                      <input
                        {...register(
                          'lastName',
                          {
                            required: { value: true, message: t('register:errors:lastNameRequired') },
                            validate: (v: string) => v.length <= 32 || `${t('register:errors:nameMaxLimit')}`,
                          },
                        )}
                        type="text"
                        placeholder={t('register:fields:lastName')}
                        maxLength={32}
                      />
                    </div>
                    <FormErrorContainer errors={errors} name="lastName" />
                  </div>
                  <div className={styles.signupInputItem}>
                    <div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputEmail}`}>
                      <select {...register('areaCode', { validate: (v: string) => v !== '-' || `${t('register:errors:selectPhoneZip')}` })}>
                        <option value="-" disabled>{t('register:fields:areaCode')}</option>
                        <option value="852">852</option>
                        <option value="853">853</option>
                        <option value="86">86</option>
                      </select>
                    </div>
                    <div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputEmail}`}>
                      <input
                        {...register(
                          'phone',
                          {
                            required: { value: true, message: t('register:errors:enterPhoneNumber') },
                            validate: (v: string) => watch('areaCode') === '-'
                              ? `${t('register:errors:selectPhoneZip')}`
                              : watch('areaCode') === '86'
                                ? v.length === 11 || `${t('register:errors:cnPhoneNumberPattern')}`
                                : v.length === 8 || `${t('register:errors:mc&hkPhoneNumberPattern')}`,
                          },
                        )}
                        type="text"
                        placeholder={t('register:fields:phoneNumber')}
                        maxLength={13}
                      />
                    </div>
                    <FormErrorContainer msg={errors.areaCode?.message ?? errors.phone?.message} />
                  </div>
                </div>
                <RoundButton text={t('register:register')} type="submit" loading={submitting} />
                <FormErrorContainer msg={submitError} />
              </form>
              <div className="login-register-now">
                <span>{t('register:alreadyHaveAccount')}</span>
                <Link to="/">{t('register:loginNow')}</Link>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
	);
};

export default Signup;
