import React from "react";
import { Link } from "react-router-dom";
// import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ClipLoader } from "react-spinners";
import "./RoundButton.scss";

interface Props {
  className?: string;
  text: string;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  onClick?: () => void;
  link?: string;
  disabled?: boolean;
  loading?: boolean;
  type?: "button" | "submit";
}

const RoundButton = ({
  className = '',
  text,
  startIcon,
  endIcon,
  onClick,
  link,
  disabled,
  loading,
  type = "button",
}: Props): JSX.Element => {
  // const { locale } = useLocalization();

  return link === undefined ? (
    <button
      className={`round-button ${loading ? "mod-loading" : ""} ${
        disabled ? "mod-disabled" : ""
      } ${className}`}
      type={type === "submit" ? "submit" : "button"}
      onClick={!loading && !disabled ? onClick : undefined}
      disabled={disabled}
    >
      {startIcon}
      <p>{text}</p>
      {endIcon}
      {loading && (
        <div className="round-button-loading">
          <ClipLoader size={16} color="#fff" />
        </div>
      )}
    </button>
  ) : link.startsWith('/') ? (
    <Link className={`round-button ${className}`} to={link}>
      <p>{text}</p>
      {endIcon}
    </Link>
  ) : (
    <a className={`round-button ${className}`} href={link}>
      <p>{text}</p>
      {endIcon}
    </a>
  );
};

export default RoundButton;
