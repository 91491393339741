import { Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const CommonTab = withStyles({
	root: {
		backgroundColor: '#fff',
		color: '#515151',
		textTransform: 'initial',
		fontWeight: 600,
		fontSize: '16px',
	},
	selected: {
		fontWeight: 700,
		color: '#080808',
	},
})(Tab);

export const RoundTab = withStyles({
	root: {
		height: '30px',
		minHeight: '30px',
		backgroundColor: '#fff',
		color: '#222',
		textTransform: 'capitalize',
		fontWeight: 600,
		fontSize: '12px',
		boxSizing: 'border-box',
		padding: '4px',
	},
	selected: {
		backgroundColor: '#67dac0',
		color: '#fff',
		borderRadius: '16px',
	}
})(Tab);

export default CommonTab;
