import { combineReducers } from "redux";
import AuthReducer, { AuthReducerState } from "./Auth";
import NewsReducer, { NewsReducerState } from './News';
import ReportsReducer, { ReportsReducerState } from "./Reports";
import ProductsReducer, { ProductsReducerState } from "./Products";
import ProfileReducer, { ProfileReducerState } from "./Profile";
import RedemptionReducer, { RedemptionState } from "./Redemption";
import ErrorModalReducer, { ErrorModalState } from "./ErrorModal";
import LoadingOverlayReducer, { LoadingOverlayState } from "./LoadingOverlay";

export interface ReduxReducerState {
  auth: AuthReducerState;
  news: NewsReducerState;
  reports: ReportsReducerState;
  products: ProductsReducerState;
  profile: ProfileReducerState;
  redemption: RedemptionState;
  loadingOverlay: LoadingOverlayState;
  errorModal: ErrorModalState;
}

export const reducer = combineReducers<ReduxReducerState>({
  auth: AuthReducer,
  news: NewsReducer,
  reports: ReportsReducer,
  products: ProductsReducer,
  profile: ProfileReducer,
  redemption: RedemptionReducer,
  loadingOverlay: LoadingOverlayReducer,
  errorModal: ErrorModalReducer,
});
