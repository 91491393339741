import React, { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import CommonHeader from "../../components/CommonHeader/CommonHeader";
import CommonTabs from "../../components/CommonTabs/CommonTabs";
import CommonTab from "../../components/CommonTab/CommonTab";
import MainLayout from "../../components/MainLayout/MainLayout";
import { HomeBenefits, HomeNews, HomeReports, HomeMore } from './HomeTabs';
import { RootState } from "MyTypes";
import { News } from "../../models/News";
import { Report } from "../../models/Report";
import ProductMoreContent from "../../models/ProductMoreContent";
import GradingDetail from "../../models/GradingDetail";
import { NewsActions } from "../../store/News";
import { ReportsActions } from "../../store/Reports";
import { ProductsActions } from '../../store/Products';
import { selectNewsItems } from "../../store/News/selectors";
import { selectGradingDetails, selectHotReports } from "../../store/Reports/selectors";
import { selectMoreContent } from "../../store/Products/selectors";
import { useQuery } from "../../helpers/CustomHook";
import { selectUser } from "../../store/Auth/selectors";
import { UserV2 } from "../../models/User";

interface Props {
	user?: UserV2;
	news?: News[];
	hotReports?: Report[];
	moreContent?: ProductMoreContent[];
	gradingDetails?: GradingDetail[];
	fetchNews: () => void;
	fetchAllReports: () => void;
	fetchMoreContent: () => void;
	fetchGradingDetails: () => void;
}

const Home = ({
	user,
	news,
	hotReports,
	moreContent,
	gradingDetails,
	fetchNews,
	fetchAllReports,
	fetchMoreContent,
	fetchGradingDetails,
}: Props) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<string>('news');
  const query = useQuery();
 
  useEffect(() => {
	  fetchNews();
	  fetchAllReports();
	  fetchMoreContent();
	  fetchGradingDetails();
  }, [fetchNews, fetchAllReports, fetchMoreContent, fetchGradingDetails]);

  useEffect(() => {
	const queryTab = query?.get('tab');
	if (queryTab) {
		setCurrentTab(queryTab);
	}
  }, [query]);

  return (
    <MainLayout title={t('navigation:home')}>
      <CommonHeader />
      <div className="page-main tabs-page-align">
        <div className="width-control">
          <CommonTabs value={currentTab} onChange={(_, i) => setCurrentTab(i)} variant="scrollable">
            <CommonTab value="news" label={t('navigation:news')} />
            <CommonTab value="reports" label={t('navigation:lists')} />
            <CommonTab value="benefits" label={t('navigation:benefits')} />
            <CommonTab value="more" label={t('navigation:more')} />
          </CommonTabs>
		  {(() => {
			  switch (currentTab) {
				  	case 'news':
					  	return <HomeNews news={news} hotReports={hotReports} onViewAllReport={() => setCurrentTab('reports')} />;
					case 'reports':
						return <HomeReports />;
					case 'benefits':
						return <HomeBenefits user={user} />;
					case 'more':
						return <HomeMore moreContent={moreContent} gradingDetails={gradingDetails} />;
					default:
						return null;
			  }
		  })()}
        </div>
      </div>
    </MainLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
	user: selectUser(state),
	news: selectNewsItems(state),
	hotReports: selectHotReports(state),
	moreContent: selectMoreContent(state),
	gradingDetails: selectGradingDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchNews: () => dispatch(NewsActions.fetchNews()),
	fetchAllReports: () => dispatch(ReportsActions.fetchAllReports()),
	fetchMoreContent: () => dispatch(ProductsActions.fetchMoreContent()),
	fetchGradingDetails: () => dispatch(ReportsActions.fetchGradingDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
