// import React, { FC, useContext } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import News from "../pages/News/News";
import NewsDetails from "../pages/News/NewsDetails";
import NotFound from "../pages/NotFound/NotFound";
import ProductExperience from "../pages/Products/Experience/Experience";
import Membership from "../pages/Products/Membership/Membership";
import Packages from "../pages/Products/Packages/Packages";
import ProductDetails from "../pages/Products/ProductDetails/ProductDetails";
import Products from "../pages/Products/Products";
import ProductStore from "../pages/Products/Store/Store";
import Profile from "../pages/Profile/Profile";
import ProfileHistory from "../pages/Profile/ProfileHistory/ProfileHistory";
import SavedItems from "../pages/Profile/SavedItems/SavedItems";
import RedeemPackage from "../pages/Redemption/RedeemPackage/RedeemPackage";
import Redemption from "../pages/Redemption/Redemption";
import Reports from "../pages/Reports/Reports";
import TestResult from "../pages/Reports/TestResult";
import Policies from "../pages/Settings/Policies";
import Settings from "../pages/Settings/Settings";
import Signup from "../pages/Signup/Signup";
import { selectAccessToken } from "../store/Auth/selectors";
import { ReduxReducerState } from "../store/RootReducer";

interface Props {
  accessToken: string;
}

const MainRouter = ({ accessToken }: Props): JSX.Element => {
  return (
    <Router>
      {accessToken ? (
        <Switch>
          <Route exact path="/" component={Home} />

          <Route exact path="/reports" component={Reports} />
          <Route exact path="/reports/:id" component={TestResult} />

          <Route exact path="/news" component={News} />
          <Route exact path="/news/:id" component={NewsDetails} />

          <Route exact path="/products" component={Products} />
          <Route exact path="/products/experience/:id" component={ProductExperience} />
          <Route exact path="/products/store" component={ProductStore} />
          <Route exact path="/products/details/:id" component={ProductDetails} />
          <Route exact path="/products/packages" component={Packages} />
          <Route exact path="/products/membership" component={Membership} /> 

          <Route exact path="/redemption" component={Redemption} />
          <Route exact path="/redemption/packages/:tierKey" component={RedeemPackage} />

          <Route exact path="/settings" component={Settings} />
          <Route exact path="/settings/policies/:policy" component={Policies} />

          <Route exact path="/profile" component={Profile} />
          <Route exact path="/profile/savedItems" component={SavedItems} />
          <Route exact path="/profile/history" component={ProfileHistory} />

          <Route exact path="/signup" component={Signup} />
          <Route exact path="/login" component={Login} />

          <Route component={NotFound} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/signup" component={Signup} />
          <Route component={Login} />
        </Switch>
      )}
    </Router>
  );
};

const mapStateToProps = (state: ReduxReducerState) => ({
  accessToken: selectAccessToken(state),
});

export default connect(mapStateToProps, null)(MainRouter);

// export default MainRouter;
