import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import CommonHeader from '../../../components/CommonHeader/CommonHeader';
import MainLayout from '../../../components/MainLayout/MainLayout';
import ProductsService from '../../../services/ProductsService';
import Product from '../../../models/Product';
import { ClipLoader } from 'react-spinners';
import RatingMeter from '../../../components/RatingMeter/RatingMeter';
import CommentCard from '../components/CommentCard/CommentCard';
import { RootState } from 'MyTypes';
import { selectUser } from '../../../store/Auth/selectors';
import { connect } from 'react-redux';
import { UserV2 } from '../../../models/User';
import './ProductDetails.scss';

interface Props {
	user: UserV2;
}

const ProductDetails = ({ user }: Props): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	const { t, i18n: { language } } = useTranslation();

	const [product, setProduct] = useState<Product | null>(null);
	const [loading, setLoading] = useState(false);
	const [fetchError, setFetchError] = useState<string | null>(null);

	useEffect(() => {
		if (id && !product && !loading && !fetchError) {
			setLoading(true);
			ProductsService.fetchProductById(id)
				.then(({ data: { data }}) => setProduct(data))
				.catch((err) => setFetchError(err.message))
				.finally(() => setLoading(false));
		}
	}, [id, product, loading, fetchError]);

	return (
		<MainLayout title={product?.prdName?.[language] ?? t('navigation:products')}>
			<CommonHeader />
			<div className="page-main justify-align-center" id="product-details-page">
				<div className="width-control">
					{product ? (
						<div>
							<section className="product-details-description-container">
								<div className="product-details-image">
									<img src={product?.img?.[language]} alt="" />
								</div>
								<div className="product-details-description">
									<h2>{product?.prdName?.[language]}</h2>
									<div className="product-details-description-table">
										<div className="product-details-description-row">
											<span className="product-details-description-label">Price:</span>
											<span className="product-details-description-value">
												{`${product?.price} ${product?.currency?.[language]}`}
											</span>
										</div>
										<div className="product-details-description-row">
											<span className="product-details-description-label">UPC Code:</span>
											<span className="product-details-description-value">
												{product?.productId}
											</span>
										</div>
										<div className="product-details-description-row">
											<span className="product-details-description-label">Product Spec:</span>
											<div className="product-details-description-subtable">
												<div className="product-details-description-subrow">
													<span className="product-details-description-sublabel">Capacity</span>
													<span className="product-details-description-subvalue">Capacity here</span>
												</div>
												<div className="product-details-description-subrow">
													<span className="product-details-description-sublabel">Country of Origin</span>
													<span className="product-details-description-subvalue">{product?.originplace?.[language]}</span>
												</div>
											</div>
										</div>
									</div>
									<div className="product-details-pwc-testing">
										<div className="product-details-header">
											<span>PwC Testing</span>
											<button type="button" className="product-details-see-more-button">
												View Description {'>'}
											</button>
										</div>
										<div className="product-details-pwc-testing-items">
											<div className="product-details-pwc-testing-item">
												<span>Acute Toxicity Test</span>
											</div>
											<div className="product-details-pwc-testing-item">
												<span>Chronic Toxicity Test</span>
											</div>
											<div className="product-details-pwc-testing-item">
												<span>Ingredient Screening</span>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section className="product-details-feedbacks-container">
								<div className="product-details-feedbacks-header">
									<h3>Users Feedback</h3>
									<div className="product-details-feedback-rating">
										<span>Overall Ratings:</span>
										<RatingMeter score={4.5} />
									</div>
									<div className="product-details-feedback-comments">
										<CommentCard
											author={user}
											score={5}
											content="A gentle face wash that works to remove dull cells, fade sun spots and discoloration, and leave skin feeling hydrated and clean!"
											createdAt="2021-11-11T11:11:11"
										/>
										<CommentCard
											author={user}
											score={4.5}
											content="Nice"
											createdAt="2021-10-10T10:10:10"
										/>
									</div>
								</div>
								<div className="product-details-feedbacks">

								</div>
							</section>
							<section className="product-details-ingredient-container">
								<div className="product-details-header">
									<h3>Ingredient Reference</h3>
									<button className="product-details-see-more-button" type="button">
										See All {'>'}
									</button>
								</div>
							</section>
						</div>
					) : loading ? (
						<ClipLoader />
					) : fetchError ? (
						<p>{fetchError}</p>
					) : null}
				</div>
				{product ? (
					<section className="product-details-recommendations">
						<div className="width-control">
							<div className="product-details-header">
								<h3>Products You Might Like</h3>
								<button className="product-details-see-more-button" type="button">
									See All {'>'}
								</button>
							</div>
						</div>
					</section>
				) : null}
			</div>
		</MainLayout>
	);
};

const mapStateToProps = (state: RootState) => ({
	user: selectUser(state),
});

export default connect(mapStateToProps)(ProductDetails);
