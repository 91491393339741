import {ActionType} from 'typesafe-actions';
import * as ProfileActions from './actions';
import * as AuthActions from '../Auth/actions';
import {Mail} from '../../models/Mail';
import {SystemProperty} from '../../models/SystemProperty';

export interface State {
  mail: Mail[];

  bookmarkList: any[];

  error?: string;

  systemProperty: SystemProperty | null;

  transactionHistory: any[];
}

export const initialState: State = {
  mail: [],

  bookmarkList: [],

  systemProperty: null,

  transactionHistory: [],
};

type ActionTypes = ActionType<typeof ProfileActions | typeof AuthActions>;

export function reducer(
  state: State = initialState,
  action: ActionTypes,
): State {
  switch (action.type) {
    case ProfileActions.FETCH_MAIL:
      return {
        ...state,
        error: undefined,
        mail: [],
      };
    case ProfileActions.FETCH_ALL_BOOKMARKS:
      return {
        ...state,
        error: undefined,
        bookmarkList: [],
      };
    case ProfileActions.FETCH_SYSTEM_PROPERTY:
      return {
        ...state,
        error: undefined,
        systemProperty: null,
      };
    case ProfileActions.FETCH_TRANSACTION_HISTORY:
      return {
        ...state,
        error: undefined,
        transactionHistory: [],
      };
    case ProfileActions.FETCH_SYSTEM_PROPERTY_SUCCESS:
      return {
        ...state,
        error: undefined,
        systemProperty: action.payload.systemProperty,
      };
    case ProfileActions.FETCH_TRANSACTION_HISTORY_SUCCESS:
    case ProfileActions.FETCH_ALL_BOOKMARKS_SUCCESS:
    case ProfileActions.FETCH_MAIL_SUCCESS:
    case ProfileActions.FETCH_SYSTEM_PROPERTY_FAIL:
    case ProfileActions.FETCH_TRANSACTION_HISTORY_FAIL:
    case ProfileActions.FETCH_ALL_BOOKMARKS_FAIL:
    case ProfileActions.FETCH_MAIL_FAIL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case AuthActions.LOGOUT:
      return initialState;

    default:
      return state;
  }
}
