import {ReduxReducerState} from '../RootReducer';
import {createSelector} from 'reselect';

export const selectReports = (state: ReduxReducerState) => state.reports;
// export const selectId = (_: any, id: string) => id;

export const selectReportsAll = createSelector(
  selectReports,
  (reports) => reports.allReports,
);

export const selectSingleReports = createSelector(
  selectReports,
  (reports) => reports.singleReports,
);

export const selectTrendingReports = createSelector(
  selectReports,
  (reports) => reports.trendingReports,
);

export const selectHotReports = createSelector(selectReportsAll, (reports) =>
  reports.filter((report) => report.isHot),
);

export const selectAllReportsCategories = createSelector(
  selectReportsAll,
  (reportsAll) => {
    let categories: {cn: string; en: string; zh: string}[] = [];
    reportsAll.map((report) => {
      // console.log(
      //   'hello123123',
      //   !categories.some((cat) => cat.en === report.category.name.en),
      // );
      !categories.some((cat) => cat.en === report.category.name.en) &&
        categories.push(report.category.name);
      return true;
    });
    // console.log('selectReportsCategories', categories);
    return categories;
  },
);

export const selectSingleReportsCategories = createSelector(
  selectSingleReports,
  (reportsAll) => {
    let categories: {cn: string; en: string; zh: string}[] = [];
    reportsAll.map((report) => {
      // console.log(
      //   'hello123123',
      //   !categories.some((cat) => cat.en === report.category.name.en),
      // );
      !categories.some((cat) => cat.en === report.category.name.en) &&
        categories.push(report.category.name);
      return true;
    });
    // console.log('selectReportsCategories', categories);
    return categories;
  },
);

export const selectTrendingReportsCategories = createSelector(
  selectTrendingReports,
  (reportsAll) => {
    let categories: {cn: string; en: string; zh: string}[] = [];
    reportsAll.map((report) => {
      // console.log(
      //   'hello123123',
      //   !categories.some((cat) => cat.en === report.category.name.en),
      // );
      !categories.some((cat) => cat.en === report.category.name.en) &&
        categories.push(report.category.name);
      return true;
    });
    // console.log('selectReportsCategories', categories);
    return categories;
  },
);

export const selectGradingDetails = createSelector(
  selectReports,
  (reports) => reports.gradingDetails,
);
