import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Dispatch } from 'redux';
import CommonHeader from '../../components/CommonHeader/CommonHeader';
import MainLayout from '../../components/MainLayout/MainLayout';
import WrapperHeader from '../../components/WrapperHeader/WrapperHeader';
import { useIsGuest } from '../../helpers/CustomHook';
import NewsService from '../../services/NewsService';
import { LoadingOverlayActions } from '../../store/LoadingOverlay';

interface Props {
	setShowLoadingOverlay: (show: boolean) => void;
}

const NewsDetails = ({ setShowLoadingOverlay }: Props): JSX.Element => {
	const { i18n: { language } } = useTranslation();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const isGuest = useIsGuest();
	const [isBookmarked, setIsBookmarked] = useState<boolean | null>(null);
	const [url, setUrl] = useState<string | null>(null);
	const [fetchError, setFetchError] = useState<string | null>(null);

	useEffect(() => {
		setShowLoadingOverlay(true);
		NewsService.fetchOneNews(id)
			.then(({ data: { data: { url: resUrl, isBookmarked: resIsBookmarked} }}) => {
				setUrl(resUrl[language]);
				setIsBookmarked(resIsBookmarked ?? false);
			})
			.catch((err) => setFetchError(err.message))
			.finally(() => setShowLoadingOverlay(false));
	}, [id, language, setShowLoadingOverlay]);

	const onClickBookmark = () => {
		if (isGuest) {
			history.push('/login');
			return;
		}
		const bookmarkCall = isBookmarked ? NewsService.deleteBookmark : NewsService.createBookmark;
		bookmarkCall(id)
			.then(() => setIsBookmarked(!isBookmarked))
			.catch((err) => console.log(err.message));
	};

	return (
		<MainLayout>
			<CommonHeader />
			<WrapperHeader
				pageType="news"
				isBookmarked={isBookmarked}
				onClickBookmark={onClickBookmark}
			/>
			<div className="page-wrapped-main">
				{url && (
					<iframe src={url} title="News" sandbox="allow-scripts allow-same-origin" />
				)}
				{fetchError && (
					<p>{fetchError}</p>
				)}
			</div>
		</MainLayout>
	)
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setShowLoadingOverlay: (show: boolean) => dispatch(LoadingOverlayActions.setShowLoadingOverlay(show)),
});

export default connect(null, mapDispatchToProps)(NewsDetails);
