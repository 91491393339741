import i18next from 'i18next';
import axios from './AxiosInstance';

const fetchAllReports = (page = 1, pageSize = 20, filter?: Record<string, string | number | null>) =>
  axios.get('report', {
    headers: {'Accept-Language': i18next.language},
    params: { page, pageSize, filter }
  });

const fetchSingleReports = () =>
  axios.get('report?filter={"productTypeKey":"single"}', {
    headers: {'Accept-Language': i18next.language},
  });

const fetchTrendingReports = (page = 1, categoryKey?: string) =>
  axios.get('report', {
    headers: {'Accept-Language': i18next.language},
    params: {
      filter: {
        productTypeKey: 'trending',
        categoryKey,
      },
      page,
    }
  });

const fetchReportsById = (
  type: 'ranking' | 'report',
  id: string,
  lang: string,
) =>
  axios.get(`report/${type}/report-web-url/${id}`, {
    headers: {'Accept-Language': lang},
    timeout: 30000,
  });

const fetchReportDetailsById = (id: string) => axios.get(`report/${id}`);

const fetchGradingDetails = () =>
  axios.get(`report/grade`, {
    headers: {'Accept-Language': i18next.language},
  });

const fetchReportCategories = () => axios.get('report/category');

const ReportService = {
  fetchAllReports,
  fetchSingleReports,
  fetchTrendingReports,
  fetchReportsById,
  fetchReportDetailsById,
  fetchGradingDetails,
  fetchReportCategories,
};

export default ReportService;
