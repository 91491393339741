import {ActionType} from 'typesafe-actions';
import * as ProductsActions from './actions';
import * as AuthActions from '../Auth/actions';
import ProductType from '../../models/ProductType';
import ProductMoreContent from '../../models/ProductMoreContent';

export interface State {
  productTypes: ProductType[];

  packageAll: any[];

  membership: any[];

  error?: string;

  moreContent?: ProductMoreContent[];

  productStoreType: string[];

  // productStoreItems: any[];
}

export const initialState: State = {
  productTypes: [],
  packageAll: [],
  membership: [],
  moreContent: undefined,
  productStoreType: ['cosmetic', 'food', 'motherbaby', 'healthcare', 'other'],
  // productStoreItems: [],
};

type ActionTypes = ActionType<typeof ProductsActions | typeof AuthActions>;

export function reducer(
  state: State = initialState,
  action: ActionTypes,
): State {
  switch (action.type) {
    case ProductsActions.FETCH_PRODUCT_TYPES:
      return {
        ...state,
        error: undefined,
        productTypes: [],
      };
    case ProductsActions.FETCH_MEMBERSHIP:
      return {
        ...state,
        error: undefined,
        membership: [],
      };
    case ProductsActions.FETCH_MORE_CONTENT:
      return {
        ...state,
        error: undefined,
        moreContent: undefined,
      };
    case ProductsActions.FETCH_PACKAGE_ALL:
      return {
        ...state,
        error: undefined,
        packageAll: [],
      };
    case ProductsActions.FETCH_PRODUCT_TYPES_SUCCESS:
      return {
        ...state,
        error: undefined,
        productTypes: action.payload.productTypes,
      };
    case ProductsActions.FETCH_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        error: undefined,
        membership: action.payload.membership,
      };
    case ProductsActions.FETCH_MORE_CONTENT_SUCCESS:
      return {
        ...state,
        error: undefined,
        moreContent: action.payload.moreContent,
      };
    case ProductsActions.FETCH_PACKAGE_ALL_SUCCESS:
      return {
        ...state,
        error: undefined,
        packageAll: action.payload.packageAll,
      };
    case ProductsActions.FETCH_MEMBERSHIP_FAIL:
    case ProductsActions.FETCH_MORE_CONTENT_FAIL:
    case ProductsActions.FETCH_PACKAGE_ALL_FAIL:
    case ProductsActions.FETCH_PRODUCT_TYPES_FAIL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case AuthActions.LOGOUT:
      return initialState;

    default:
      return state;
  }
}
