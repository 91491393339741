import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { DeepMap, FieldError, FieldValues } from "react-hook-form";
import ALERT_ICON from "../../assets/images/alert.png";
import "./FormErrorContainer.scss";

interface FormErrorContentProps {
  children?: React.ReactNode;
}

const FormErrorContent = ({ children }: FormErrorContentProps) => (
  <div className="form-error-container-content">
    <img src={ALERT_ICON} alt="Error" />
    <div className="form-error-container-content-children">{children}</div>
  </div>
);

interface Props {
  // errors from useForm().formState;
  errors?: DeepMap<FieldValues, FieldError>;
  // name of form field
  name?: string;
  // custom message
  msg?: string;
}

const FormErrorContainer = ({ errors, name, msg }: Props) => {
  return (
    <div className="form-error-container">
      {name
        ? <ErrorMessage errors={errors} name={name} as={<FormErrorContent />} />
        : msg
          ? <FormErrorContent>{msg}</FormErrorContent>
          : null
      }
    </div>
  );
};

export default FormErrorContainer;
