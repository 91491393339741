import React, { useEffect, useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './ItemSlider.scss';

type Props = {
	children?: React.ReactNode;
} & Settings;

const ItemSlider = ({ children, ...settings }: Props): JSX.Element => {
	const sliderRef = useRef<Slider>(null);
	useEffect(() => {
		sliderRef.current?.slickGoTo?.(0, true);
	}, [children]);
	return (
		<Slider
			ref={sliderRef}
			className="item-slider"
			{...{
				dots: false,
				slidesToShow: 3,
				responsive: [{
					breakpoint: 991,
					settings: {
						slidesToShow: 1.5,
						arrows: false,
					},
				}],
				infinite: false,
				slidesToScroll: 3,
				...settings,
			}}
		>
			{children}
		</Slider>
	);
};

export default ItemSlider;
