import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormErrorContainer from '../../../../components/FormErrorContainer/FormErrorContainer';
import InfoModal from '../../../../components/InfoModal/InfoModal';
import RoundButton from '../../../../components/RoundButton/RoundButton';
import { emailRegex } from '../../../../helpers/helpers';
import UserService from '../../../../services/UserService';
import styles from '../../../../styles/modules/FormStyles.module.scss';

interface Props {
	isOpen: boolean;
	onClose: () => void;

	email: string;
	onSuccess: () => void;
}

const ResetPasswordModal = ({ isOpen, onClose, email, onSuccess }: Props): JSX.Element => {
	const { t } = useTranslation();
	const [code, setCode] = useState('');
	const [newPw, setNewPw] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const onSubmit = () => {
		const submitError = (email.length < 1 || code.length < 1 || newPw.length < 1)
			? t('settings:verificationOrPasswordEmpty')
			: !emailRegex.test(email)
				? t('settings:invalidEmail')
				: (newPw.length < 6 || newPw.length > 12)
					? t('register:errors:pwLimit')
					: null;
		if (submitError) {
			setError(submitError);
		} else {
			setLoading(true);
			UserService.resetPassword(email, newPw, code)
				.then(({ status }) => {
					if (status >= 200 && status < 400) {
						onSuccess();
						setCode('');
						setNewPw('');
					}
					else {
						throw new Error(t('settings:passwordResetFailed'));
					}
				})
				.catch(({ message }) => setError(message))
				.finally(() => setLoading(false));
		}
	};

	return (
		<InfoModal isOpen={isOpen} onClose={onClose}>
			<div className="login-reset-password-modal">
				<h3>{t('settings:resetPassword').toUpperCase()}</h3>
				<div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputEmail}`}>
					<input
						type="text"
						placeholder={t('settings:verifyCode')}
						value={code}
						onChange={(e) => setCode(e.target.value)}
					/>
				</div>
				<div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputPassword}`}>
					<input
						type="password"
						placeholder={t('common:password')}
						value={newPw}
						onChange={(e) => setNewPw(e.target.value)}
					/>
				</div>
				<RoundButton
					text={t('common:submit').toUpperCase()}
					onClick={onSubmit}
					loading={loading}
				/>
				{error && <FormErrorContainer msg={error} />}
			</div>
		</InfoModal>
	);
};

export default ResetPasswordModal;
