import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Report, ReportCategory } from '../../../../models/Report';
import MyReportCard from '../MyReportCard/MyReportCard';
import DROPDOWN_ICON from '../../../../assets/images/dropdownIcon.png';
import DEFAULT_CATEGORY_ICON from '../../../../assets/images/gradingIcon4.png';

const ExpandIcon = (): JSX.Element => (
	<img width={24} height={16} style={{ objectFit: 'contain' }} src={DROPDOWN_ICON} alt="" />
);

const MyReportAccordion = withStyles({
	root: {
		boxShadow: 'none',
		border: 'none',
	},
})(Accordion);
const MyReportAccordionSummary = withStyles({
	root: {
		width: '100%',
		justifyContent: 'flex-start',
	},
	content: {
		margin: '12px 0 !important',
		flexGrow: 0,
		'& h3': {
			color: '#67dac0',
			fontWeight: 700,
			fontSize: '16px',
		},
	},
	expandIcon: {
		padding: '12px 0',
	},
})(AccordionSummary);
const MyReportAccordionDetails = withStyles({
	root: {
		flexWrap: 'wrap',
		'& .my-report-card': {
			flex: '0 0 calc((100% - 24px * 3) / 3)',
			margin: '0 12px',
			minWidth: '250px',
		},
	},
})(AccordionDetails);

interface Props {
	displayMonth: string;
	reports: Report[];
	reportCategories?: ReportCategory[];
	defaultExpanded?: boolean;
}

const MyReportMonth = ({ displayMonth, reports, reportCategories, defaultExpanded }: Props): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();

	return (
		<MyReportAccordion defaultExpanded={defaultExpanded}>
			<MyReportAccordionSummary expandIcon={<ExpandIcon />}>
				<h3>{displayMonth}</h3>
			</MyReportAccordionSummary>
			<MyReportAccordionDetails>
				{reports.map((report) => {
					const categoryName =
						report.category?.name?.[language]
							?? reportCategories?.find((c) => c.key === report.categoryKey)?.name?.[language]
							?? report.categoryKey
							?? '';
					const categoryIcon =
						report.category?.imageUrl?.[language] ?? DEFAULT_CATEGORY_ICON;
					return (
						<MyReportCard
							key={report.id}
							img={report.imageUrl[language]}
							title={report.title[language]}
							to={`/reports/${report.id}`}
							categoryName={categoryName}
							categoryIcon={categoryIcon}
							expiryDate={`${t('redemption:expiryDate')}: -`}
						/>
					);
				})}
			</MyReportAccordionDetails>
		</MyReportAccordion>
	);
};

export default MyReportMonth;
