import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CommonHeader from '../../../components/CommonHeader/CommonHeader';
import InfoModal from '../../../components/InfoModal/InfoModal';
import MainLayout from '../../../components/MainLayout/MainLayout';
import RoundButton from '../../../components/RoundButton/RoundButton';
import MembershipType from '../../../models/Membership';
import ProductType from '../../../models/ProductType';
import { ProductsActions } from '../../../store/Products';
import { selectMembership, selectProductsType } from '../../../store/Products/selectors';
import { ReportsActions } from '../../../store/Reports';
import MembershipCard from './components/MembershipCard/MembershipCard';
import MEMBERSHIP_ICON from '../../../assets/images/membersIcon.jpg';
import './Membership.scss';

const MEMBERSHIP_CARD_BACKGROUND = {
	90: '#67dac033',
	180: '#46c3d433',
	365: '#8a45d41a',
};

interface Props {
	productTypes: ProductType[];
	fetchProductTypes: () => void;
	memberships: MembershipType[];
	fetchMemberships: () => void;
}

const Membership = ({ memberships, fetchMemberships, productTypes, fetchProductTypes }: Props): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();
	const [selectedMembership, setSelectedMembership] = useState<MembershipType | null>(null);

	useEffect(() => {
		fetchMemberships();
		fetchProductTypes();
	}, [fetchMemberships, fetchProductTypes]);

	return (
		<>
			<InfoModal isOpen={!!selectedMembership} onClose={() => setSelectedMembership(null)}>
				<div className="membership-page-modal">
					<img src={MEMBERSHIP_ICON} alt="" />
					<p className="membership-page-modal-title">{selectedMembership?.title?.[language]}</p>
					<p className="membership-page-modal-desc">{selectedMembership?.description?.[language]}</p>
				</div>
			</InfoModal>
			<MainLayout title={productTypes?.find((p) => p.key === 'membership')?.name?.[language] ?? 'Membership'}>
				<CommonHeader />
				<div className="page-main justify-align-center">
					<div className="width-control">
						<div className="membership-page-content">
							{memberships?.map((m) => (
								<MembershipCard
									key={m.id}
									membership={m}
									backgroundColor={MEMBERSHIP_CARD_BACKGROUND[m.validDays]}
									onDetails={(mem) => setSelectedMembership(mem)}
								/>
							))}
							<div className="membership-page-try">
								<RoundButton text={t('membership:tryNow')} link="/redemption" />
							</div>
						</div>
					</div>
				</div>
			</MainLayout>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	productTypes: selectProductsType(state),
	memberships: selectMembership(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchProductTypes: () => dispatch(ReportsActions.fetchAllReports()),
	fetchMemberships: () => dispatch(ProductsActions.fetchMembership()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Membership);