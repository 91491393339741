import React from 'react';
import REPORT_ICON from '../../../../assets/images/singleReportIcon.png';
import MORE_BUTTON from '../../../../assets/images/moreBtn.png';
import './EntryCard.scss';

interface Props {
	title?: string;
	subtitle?: string;
	onMore?: () => void;
}

const EntryCard = ({ title, subtitle, onMore }: Props): JSX.Element => (
	<div className="entry-card">
		<div className="entry-card-icon">
			<img src={REPORT_ICON} alt="" />
		</div>
		<span className="entry-card-title">{title}</span>
		<span className="entry-card-subtitle">{subtitle}</span>
		{onMore && (
			<button type="button" onClick={onMore}>
				<img src={MORE_BUTTON} alt="More" />
			</button>
		)}
	</div>
);

export default EntryCard;
