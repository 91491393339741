import React from 'react';
import { useTranslation } from 'react-i18next';
import COMING_SOON_EN from '../../assets/images/comingsoon_en.png';
import COMING_SOON_ZH from '../../assets/images/comingsoon_zh.png';
import COMING_SOON_CN from '../../assets/images/comingsoon_cn.png';
import './ComingSoon.scss';

const COMING_SOON: {[key: string]: string} = {
	en: COMING_SOON_EN,
	zh: COMING_SOON_ZH,
	cn: COMING_SOON_CN,
};

const ComingSoon = (): JSX.Element => {
	const { i18n: { language } } = useTranslation();
	return (
		<div className="coming-soon">
			<img src={COMING_SOON[language]} alt="Coming Soon" />
		</div>
	);
};

export default ComingSoon;
