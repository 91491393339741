import i18next from 'i18next';
import axios from './AxiosInstance';

const fetchProductsTypes = () => axios.get('product/type');

const fetchMembership = () => axios.get('membership');

const fetchPackageAll = (page = 1) =>
  axios.get('package', {
    headers: {'Accept-Language': i18next.language},
    params: { page }
  });

const fetchMoreContent = () => axios.get('content/all');

const fetchProductStoreListByType = (page: number, productType: string) =>
  axios.get(
    `store-product?page=${page}&filter={"productType":"${productType}"}&pageSize=15`,
  );

const fetchProductStoreBySearch = (
  page: number,
  productType: string,
  productName: string,
) =>
  axios.get(
    `store-product?page=${page}&filter={"productType":"${productType}","productName":"${productName}"}&pageSize=15`,
    {
      headers: {'Accept-Language': i18next.language},
    },
  );

const fetchProductStore = (productType: string, productName = '', page = 1, pageSize = 15) => (
  axios.get(
    'store-product',
    {
      params: {
        filter: {
          productType,
          productName: productName !== '' ? productName : undefined,
        },
        page,
        pageSize,
      },
      headers: {
        'Accept-Language': i18next.language,
      },
    },
  )
);

const fetchProductUrl = (productId: string) =>
  axios.get(`store-product/detail-url/${productId}`, {
    headers: {'Accept-Language': i18next.language},
  });

const fetchProductById = (productId: string) => axios.get(`store-product/${productId}`, {
  headers: { 'Accept-Language': i18next.language },
});

const ProductsService = {
  fetchProductsTypes,
  fetchMembership,
  fetchPackageAll,
  fetchMoreContent,
  fetchProductStoreListByType,
  fetchProductStoreBySearch,
  fetchProductStore,
  fetchProductUrl,
  fetchProductById,
};

export default ProductsService;
