import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Dispatch } from 'redux';
import CommonHeader from '../../components/CommonHeader/CommonHeader';
import MainLayout from '../../components/MainLayout/MainLayout';
import RoundButton from '../../components/RoundButton/RoundButton';
import WrapperHeader from '../../components/WrapperHeader/WrapperHeader';
import BookmarkService from '../../services/BookmarkService';
import ReportService from '../../services/ReportService';
import { LoadingOverlayActions } from '../../store/LoadingOverlay';
import { selectSavedReports } from '../../store/Profile/selectors';
import { ProfileActions } from '../../store/Profile';
import { selectIsGuest, selectRankingQuota, selectReportQuota } from '../../store/Auth/selectors';
import Bookmarked from '../../models/Bookmarked';
import RedeemPopup from './components/RedeemPopup/RedeemPopup';
import SuccessContainer from '../../components/SuccessContainer/SuccessContainer';

interface Props {
	bookmarkList?: Bookmarked[];
	isGuest: boolean;
	reportQuota: number;
	rankingQuota: number;
	fetchAllBookmark: () => void;
	setShowLoadingOverlay: (show: boolean) => void;
}

const TestResult = ({ bookmarkList, isGuest, reportQuota, rankingQuota, fetchAllBookmark, setShowLoadingOverlay }: Props): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();
	const [url, setUrl] = useState<string | null>(null);
	const [isSaved, setIsSaved] = useState(false);
	const [saving, setSaving] = useState(false);
	const [isRedeemed, setIsRedeemed] = useState(false);
	const [fetchError, setFetchError] = useState<string | null>(null);

	const [resultType, setResultType] = useState<'report' | 'ranking' | null>(null);

	const [showRedeemPopup, setShowRedeemPopup] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);

	const redeemSuccessCallback = (msg: string) => {
		setShowRedeemPopup(false);
		setIsRedeemed(true);
		setShowSuccess(true);
		setSuccessMessage(msg);
	};

	useEffect(() => {
		fetchAllBookmark();
	}, [fetchAllBookmark]);

	useEffect(() => {
		setShowLoadingOverlay(true);
		ReportService.fetchReportDetailsById(id)
			.then(({ data: { data: { reportId, rankingUnionId, isRedeemed: resIsRedeemed } } }) => {
				setIsRedeemed(resIsRedeemed);
				if (reportId) {
					setResultType('report');
					ReportService.fetchReportsById('report', reportId, language)
						.then(({ data: { data } }) => setUrl(data))
						.finally(() => setShowLoadingOverlay(false));
				} else if (rankingUnionId) {
					setResultType('ranking');
					ReportService.fetchReportsById('ranking', rankingUnionId, language)
						.then(({ data: { data } }) => setUrl(data))
						.finally(() => setShowLoadingOverlay(false));
				} else throw new Error('Invalid report');
			})
			.catch((err) => {
				setFetchError(err.message);
				setShowLoadingOverlay(false);
			})
	}, [id, language, setShowLoadingOverlay]);

	useEffect(() => {
		if (bookmarkList && id) {
			// console.log(bookmarkList.map((n) => n.reportId));
			// console.log(bookmarkList.some((b) => b.reportId === id));
			setIsSaved(bookmarkList.some((b) => b.reportId === id));
		}
	}, [bookmarkList, id]);

	const onSave = () => {
		if (isGuest) {
			history.push('/login');
			return;
		}
		setSaving(true);
		BookmarkService.createSaveReport(id)
			.then(() => {
				fetchAllBookmark();
				history.push('/redemption?tab=pending')
			})
			.catch((err) => setFetchError(err.message))
			.finally(() => setSaving(false));
	}

	const onRedeem = () => {
		if (isGuest) {
			history.push('/login');
			return;
		}
		setShowRedeemPopup(true);
	};

	return (
		<>
			<RedeemPopup
				isOpen={showRedeemPopup}
				id={id}
				redeemQuota={resultType === 'report' ? reportQuota : resultType === 'ranking' ? rankingQuota : 0}
				onClose={() => setShowRedeemPopup(false)}
				redeemSuccessCallback={redeemSuccessCallback}
				itemType={resultType ?? undefined}
			/>
			<MainLayout>
				<CommonHeader />
				<WrapperHeader pageType="reports" />
				{!showSuccess ? (
					<div className="page-wrapped-main">
						{url && (
							<iframe
								className={`page-wrapped-iframe ${!isRedeemed ? 'mod-lock' : ''}`}
								src={url}
								title="Report"
								sandbox="allow-scripts allow-same-origin"
							/>
						)}
						{!isRedeemed && (
							<div className="page-wrapped-lock">
								{!isSaved && <RoundButton text={t('reports:saveItem')} loading={saving} onClick={onSave} />}
								<RoundButton text={t('reports:redeem')} onClick={onRedeem} />
							</div>
						)}
						{fetchError && (
							<p>{fetchError}</p>
						)}
					</div>
				) : (
					<div className="page-wrapped-main">
						<SuccessContainer
							title={t('redemption:redeemSuccess').toUpperCase()}
							content={successMessage} okText={t('common:ok')}
							onOk={() => history.replace('/redemption')}
						/>
					</div>
				)}
			</MainLayout>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	bookmarkList: selectSavedReports(state),
	isGuest: selectIsGuest(state),
	reportQuota: selectReportQuota(state),
	rankingQuota: selectRankingQuota(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchAllBookmark: () => dispatch(ProfileActions.fetchAllBookmarks()),
	setShowLoadingOverlay: (show: boolean) => dispatch(LoadingOverlayActions.setShowLoadingOverlay(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestResult);
