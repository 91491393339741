import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router';
import { ClipLoader } from 'react-spinners';
import ComingSoon from '../../../components/ComingSoon/ComingSoon';
import HomeCard from '../../../components/HomeCard/HomeCard';
import { useIsGuest } from '../../../helpers/CustomHook';
import Benefit from '../../../models/Benefit';
import { UserV2 } from '../../../models/User';
import BenefitsService from '../../../services/BenefitsService';
import styles from '../../../styles/modules/HomeTab.module.scss';

interface Props {
	user?: UserV2;
}

const HomeBenefits = ({ user }: Props): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();
	const history = useHistory();
	const isGuest = useIsGuest();
	const [benefits, setBenefits] = useState<Benefit[] | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [fetchError, setFetchError] = useState<string | null>(null);

	/* Infinite Scroll */
	const onFetchBenefits = useCallback(() => {
		BenefitsService.fetchBenefits(currentPage, 20, { isEnabled: true })
			.then(({ data: { data: { data, totalPage: resTotalPage } } }) => {
				setBenefits([...(benefits ?? []), ...data]);
				setCurrentPage(currentPage + 1);
				setTotalPage(resTotalPage);
			})
			.catch(({ message }) => setFetchError(message));
	}, [benefits, currentPage]);

	// Initial fetch
	useEffect(() => {
		if (!benefits && !fetchError) {
			onFetchBenefits();
		}
	}, [benefits, fetchError, onFetchBenefits]);

	useEffect(() => {
		if (isGuest && benefits?.length > 0) {
			history.replace('/login');
		}
	}, [benefits, history, isGuest]);

	return (
		<div className={styles.homeTab}>
			{benefits ? (
				benefits.length === 0 || !user?.memberValidTill ? (
					<ComingSoon />
				) : (
					<>
						<h2>{t('navigation:benefits')}</h2>
						<InfiniteScroll
							className={styles.reportList}
							loader={<div className={styles.reportLoading}><ClipLoader /></div>}
							hasMore={currentPage <= totalPage}
							next={onFetchBenefits}
							dataLength={benefits?.length ?? 0}
						>
							{benefits?.map((benefit) => (
								<HomeCard
									key={benefit.id}
									to={benefit.webUrl[language] || benefit.webUrl.en}
									img={benefit.imageUrl[language] || benefit.imageUrl.en}
									name={benefit.name[language] || benefit.name.en}
									createdAt={moment(benefit.createdAt).format('YYYY-MM-DD')}
								/>
							))}
						</InfiniteScroll>
					</>
				)
			) : null}
		</div>
	);
};

export default HomeBenefits;
