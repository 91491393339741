import { ActionType } from "typesafe-actions";
import * as LoadingOverlayActions from "./action";

export interface State {
  show: boolean;
}

const initialState: State = { show: false };

type LoadingOverlayActionsType = ActionType<typeof LoadingOverlayActions>;

export function reducer(
  state: State = initialState,
  action: LoadingOverlayActionsType
): State {
  switch (action.type) {
    case LoadingOverlayActions.SET_SHOW_LOADING_OVERLAY:
      return {
        ...state,
        show: action.payload,
      };
    default:
      return state;
  }
}
