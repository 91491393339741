import axios from './AxiosInstance';

const fetchAllBookmarks = (page = 1) => axios.get(`user/bookmark`, { params: { page } });

const deleteSavedReport = (id: string) =>
  axios.delete(`user/bookmark?reportID=${id}`);

const createSaveReport = (id: string) =>
  axios.post(`user/bookmark`, {reportId: id});

const BookmarkService = {
  fetchAllBookmarks,
  deleteSavedReport,
  createSaveReport,
};

export default BookmarkService;
