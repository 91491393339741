import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClipLoader } from 'react-spinners';
import { useIsGuest } from '../../../helpers/CustomHook';
import { Mail } from '../../../models/Mail';
import MailService from '../../../services/MailService';
import styles from '../../../styles/modules/ProfileTab.module.scss';
import MailCard from '../components/MailCard/MailCard';

const Mailbox = (): JSX.Element => {
	const { t } = useTranslation();
	const isGuest = useIsGuest();

	const [mails, setMails] = useState<Mail[] | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [fetchError, setFetchError] = useState<string | null>(null);

	/* Infinite Scroll */
	const onFetchMail = useCallback(() => {
		MailService.fetchMail(currentPage)
			.then(({ data: { data: { data, totalPage } } }) => {
				setMails([...(mails ?? []), ...data]);
				setCurrentPage(currentPage + 1);
				setTotalPage(totalPage);
			})
			.catch((err) => setFetchError(err.message))
	}, [currentPage, mails]);

	// Initial fetch
	useEffect(() => {
		if (!mails && !fetchError && !isGuest) {
			onFetchMail();
		}
	}, [mails, fetchError, onFetchMail, isGuest]);

	return (
		<div className={styles.profileTab}>
			<InfiniteScroll
				className={styles.profileMailList}
				loader={<div className={styles.profileMailCenter}><ClipLoader /></div>}
				hasMore={currentPage <= totalPage}
				next={onFetchMail}
				dataLength={mails?.length ?? 0}
			>
				{mails?.map((mail) => (
					<MailCard key={mail._id} mail={mail} />
				))}
				{(mails?.length ?? 0) === 0 && (
					<div className={styles.profileMailCenter}>
						<span>{t('myProfile:noMail')}</span>
					</div>
				)}
			</InfiniteScroll>
		</div>
	);
};

export default Mailbox;
