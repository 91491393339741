import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ARROW_ICON from '../../../../assets/images/nextBtn.png';
import './ProductTypeCard.scss';

interface Props {
	to: string;
	imgUrl?: string;
	title?: string;
	subtitle?: string;
	onDetail?: () => void;
}

const ProductTypeCard = ({ to, imgUrl, title, subtitle, onDetail }: Props): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Link className="product-type-card" to={to}>
			<div className="product-type-card-img">
				<img src={imgUrl} alt="" />
			</div>
			<span className="product-type-card-title">{title}</span>
			<div className="product-type-card-subtitle-container">
				<span className="product-type-card-subtitle">
					{`${subtitle.substr(0, 30)}...`}
				</span>
				<button
					className="product-type-card-detail"
					type="button"
					onClick={(e) => {
						onDetail?.();
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					{t('navigation:seeMore')}
				</button>
			</div>
			<div className="product-type-card-button">
				<img src={ARROW_ICON} alt=">" />
			</div>
		</Link>
	);
};

export default ProductTypeCard;
