import {ActionType} from 'typesafe-actions';
import * as ReportsActions from './actions';

export interface State {
  allReports: any[];
  singleReports: any[];
  trendingReports: any[];
  error?: string;
  gradingDetails: any[];
}

export const initialState: State = {
  allReports: [],
  singleReports: [],
  trendingReports: [],
  gradingDetails: [],
};

type ReportsActionsType = ActionType<typeof ReportsActions>;

export function reducer(
  state: State = initialState,
  action: ReportsActionsType,
): State {
  switch (action.type) {
    case ReportsActions.FETCH_ALL_REPORTS: {
      return {
        ...state,
        allReports: [],
        error: undefined,
      };
    }
    case ReportsActions.FETCH_SINGLE_REPORTS: {
      return {
        ...state,
        singleReports: [],
        error: undefined,
      };
    }
    case ReportsActions.FETCH_TRENDING_REPORTS: {
      return {
        ...state,
        trendingReports: [],
        error: undefined,
      };
    }
    case ReportsActions.FETCH_GRADING_DETAILS: {
      return {
        ...state,
        gradingDetails: [],
        error: undefined,
      };
    }
    case ReportsActions.FETCH_ALL_REPORTS_SUCCESS: {
      return {
        ...state,
        allReports: action.payload.allReports,
        error: undefined,
      };
    }
    case ReportsActions.FETCH_SINGLE_REPORTS_SUCCESS: {
      return {
        ...state,
        singleReports: action.payload.singleReports,
        error: undefined,
      };
    }
    case ReportsActions.FETCH_TRENDING_REPORTS_SUCCESS: {
      return {
        ...state,
        trendingReports: action.payload.trendingReports,
        error: undefined,
      };
    }
    case ReportsActions.FETCH_GRADING_DETAILS_SUCCESS: {
      return {
        ...state,
        gradingDetails: action.payload.gradingDetails,
        error: undefined,
      };
    }
    case ReportsActions.FETCH_ALL_REPORTS_FAIL: {
      return {
        ...state,
        allReports: [],
        error: action.payload.err,
      };
    }
    case ReportsActions.FETCH_SINGLE_REPORTS_FAIL: {
      return {
        ...state,
        singleReports: [],
        error: action.payload.err,
      };
    }
    case ReportsActions.FETCH_TRENDING_REPORTS_FAIL: {
      return {
        ...state,
        trendingReports: [],
        error: action.payload.err,
      };
    }
    case ReportsActions.FETCH_GRADING_DETAILS_FAIL: {
      return {
        ...state,
        gradingDetails: [],
        error: action.payload.err,
      };
    }
    default:
      return state;
  }
}
