import React from 'react';
import { Link } from 'react-router-dom';
import './ItemCard.scss';

interface Props {
	to?: string;
	img?: string;
	title?: string;
	subTitle?: string;
	arrow?: boolean;
}

const ItemCard = ({ to, img, title, subTitle, arrow }: Props): JSX.Element => (
	<Link to={to} className="item-card">
		<div className="item-card-box">
			<div className="item-card-img-container">
				{!!img && <img src={img} alt={title} />}
			</div>
			<div className="item-card-title-container">
				<span className={`item-card-title ${!subTitle ? 'mod-multi' : ''} ${arrow ? 'mod-shrink' : ''}`}>{title}</span>
				{!!subTitle && <span className="item-card-subtitle">{subTitle}</span>}
				{arrow && <div className="item-card-arrow" />}
			</div>
		</div>
	</Link>
);

export default ItemCard;
