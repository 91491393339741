import {ReduxReducerState} from '../RootReducer';
import {createSelector} from 'reselect';

export const selectRedemption = (state: ReduxReducerState) => state.redemption;
// export const selectId = (_: any, id: string) => id;

export const selectInuseRedeemCode = createSelector(
  selectRedemption,
  (redemption) => redemption.inUseRedeemCode,
);

export const selectPendingRedeemedCodeList = createSelector(
  selectRedemption,
  (redemption) =>
    redemption.redeemCodeList.filter((redeemedCode) => redeemedCode.isPending),
);

export const selectRedeemedReportsList = createSelector(
  selectRedemption,
  (redemption) => redemption.redeemedReportsList,
);
