import React from 'react';
import { useTranslation } from 'react-i18next';
import PROFILE_PIC from '../../../assets/images/myProfileIcon.png';
import { UserV2 } from '../../../models/User';
import styles from '../../../styles/modules/ProfileTab.module.scss';
import PHONE_ICON from '../../../assets/images/phoneIcon.png';
import EMAIL_ICON from '../../../assets/images/emailIcon.png';
import BOOKMARK_ICON from '../../../assets/images/bookmarkBtn.png';
import HISTORY_ICON from '../../../assets/images/historyBtn.png';
import ARROW_ICON from '../../../assets/images/nextBtn.png';
import { Link } from 'react-router-dom';
import { useIsGuest } from '../../../helpers/CustomHook';

interface Props {
	user?: UserV2;
}

const MyInfo = ({ user }: Props): JSX.Element => {
	const { t } = useTranslation();
	const isGuest = useIsGuest();

	return (isGuest || user) ? (
		<div className={styles.profileTab}>
			<div className={styles.profileInfoContainer}>
				<img className={styles.profilePic} src={PROFILE_PIC} alt="" />
				<span className={styles.profileName}>
					{isGuest ? t('settings:Guest') : `${user?.firstName ?? ''} ${user?.lastName ?? ''}`}
				</span>
				<div className={styles.memberId}>
					<span>{t('myProfile:memberId')}</span>
					<span>{isGuest ? '-' : user?.id}</span>
				</div>
				<div className={styles.profileInfoList}>
					{!isGuest && (
						<>
							<div className={styles.profileInfoListItem}>
								<img src={EMAIL_ICON} alt="Email" />
								<span>{user?.email ?? '-'}</span>
							</div>
							<div className={styles.profileInfoListItem}>
								<img src={PHONE_ICON} alt="Phone" />
								<span>{(() => {
									const [areaCode, phone] = (user?.phone ?? '-').split(' ');
									return (phone?.length > 0 ?? 0) ? `(${areaCode}) ${phone}` : user.phone;
								})()}</span>
							</div>
						</>
					)}
					<Link to={isGuest ? '/login' : '/profile/savedItems'} className={styles.profileInfoListItem}>
						<img src={BOOKMARK_ICON} alt="Bookmarked" />
						<span>{t('myProfile:bookmarked')}</span>
						<img src={ARROW_ICON} alt=">" />
					</Link>
					<Link to={isGuest ? '/login' : '/profile/history'} className={styles.profileInfoListItem}>
						<img src={HISTORY_ICON} alt="History" />
						<span>{t('myProfile:history')}</span>
						<img src={ARROW_ICON} alt=">" />
					</Link>
				</div>
			</div>
		</div>
	) : null;
};

export default MyInfo;
