import {createSelector} from 'reselect';
import {ReduxReducerState} from '../RootReducer';

export const selectProfile = (state: ReduxReducerState) => state.profile;

export const selectId = (_: any, id: string) => id;

export const selectMail = createSelector(
  selectProfile,
  (profile) => profile.mail,
);

export const selectMailById = createSelector(
  selectMail,
  selectId,
  (mail, id) => mail && mail.find((x) => x._id === id),
);

export const selectProfileError = createSelector(
  selectProfile,
  (profile) => profile.error,
);

export const selectAllBookmarksList = createSelector(
  selectProfile,
  (profile) => profile.bookmarkList,
);

export const selectSavedReports = createSelector(
  selectAllBookmarksList,
  (bookmarkList) =>
    // console.log('selectSavedReports', bookmarkList);
    bookmarkList.filter(
      (bookmark) => bookmark.reportId && bookmark.news === null,
    ),
);

export const selectTNC = createSelector(
  selectProfile,
  (profile) => profile.systemProperty?.termsAndCondition,
);

export const selectCopyRight = createSelector(
  selectProfile,
  (profile) => profile.systemProperty?.copyRight,
);

export const selectDisclaimer = createSelector(
  selectProfile,
  (profile) => profile.systemProperty?.disclaimer,
);

export const selectPrivacyPolicy = createSelector(
  selectProfile,
  (profile) => profile.systemProperty?.privacyPolicy,
);

export const selectTransactionHistory = createSelector(
  selectProfile,
  (profile) => profile.transactionHistory,
);
