import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoModal from '../../../../components/InfoModal/InfoModal';
import RoundButton from '../../../../components/RoundButton/RoundButton';
import './RedeemPackageModal.scss';

interface Props {
	isOpen: boolean;
	onClose: () => void;

	packageTitle?: string;
	packageContents?: string[];
	packageBottomText?: string;

	buttonText?: string;
	buttonDisabled?: boolean;
	buttonLoading?: boolean;
	buttonOnClick?: (code?: string) => void;

	showInput?: boolean;
	errorMsg?: string | null;
}

const RedeemPackageModal = ({
	isOpen, onClose,
	packageTitle, packageContents, packageBottomText,
	buttonText, buttonDisabled, buttonLoading, buttonOnClick,
	showInput,
	errorMsg,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	const [inputCode, setInputCode] = useState('');

	return (
		<InfoModal isOpen={isOpen} onClose={onClose}>
			<div className="redeem-package-modal">
				<span className="redeem-package-modal-title">{packageTitle}</span>
				<span className="redeem-package-modal-text">{t('package:packageRedeemIntro')}</span>
				<ol className="redeem-package-modal-list">
					{packageContents?.map((r, i) => (
						<li key={`redeem-modal-list-${+i}`}>{r}</li>
					))}
				</ol>
				<span className="redeem-package-modal-text">{packageBottomText ?? t('redemption:confirmRedemption')}</span>
				{showInput && (
					<input
						className="redeem-package-modal-input" 
						value={inputCode}
						onChange={(e) => setInputCode(e.target.value)}
						type="text"
						placeholder={t('redemption:inputCodePlaceholder')}
					/>
				)}
				<RoundButton
					className="redeem-package-modal-btn"
					text={buttonText ?? t('common:ok')}
					disabled={buttonDisabled}
					loading={buttonLoading}
					onClick={() => buttonOnClick(showInput ? inputCode : undefined)}
				/>
				{!!errorMsg && (
					<span className="redeem-package-modal-text">{errorMsg}</span>
				)}
			</div>
		</InfoModal>
	);
};

export default RedeemPackageModal;
