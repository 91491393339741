import { RootState } from 'MyTypes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CommonHeader from '../../components/CommonHeader/CommonHeader';
import CommonTab from '../../components/CommonTab/CommonTab';
import CommonTabs from '../../components/CommonTabs/CommonTabs';
import MainLayout from '../../components/MainLayout/MainLayout';
import { UserV2 } from '../../models/User';
import { selectIsGuest, selectUser } from '../../store/Auth/selectors';
import EDIT_ICON from '../../assets/images/editBtn.png';
import './Profile.scss';
import { Mailbox, MyInfo } from './ProfileTabs';
import EditProfileModal from './components/EditProfileModal/EditProfileModal';
import { Dispatch } from 'redux';
import { AuthActions } from '../../store/Auth';

interface Props {
	user?: UserV2;
	fetchUser: () => void;
	isGuest: boolean;
}

const Profile = ({ user, fetchUser, isGuest }: Props): JSX.Element => {
	const { t } = useTranslation();
	const [currentTab, setCurrentTab] = useState<string>('info');
	const [showEdit, setShowEdit] = useState(false);

	const onUpdateSuccess = () => {
		setShowEdit(false);
		fetchUser();
	}

	return (
		<>
			{!isGuest && (
				<EditProfileModal
					isOpen={showEdit}
					onClose={() => setShowEdit(false)}
					user={user}
					onSuccess={onUpdateSuccess}
				/>
			)}
			<MainLayout title={t('myProfile:myProfile')}>
				<CommonHeader />
				<div className="page-main tabs-page-align">
					<div className="width-control">
						<div className="profile-page">
							<div className="profile-page-header">
								<h2>{t('myProfile:myProfile')}</h2>
								{!isGuest && (
									<button type="button" onClick={() => setShowEdit(true)}>
										<img src={EDIT_ICON} alt="Edit" />
									</button>
								)}
							</div>
							<CommonTabs value={currentTab} onChange={(_, i) => setCurrentTab(i)} variant="scrollable">
								<CommonTab value="info" label={t('myProfile:myInfo')} />
								<CommonTab value="mail" label={t('myProfile:mailbox')} />
							</CommonTabs>
							{(() => {
								switch (currentTab) {
									case 'info':
										return <MyInfo user={user} />;
									case 'mail':
										return <Mailbox />;
									default:
										return null;
								}
							})()}
						</div>
					</div>
				</div>
			</MainLayout>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	user: selectUser(state),
	isGuest: selectIsGuest(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchUser: () => dispatch(AuthActions.fetchUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
