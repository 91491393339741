import { RootState } from 'MyTypes';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Dispatch } from 'redux';
import CommonHeader from '../../../components/CommonHeader/CommonHeader';
import InfoModal from '../../../components/InfoModal/InfoModal';
import LongItemCard from '../../../components/LongItemCard/LongItemCard';
import MainLayout from '../../../components/MainLayout/MainLayout';
// import RoundButton from '../../../components/RoundButton/RoundButton';
import SuccessContainer from '../../../components/SuccessContainer/SuccessContainer';
import Package from '../../../models/Package';
import RedemptionCode from '../../../models/RedemptionCode';
import RedemptionService from '../../../services/RedemptionService';
import { ProductsActions } from '../../../store/Products';
import { selectPackageAll } from '../../../store/Products/selectors';
import { RedemptionActions } from '../../../store/Redemption';
import { selectInuseRedeemCode, selectPendingRedeemedCodeList } from '../../../store/Redemption/selectors';
import RedeemPackageModal from '../../Products/components/RedeemPackageModal/RedeemPackageModal';
import './RedeemPackage.scss';

interface Props {
	inUseCode?: string;
	redeemedCodeList?: RedemptionCode[];
	packageAll?: Package[];
	fetchRedeemedCodeList: () => void;
	clearInuseRedeemCode: () => void;
	fetchPackageAll: () => void;
}

const RedeemPackage = ({
	inUseCode,
	redeemedCodeList,
	packageAll,
	fetchRedeemedCodeList,
	clearInuseRedeemCode,
	fetchPackageAll,
}: Props): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();
	const history = useHistory();
	const { tierKey } = useParams<{ tierKey: string }>();
	const [packages, setPackages] = useState<Package[] | null>(null);
	const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
	const [redeeming, setRedeeming] = useState(false);
	const [successMsg, setSuccessMsg] = useState<string | null>(null);

	useEffect(() => {
		if (!inUseCode) {
			history.goBack();
		}
	}, [history, inUseCode]);

	useEffect(() => {
		fetchPackageAll();
	}, [fetchPackageAll]);

	useEffect(() => {
		// console.log(packageAll, tierKey);
		if (packageAll && tierKey) {
			setPackages(packageAll.filter((p) => p.tier === tierKey));
		} else {
			setPackages(null);
		}
	}, [packageAll, tierKey]);

	const onClickRedeem = useCallback(() => {
		if (!selectedPackage) return;
		setRedeeming(true);
		RedemptionService.redeemPackage(inUseCode, selectedPackage.id)
			.then(({ data: { data: { tokenCode, token: { title }  } } }) => {
				fetchRedeemedCodeList();
				clearInuseRedeemCode();
				setSelectedPackage(null);
				setSuccessMsg(t(
					'systemMsg:redeemPackageSuccess',
					{ codeName: title[language], redemptionCode: tokenCode },
				))
			})
			.catch((err) => alert(err.message))
			.finally(() => setRedeeming(false));
	}, [clearInuseRedeemCode, fetchRedeemedCodeList, inUseCode, language, selectedPackage, t]);

	return (
		<>
			<InfoModal isOpen={!!successMsg} onClose={() => setSuccessMsg(null)}>
				<SuccessContainer
					title={t('redemption:redeemSuccess').toUpperCase()}
					content={successMsg}
					okText={t('common:ok')}
					onOk={() => setSuccessMsg(null)}
				/>
			</InfoModal>
			<RedeemPackageModal
				isOpen={!!selectedPackage}
				onClose={() => setSelectedPackage(null)}
				packageTitle={selectedPackage?.name?.[language]}
				packageContents={selectedPackage?.reports?.map((r) => r.title?.[language])}
				buttonText={
					selectedPackage?.isRedeemed || redeemedCodeList.some((r) => r.id === selectedPackage?.id)
							? t('reports:unlocked') : t('reports:redeem')}
				buttonDisabled={selectedPackage?.isRedeemed || redeemedCodeList.some((r) => r.id === selectedPackage?.id)}
				buttonLoading={redeeming}
				buttonOnClick={onClickRedeem}
			/>
			<MainLayout title={t('package:title')}>
				<CommonHeader />
				<div className="page-main tabs-page-align">
					<div className="width-control">
						{packages?.map((p) => (
							<LongItemCard
								key={p.id}
								title={p.name[language]}
								roundImg
								img={p.imageUrl[language]}
								onClick={() => setSelectedPackage(p)}
							/>
						))}
					</div>
				</div>
			</MainLayout>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	inUseCode: selectInuseRedeemCode(state),
	redeemedCodeList: selectPendingRedeemedCodeList(state),
	packageAll: selectPackageAll(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchRedeemedCodeList: () =>
		dispatch(RedemptionActions.fetchRedeemedCodeList()),
	clearInuseRedeemCode: () =>
		dispatch(RedemptionActions.clearInuseRedemptionCode()),
	fetchPackageAll: () => dispatch(ProductsActions.fetchPackageAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RedeemPackage);
