import {all} from 'redux-saga/effects';
import {reducer, State as ReportsReducerState} from './reducer';
import * as ReportsActions from './actions';
import {
  watchFetchAllReports,
  watchFetchGradingDetails,
  watchFetchSingleReports,
  watchFetchTrendingReports,
} from './saga';

function* ReportsSaga() {
  yield all([
    watchFetchSingleReports(),
    watchFetchAllReports(),
    watchFetchTrendingReports(),
    watchFetchGradingDetails(),
  ]);
}

export {ReportsSaga, ReportsActions};
export type {ReportsReducerState};
export default reducer;
