import React from 'react';
import { range } from 'lodash';
import './RatingMeter.scss';

interface Props {
	score: number;
}

const RatingMeter = ({ score }: Props): JSX.Element => (
	<div className="rating-meter">
		<div className="rating-meter-stars">
			{range(5).map((i) => {
				const stop = Math.min(((score - i) * 100), 100).toFixed(0);
				return (
				<div
					key={`rating-meter-star-${+i}`}
					className="rating-meter-star"
				>
					<div
						className="rating-meter-star-inner"
						style={{
							background: `linear-gradient(90deg, #f7b500 ${stop}%, #ffffff ${stop}%)`,
						}}
					/>
				</div>
				);
			})}
		</div>
		<span>{score.toFixed(1)}</span>
	</div>
);

export default RatingMeter;
