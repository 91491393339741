import { FormControlLabel, FormControlLabelProps, Radio } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import React from 'react';

const CustomFormControlLabel = withStyles({
	root: {
		color: '#959695',
		fontWeight: 600,
		fontSize: '16px',
		justifyContent: 'flex-start',
		margin: '0',
	},
	label: {
		flex: '1 0 auto',
		whiteSpace: 'nowrap',
		textAlign: 'right',
	},
})(FormControlLabel);

const CustomRadio = withStyles({
	root: {
		flex: '0 0 32px',
		color: '#979797',
		margin: '0',
		boxSizing: 'border-box',
	},
	checked: {
		color: '#76e2d2 !important',
	}
})(Radio);

interface Props {
	label: string;
	value: string;
}

const CommonRadioItem = ({ value, label, ...props }: Props | FormControlLabelProps): JSX.Element => (
	<CustomFormControlLabel
		value={value}
		label={label}
		control={<CustomRadio />}
		{...props}
	/>
);

export default CommonRadioItem;
