import CryptoJS from 'crypto-js';
import {LoginConfig} from '../configs';

// // AesCrtpto's result is base64 encode
// const loginEncrypt = async (text: string): Promise<string> => {
//   const keyBytes = aesjs.utils.utf8.toBytes(LoginConfig.keypath);
//   const ivBytes = aesjs.utils.utf8.toBytes(LoginConfig.iv);
//   console.log('keyBytes: ' + keyBytes + ' ivBytes: ' + ivBytes);
//   // Convert text to bytes (text must be a multiple of 16 bytes)
//   const textBytes = aesjs.utils.utf8.toBytes(text);
//   const paddedTextBytes = aesjs.padding.pkcs7.pad(textBytes);
//   // console.log("paddedTextBytes: " + paddedTextBytes);
//   const aesCbc = new aesjs.ModeOfOperation.cbc(keyBytes, ivBytes.slice(0, 16));
//   const encryptedBytes = aesCbc.encrypt(paddedTextBytes);
//   // To print or store the binary data, you may convert it to hex
//   const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
//   // console.log("encryptedHex: " + encryptedHex);
//   const encodedEncryptedHex = base64.encode(encryptedHex);
//   return encodedEncryptedHex;
// };

const pwEncrypt = (text: string) => {
  console.log('startEncryption');
  const encryptedPW = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    LoginConfig.secret,
  );
  const encryptedBase64PW = encryptedPW.toString();
  // console.log('abc123123', encryptedBase64PW);
  // const decryptedPW = CryptoJS.AES.decrypt(encryptedPW, LoginConfig.secret);
  // console.log('cde123123', decryptedPW);

  return encryptedBase64PW;
};

const CryptoHelper = {
  pwEncrypt,
};

export default CryptoHelper;
