import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './en.json';
import cn from './cn.json';
import zh from './zh.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  debug: true,
  resources: {
    en,
    cn,
    zh,
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
