import {action} from 'typesafe-actions';
import {News} from '../../models/News';

export const FETCH_NEWS = 'news/FETCH_NEWS';
export const FETCH_NEWS_SUCCESS = 'news/FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAIL = 'new/FETCH_NEWS_FAIL';

export const FETCH_BOOKMARK_LIST = 'news/FETCH_BOOKMARK_LIST';
export const FETCH_BOOKMARK_LIST_SUCCESS = 'news/FETCH_BOOKMARK_LIST_SUCCESS';
export const FETCH_BOOKMARK_LIST_FAIL = 'new/FETCH_BOOKMARK_LIST_FAIL';

export const FETCH_NEWS_BY_ID = 'news/FETCH_NEWS_BY_ID';
export const FETCH_NEWS_BY_ID_SUCCESS = 'news/FETCH_NEWS_BY_ID_SUCCESS';
export const FETCH_NEWS_BY_ID_FAIL = 'news/FETCH_NEWS_BY_ID_FAIL';

// export const CREATE_BOOKMARK = 'news/CREATE_BOOKMARK';
// export const CREATE_BOOKMARK_SUCCESS = 'news/CREATE_BOOKMARK_SUCCESS';
// export const CREATE_BOOKMARK_FAIL = 'news/CREATE_BOOKMARK_FAIL';

// export const DELETE_BOOKMARK = 'news/DELETE_BOOKMARK';
// export const DELETE_BOOKMARK_SUCCESS = 'news/DELETE_BOOKMARK_SUCCESS';
// export const DELETE_BOOKMARK_FAIL = 'news/DELETE_BOOKMARK_FAIL';

export const fetchNews = () => action(FETCH_NEWS);
export const fetchNewsSuccess = (news: News[]) =>
  action(FETCH_NEWS_SUCCESS, {news});
export const fetchNewsFail = (error: string) =>
  action(FETCH_NEWS_FAIL, {error});

export const fetchBookmarkList = () => action(FETCH_BOOKMARK_LIST);
export const fetchBookmarkListSuccess = (news: News[]) =>
  action(FETCH_BOOKMARK_LIST_SUCCESS, {news});
export const fetchBookmarkListFail = (error: string) =>
  action(FETCH_BOOKMARK_LIST_FAIL, {error});

// export const createBookmark = (id: string) => action(CREATE_BOOKMARK, {id});
// export const createBookmarkSuccess = (news: News[]) =>
//   action(CREATE_BOOKMARK_SUCCESS, {news});
// export const createBookmarkFail = (error: string) =>
//   action(CREATE_BOOKMARK_FAIL, {error});

// export const deleteBookmark = (id: string) => action(DELETE_BOOKMARK, {id});
// export const deleteBookmarkSuccess = (news: News[]) =>
//   action(DELETE_BOOKMARK_SUCCESS, {news});
// export const deleteBookmarkFail = (error: string) =>
//   action(DELETE_BOOKMARK_FAIL, {error});

export const fetchNewsById = (id: string) => action(FETCH_NEWS_BY_ID, {id});
export const fetchNewsByIdSuccess = (news: News[]) =>
  action(FETCH_NEWS_BY_ID_SUCCESS, {news});
export const fetchNewsByIdFail = (error: string) =>
  action(FETCH_NEWS_BY_ID_FAIL, {error});
