import {ReduxReducerState} from '../RootReducer';
import {createSelector} from 'reselect';

export const selectNews = (state: ReduxReducerState) => state.news;
export const selectId = (_: any, id: string) => id;

export const selectNewsItems = createSelector(selectNews, (news) => news.news);

export const selectNewsItemById = createSelector(
  selectNewsItems,
  selectId,
  (news, id) => news && news.find((x) => x.id === id),
);

export const selectBookmarkList = createSelector(selectNewsItems, (news) =>
  news.filter((x) => x.isBookmarked === true),
);
