import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import 'moment/locale/zh-hk';
import 'moment/locale/zh-cn';
import SEO from "../SEO/SEO";
// import { setAxiosLang } from "../../api/axios";
import ErrorModalController from "../../controller/ErrorModalController";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import "@fontsource/montserrat";
import "@fontsource/noto-sans-tc";
import "./MainLayout.scss";

interface Props {
  title?: string;
  path?: string;
  children: React.ReactNode;
}

const MainLayout = ({ title, path, children }: Props): JSX.Element => {
  const { i18n: { language } } = useTranslation();

  useEffect(() => {
    // setAxiosLang(locale);
    moment.locale(language === 'zh' ? 'zh-HK' : language === 'cn' ? 'zh-CN' : 'en');
  }, [language]);

  return (
    <>
      <SEO title={title} path={path} />
      <LoadingOverlay />
      <ErrorModalController />
      {/* <SnackbarProvider maxSnack={3}> */}
      <div className="main-layout">
        <div className="main-layout-wrapper">{children}</div>
      </div>
      {/* </SnackbarProvider> */}
    </>
  );
};

export default MainLayout;
