import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CommonHeader from "../../components/CommonHeader/CommonHeader";
import MainLayout from "../../components/MainLayout/MainLayout";
import { selectAccessToken, selectAuthError, selectIsGuest } from "../../store/Auth/selectors";
import * as AuthActions from "../../store/Auth/actions";
import { ReduxReducerState } from "../../store/RootReducer";
import { Dispatch } from "redux";
import { useForm } from "react-hook-form";
import FormErrorContainer from "../../components/FormErrorContainer/FormErrorContainer";
import { useTranslation } from "react-i18next";
import RoundButton from "../../components/RoundButton/RoundButton";
import { Link, useHistory } from "react-router-dom";
import InfoModal from "../../components/InfoModal/InfoModal";
import ForgetPasswordModal from "./components/ForgetPasswordModal/ForgetPasswordModal";
import ResetPasswordModal from "./components/ResetPasswordModal/ResetPasswordModal";
import SuccessContainer from "../../components/SuccessContainer/SuccessContainer";
import "./Login.scss";
import styles from '../../styles/modules/FormStyles.module.scss';

type Props = {
  isGuest: boolean;
  login: (args: {
    email: string;
    password: string;
    fromGuest: boolean;
  }) => void;
  token?: string;

  setGuest: () => void;

  error?: string;
};

const Login = ({ login, isGuest, setGuest, error, token }: Props) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [showForget, setShowForget] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const { t } = useTranslation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const onSubmit = (data: {
    email: string;
    password: string;
    fromGuest: boolean;
  }) => {
    setLoading(true);
    const { email, password, fromGuest } = data;
    login({ email, password, fromGuest });
  };

  const onPressGuest = () => {
    if (isGuest) {
      history.goBack();
    } else {
      setGuest();
    }
  };
  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (window.location.pathname === '/login' && !isGuest) {
      history.replace('/');
    }
  }, [history, isGuest]);

  return (
    <>
      <InfoModal
        isOpen={showSuccess}
        onClose={() => {
          setShowSuccess(false);
          setShowReset(true);
        }}
      >
        <SuccessContainer
          title={t('login:resetPwSuccessHeader')}
          content={t('login:resetPwSuccessDesc')}
          okText={t('common:okay').toUpperCase()}
          onOk={() => {
            setShowSuccess(false);
            setShowReset(true);
          }}
        />
      </InfoModal>
      <ResetPasswordModal
        isOpen={showReset}
        onClose={() => setShowReset(false)}
        email={resetEmail}
        onSuccess={() => {
          setShowReset(false);
        }}
      />
      <ForgetPasswordModal
        isOpen={showForget}
        onClose={() => setShowForget(false)}
        onSuccess={() => {
          setShowForget(false);
          setShowSuccess(true);
        }}
        email={resetEmail}
        setEmail={setResetEmail}
      />
      <MainLayout title={t('login:login')}>
        <CommonHeader />
        <div className="page-main justify-align-center">
          <div className="width-control">
            <div className={`${styles.formContainer} login-container`}>
              <form className={`${styles.form} login-form`} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.generalInputContainer}>
                  <div className="login-input-item">
                    <div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputEmail}`}>
                      <input
                        {...register("email", { required: true })}
                        id="email"
                        type="text"
                        placeholder={t('common:emailAddr')}
                      />
                    </div>
                  </div>
                  <div className="login-input-item">
                    <div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputPassword}`}>
                      <input
                        {...register("password", { required: true })}
                        id="password"
                        type="password"
                        placeholder={t('common:password')}
                      />
                    </div>
                  </div>
                </div>
                <button type="button" className="login-forgot" onClick={() => setShowForget(true)}>
                  {t('login:forgotPw')}
                </button>
                <RoundButton loading={loading} type="submit" text={t('login:login')} />
                {(Object.keys(errors).length > 0 || error) && (
                  <FormErrorContainer msg={error ? t('login:emailPwIncorrect') : t('login:emailAndPasswordRequired')} />
                )}
              </form>

              <div className="login-register-now">
                <span>{t('login:notAMember')}</span>
                <Link to="/signup">{t('login:register')}</Link>
                <span>{t('common:or')}</span>
                <button type="button" onClick={onPressGuest}>
                  {isGuest ? t('common:goBack') : t('login:enterAsGuest')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

const mapStateToProps = (state: ReduxReducerState) => ({
  token: selectAccessToken(state),
  isGuest: selectIsGuest(state),
  error: selectAuthError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  login: (args: { email: string; password: string; fromGuest: boolean }) =>
    dispatch(AuthActions.login(args)),
  setGuest: () => dispatch(AuthActions.setGuest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
