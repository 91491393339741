import {ActionType} from 'typesafe-actions';
import * as RedemptionActions from './actions';

export interface State {
  redeemCodeList: any[];
  redeemedReportsList: any[];
  inUseRedeemCode: string | null;
  error?: string;
}

export const initialState: State = {
  redeemCodeList: [],
  inUseRedeemCode: null,
  redeemedReportsList: [],
};

type RedemptionActionsType = ActionType<typeof RedemptionActions>;

export function reducer(
  state: State = initialState,
  action: RedemptionActionsType,
): State {
  switch (action.type) {
    case RedemptionActions.CREATE_REDEEMED_CODE:
      return {
        ...state,
        error: undefined,
      };
    case RedemptionActions.FETCH_REDEEMED_CODE_LIST_SUCCESS:
      return {
        ...state,
        redeemCodeList: action.payload.redeemedCodeList,
        error: undefined,
      };
    case RedemptionActions.FETCH_REDEEMED_REPORTS_LIST_SUCCESS:
      return {
        ...state,
        redeemedReportsList: action.payload.redeemedReportsList,
        error: undefined,
      };
    case RedemptionActions.SET_INUSE_REDEMPTION_CODE:
      return {
        ...state,
        inUseRedeemCode: action.payload.inUseCode,
        error: undefined,
      };
    case RedemptionActions.CLEAR_INUSE_REDEMPTION_CODE:
      return {
        ...state,
        inUseRedeemCode: null,
      };
    case RedemptionActions.REDEEM_REPORT:
    case RedemptionActions.REDEEM_PACKAGE:
    case RedemptionActions.FETCH_REDEEMED_CODE_LIST:
    case RedemptionActions.FETCH_REDEEMED_REPORTS_LIST:
      return {
        ...state,
        error: undefined,
      };
    case RedemptionActions.FETCH_REDEEMED_CODE_LIST_FAIL:
    case RedemptionActions.FETCH_REDEEMED_REPORTS_LIST_FAIL:
    case RedemptionActions.CREATE_REDEEMED_CODE_FAIL:
    case RedemptionActions.REDEEM_REPORT_FAIL:
    case RedemptionActions.REDEEM_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload.err,
      };
    default:
      return state;
  }
}
