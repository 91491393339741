import axios from './AxiosInstance';

const fetchBenefits = (page = 1, pageSize = 20, filter?: Record<string, unknown>) => (
	axios.get('/benefit', { params: { page, pageSize, filter } })
);

const BenefitsService = {
	fetchBenefits,
};

export default BenefitsService;
