import {
  watchFetchAllBookmarks,
  watchFetchMail,
  watchFetchSystemProperty,
  watchFetchTransactionHistory,
} from './saga';
import * as ProfileActions from './actions';
import {all} from 'redux-saga/effects';
import {reducer, State as ProfileReducerState} from './reducer';

function* ProfileSaga() {
  yield all([
    watchFetchMail(),
    watchFetchAllBookmarks(),
    watchFetchTransactionHistory(),
    watchFetchSystemProperty(),
  ]);
}

export {ProfileSaga, ProfileActions};
export type {ProfileReducerState};
export default reducer;
