import { RadioGroup } from '@material-ui/core';
// import moment from 'moment';
import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import CommonHeader from '../../components/CommonHeader/CommonHeader';
import CommonRadioItem from '../../components/CommonRadioItem/CommonRadioItem';
import InfoModal from '../../components/InfoModal/InfoModal';
import MainLayout from '../../components/MainLayout/MainLayout';
import SuccessContainer from '../../components/SuccessContainer/SuccessContainer';
import Config from '../../configs/Config';
import { UserV2 } from '../../models/User';
import { AuthActions } from '../../store/Auth';
import { selectIsGuest, selectUser } from '../../store/Auth/selectors';
import { ProfileActions } from '../../store/Profile';
import ForgetPasswordModal from '../Login/components/ForgetPasswordModal/ForgetPasswordModal';
import ResetPasswordModal from '../Login/components/ResetPasswordModal/ResetPasswordModal';
import './Settings.scss';

interface Props {
	user?: UserV2;
	isGuest: boolean;
	logout: () => void;
	fetchSystemProperty: () => void;
	changeUserLanguage: (lang: string) => void;
}

const Settings = ({ user, isGuest, logout, fetchSystemProperty, changeUserLanguage }: Props): JSX.Element => {
	const { t, i18n: { language, changeLanguage } } = useTranslation();
	const history = useHistory();

	const [resetEmail, setResetEmail] = useState('');
	const [showForget, setShowForget] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [showReset, setShowReset] = useState(false);

	const onChangeLanguage = (lang: string) => {
		changeLanguage(lang);
		if (!isGuest && user) {
			changeUserLanguage(lang);
		}
	};

	useEffect(() => { 
		fetchSystemProperty()
	}, [fetchSystemProperty]);

	return (
		<>
			{!isGuest && (
				<>
					<InfoModal
						isOpen={showSuccess}
						onClose={() => {
							setShowSuccess(false);
							setShowReset(true);
						}}
					>
						<SuccessContainer
						title={t('login:resetPwSuccessHeader')}
						content={t('login:resetPwSuccessDesc')}
						okText={t('common:okay').toUpperCase()}
						onOk={() => {
							setShowSuccess(false);
							setShowReset(true);
						}}
						/>
					</InfoModal>
					<ResetPasswordModal
						isOpen={showReset}
						onClose={() => setShowReset(false)}
						email={resetEmail}
						onSuccess={() => {
							setShowReset(false);
						}}
					/>
					<ForgetPasswordModal
						isOpen={showForget}
						onClose={() => setShowForget(false)}
						onSuccess={() => {
						setShowForget(false);
						setShowSuccess(true);
						}}
						email={resetEmail}
						setEmail={setResetEmail}
					/>
				</>
			)}
			<MainLayout title={t('navigation:settings')}>
				<CommonHeader />
				<div className="page-main justify-align-center">
					<div className="width-control" id="settings-page">
						<section>
							<h2>{t('settings:general')}</h2>
							<div className="settings-table">
								<span className="settings-label">{t('settings:language').toUpperCase()}</span>
								<RadioGroup
									value={language}
									onChange={(e) => onChangeLanguage(e.target.value)}
									name="language-group"
									className="settings-label-radios"
								>
									<CommonRadioItem value="zh" label="中文 (繁體)" />
									<CommonRadioItem value="cn" label="中文 (簡體)" />
									<CommonRadioItem value="en" label="ENGLISH" />
								</RadioGroup>
								{user && !isGuest && (
									<button className="settings-label" onClick={() => setShowForget(true)}>
										{t('settings:resetPassword').toUpperCase()}
									</button>
								)}
								{user && !isGuest ? (
									<button 
										className="settings-label"
										onClick={() => {
											logout();
											history.push('/');
										}}
									>
										{t('settings:logout').toUpperCase()}
									</button>
								) : (
									<button className="settings-label" onClick={() => history.push('/login')}>
										{t('settings:signupLogin').toUpperCase()}
									</button>
								)}
							</div>
						</section>
						<section>
							<h2>{t('settings:privacy')}</h2>
							<div className="settings-table">
								<Link to="/settings/policies/privacy" className="settings-label mod-arrow">
									{t('settings:privacyPolicy')}
								</Link>
								<Link to="/settings/policies/copyright" className="settings-label mod-arrow">
									{t('settings:copyrightAndDisclaimer')}
								</Link>
								<Link to="/settings/policies/personalInfo" className="settings-label mod-arrow">
									{t('settings:personalDataCollection')}
								</Link>
								<span className="settings-label">
									{`${t('settings:appVersion')} ${Config.VERSION}`}
								</span>
							</div>
						</section>
					</div>
				</div>
			</MainLayout>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	user: selectUser(state),
	isGuest: selectIsGuest(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	logout: () => dispatch(AuthActions.logout()),
	fetchSystemProperty: () => dispatch(ProfileActions.fetchSystemProperty()),
	changeUserLanguage: (lang: string) => dispatch(AuthActions.changeLanguage(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
