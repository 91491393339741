import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import CommonHeader from '../../components/CommonHeader/CommonHeader';
import CommonTab from '../../components/CommonTab/CommonTab';
import CommonTabs from '../../components/CommonTabs/CommonTabs';
import ItemCard from '../../components/ItemCard/ItemCard';
import LongItemCard from '../../components/LongItemCard/LongItemCard';
import MainLayout from '../../components/MainLayout/MainLayout';
import { useIsGuest } from '../../helpers/CustomHook';
import Bookmarked from '../../models/Bookmarked';
import { News as NewsModel } from '../../models/News';
import NewsService from '../../services/NewsService';
import './News.scss';

const News = (): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();
	const isGuest = useIsGuest();
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState<string>('hot');

	const [displayNews, setDisplayNews] = useState<NewsModel[] | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [fetchError, setFetchError] = useState<string | null>(null);

	const onRemoveBookmark = (id: string) => {
		NewsService.deleteBookmark(id)
			.then(() => {
				setCurrentPage(1);
				setDisplayNews(null);
			})
			.catch((err) => setFetchError(err.message));
	};

	/* Infinite Scroll */
	const onFetchNews = useCallback(async () => {
		let fetchedData: { data: NewsModel[] | Bookmarked[], totalPage: number } | null = null;
		const fetchCalls = {
			hot: NewsService.fetchHotNews,
			all: NewsService.fetchNews,
			bookmarked: NewsService.fetchBookmarkList,
		};
		try {
			if (!(currentTab in fetchCalls)) throw new Error('Invalid category');
			fetchedData = (await fetchCalls[currentTab](currentPage, 6)).data.data;
		} catch (err) {
			setFetchError(err.message);
			return;
		}
		if (fetchedData) {
			let { data, totalPage: resTotalPage } = fetchedData;
			// Convert bookmarked to news
			const check = (arr: NewsModel[] | Bookmarked[]): arr is Bookmarked[] => arr[0] && 'newsId' in arr[0];
			data = check(data) ? data.map((d) => d.news).filter((d) => d) : data;

			setDisplayNews([...(displayNews ?? []), ...data]);
			setCurrentPage(currentPage + 1);
			setTotalPage(resTotalPage ?? 1);
		}
	}, [currentPage, currentTab, displayNews]);

	// Reset when switching tab
	useEffect(() => {
		setCurrentPage(1);
		setTotalPage(1);
		setDisplayNews(null);
		setFetchError(null);
	}, [currentTab]);

	// Initial fetch
	useEffect(() => {
		if (!displayNews && !fetchError) {
			onFetchNews()
		}
	}, [onFetchNews, displayNews, fetchError]);

	return (
		<MainLayout title={t('navigation:news')}>
			<CommonHeader />
			<div className="page-main tabs-page-align">
				<div className="width-control">
					<CommonTabs
						value={currentTab}
						onChange={(_, i) => {
							if (isGuest && i === 'bookmarked') {
								history.push('/login');
								return;
							}
							setCurrentTab(i);
						}}
						variant="scrollable"
					>
						<CommonTab value="hot" label={t('news:hot')} />
						<CommonTab value="all" label={t('news:all')} />
						<CommonTab value="bookmarked" label={t('news:bookmarked')} />
					</CommonTabs>
					<div className="news-page-tab">
						<InfiniteScroll
							className={`news-page-tab-scroll ${currentTab === 'bookmarked' ? 'mod-long' : ''}`}
							loader={<div className="news-page-tab-loading"><ClipLoader /></div>}
							hasMore={currentPage <= totalPage}
							next={onFetchNews}
							dataLength={displayNews?.length ?? 0}
						>
							{displayNews?.map((news) => currentTab !== 'bookmarked' ? (
								<ItemCard
									key={news.id}
									to={`/news/${news.id}`}
									title={news.title[language]}
									subTitle={moment(news.updatedAt).format('DD-MM-YYYY, hh:mm a')}
									arrow
									img={news.imageUrl[language]}
								/>
							) : (
								<LongItemCard
									key={news.id}
									to={`/news/${news.id}`}
									title={news.title[language]}
									subTitle={moment(news.updatedAt).format('DD-MM-YYYY, hh:mm a')}
									img={news.imageUrl[language]}
									onClose={() => onRemoveBookmark(news.id)}
								/>
							))}
							{(displayNews?.length ?? 0) === 0 && (
								<div className="news-page-tab-loading">
									<span>{t('news:noNews')}</span>
								</div>
							)}
						</InfiniteScroll>
					</div>
				</div>
			</div>
		</MainLayout>
	);
};

export default News;
