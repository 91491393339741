import {action} from 'typesafe-actions';

export const FETCH_ALL_REPORTS = 'reports/FETCH_ALL_REPORTS';
export const FETCH_ALL_REPORTS_SUCCESS = 'reports/FETCH_ALL_REPORTS_SUCCESS';
export const FETCH_ALL_REPORTS_FAIL = 'reports/FETCH_ALL_REPORTS_FAIL';

export const FETCH_SINGLE_REPORTS = 'reports/FETCH_SINGLE_REPORTS';
export const FETCH_SINGLE_REPORTS_SUCCESS =
  'reports/FETCH_SINGLE_REPORTS_SUCCESS';
export const FETCH_SINGLE_REPORTS_FAIL = 'reports/FETCH_SINGLE_REPORTS_FAIL';

export const FETCH_TRENDING_REPORTS = 'reports/FETCH_TRENDING_REPORTS';
export const FETCH_TRENDING_REPORTS_SUCCESS =
  'reports/FETCH_TRENDING_REPORTS_SUCCESS';
export const FETCH_TRENDING_REPORTS_FAIL =
  'reports/FETCH_TRENDING_REPORTS_FAIL';

export const FETCH_GRADING_DETAILS = 'reports/FETCH_GRADING_DETAILS';
export const FETCH_GRADING_DETAILS_SUCCESS =
  'reports/FETCH_GRADING_DETAILS_SUCCESS';
export const FETCH_GRADING_DETAILS_FAIL = 'reports/FETCH_GRADING_DETAILS_FAIL';

export const fetchAllReports = () => action(FETCH_ALL_REPORTS);
export const fetchAllReportsSuccess = (allReports: any[]) =>
  action(FETCH_ALL_REPORTS_SUCCESS, {allReports});
export const fetchAllReportsFail = (err: string) =>
  action(FETCH_ALL_REPORTS_FAIL, {err});

export const fetchSingleReports = () => action(FETCH_SINGLE_REPORTS);
export const fetchSingleReportsSuccess = (singleReports: any[]) =>
  action(FETCH_SINGLE_REPORTS_SUCCESS, {singleReports});
export const fetchSingleReportsFail = (err: string) =>
  action(FETCH_SINGLE_REPORTS_FAIL, {err});

export const fetchTrendingReports = () => action(FETCH_TRENDING_REPORTS);
export const fetchTrendingReportsSuccess = (trendingReports: any[]) =>
  action(FETCH_TRENDING_REPORTS_SUCCESS, {trendingReports});
export const fetchTrendingReportsFail = (err: string) =>
  action(FETCH_TRENDING_REPORTS_FAIL, {err});

export const fetchGradingDetails = () => action(FETCH_GRADING_DETAILS);
export const fetchGradingDetailsSuccess = (gradingDetails: any[]) =>
  action(FETCH_GRADING_DETAILS_SUCCESS, {gradingDetails});
export const fetchGradingDetailsFail = (err: string) =>
  action(FETCH_GRADING_DETAILS_FAIL, {err});
