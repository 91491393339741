import moment from 'moment';
import React from 'react';
import RatingMeter from '../../../../components/RatingMeter/RatingMeter';
import { UserV2 } from '../../../../models/User';
import './CommentCard.scss';

interface Props {
	author: UserV2;
	score: number;
	content: string;
	createdAt: string;
	imgs?: string[];
}

const CommentCard = ({ author, score, content, createdAt, imgs }: Props): JSX.Element => (
	<div className="product-details-comment-card">
		<div className="product-details-comment-avatar">
			<img src="https://via.placeholder.com/150" alt={`${author.firstName} ${author.lastName}`} />
		</div>
		<span className="product-details-comment-name">{`${author.firstName} ${author.lastName}`}</span>
		<RatingMeter score={score} />
		<div className="product-details-comment-content">
			<p>{content}</p>
			{!!imgs && (
				<div className="product-details-comment-imgs" />
			)}
		</div>
		<div className="product-details-comment-time">
			{moment(createdAt).fromNow()}
		</div>
	</div>
);

export default CommentCard;
