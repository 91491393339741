import { RootState } from 'MyTypes';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import InfoModal from '../../../../components/InfoModal/InfoModal';
import RoundButton from '../../../../components/RoundButton/RoundButton';
import { TokenType } from '../../../../models/TokenType';
import { UserV2 } from '../../../../models/User';
import RedemptionService from '../../../../services/RedemptionService';
import { AuthActions } from '../../../../store/Auth';
import { selectUser } from '../../../../store/Auth/selectors';
import { ProfileActions } from '../../../../store/Profile';
import { RedemptionActions } from '../../../../store/Redemption';
import { ReportsActions } from '../../../../store/Reports';
import './RedeemPopup.scss';

interface Props {
	isOpen: boolean;
	id?: string;
	inUseCode?: string;
	redeemQuota: number;
	onClose: () => void;
	redeemSuccessCallback: (msg: string) => void;
	user?: UserV2;
	itemType?: TokenType;

	fetchRedeemedCodeList: () => void;
	clearInuseRedeemCode: () => void;
	fetchRedeemedReportsList: () => void;
	fetchTransactionHistory: () => void;
	fetchUser: () => void;
	fetchSingleReports: () => void;
	fetchTrendingReports: () => void;
	fetchAllReports: () => void;
}

const RedeemPopup = ({
	isOpen, id, inUseCode, redeemQuota, onClose, redeemSuccessCallback, user, itemType,
	fetchRedeemedCodeList, clearInuseRedeemCode, fetchRedeemedReportsList, fetchTransactionHistory,
	fetchUser, fetchSingleReports, fetchTrendingReports, fetchAllReports,
}: Props): JSX.Element => {
	const history = useHistory();
	const { t, i18n: { language } } = useTranslation();
	const [codeInput, setCodeInput] = useState('');
	const [redeeming, setRedeeming] = useState(false);

	const onSuccess = useCallback((codeName: string, redemptionCode: string, quotaLeft: number) => {
		fetchRedeemedCodeList();
		fetchRedeemedReportsList();
		fetchTransactionHistory();
		fetchSingleReports();
		fetchTrendingReports();
		fetchAllReports();
		fetchUser();
		clearInuseRedeemCode();
		redeemSuccessCallback(t('systemMsg:redeemQuotaSuccess', { codeName, redemptionCode, quotaLeft }));
	}, [
		clearInuseRedeemCode, fetchAllReports, fetchRedeemedCodeList, fetchRedeemedReportsList,
		fetchSingleReports, fetchTransactionHistory, fetchTrendingReports, fetchUser, redeemSuccessCallback, t,
	]);

	const onRedeem = async () => {
		if (!id) return;
		const token = inUseCode ?? codeInput;

		// If code is inputted from pop up
		if (codeInput.length > 0) {
			// Validate token and check should allow redeem report
			const shouldRedeemReport = await new Promise((res) => {
				 RedemptionService.validateCode(token)
					.then(({ data: { data } }) => {
						const { title, content: { membershipId, packageTierKey }, type: tokenType } = data;
						if (tokenType !== itemType) {
							alert(t('redemption:codeTypeInvalid'));
							res(false);
							return;
						}
						if (membershipId) {		// Membership code validated
							// Redeem membership code
							RedemptionService.redeemCode(token)
								.then(() => {
									fetchRedeemedCodeList();
									redeemSuccessCallback(
										t('systemMsg:redeemMembershipCodeSuccess',
										{ codeName: title[language], redemptionCode: membershipId },
									));
									res(false);
									return;
								})
						} else if (packageTierKey) {	// Package code validated
							alert(t('systemMsg:enterPackageCodeInAReport'));
							// TODO: Redirect to Redemption page
							history.replace('/redemption');
							res(false);
							return;
						} else { 	// Redemption code validated
							// Redeem redemption code
							RedemptionService.redeemCode(token)
								.then(() => { res(true) }) 	// Allow redeem report if redemption code redeemed successfully
								.catch(() => { res(false) });
						}
					})
					.catch(() => { res(false) });
			});
			// console.log(shouldRedeemReport);
			if (!shouldRedeemReport) return;
		}

		// Redeem report
		await RedemptionService.redeemReport(id, token.length < 1 ? undefined : token)
			.then(({ data: { data }}) => {
				const { token, tokenCode, redeemedProductIds } = data;
				onSuccess(token.title[language], tokenCode, Number(token.content.freeRedemptionChance ?? 0) - (redeemedProductIds?.length ?? 0));
			})
			.catch((err) => alert(err.message))
			.finally(() => setCodeInput(''));
	};

	return (
		<InfoModal isOpen={isOpen} onClose={onClose}>
			<div className="redeem-popup">
				{!inUseCode && redeemQuota <= 0 ? (
					<div className="redeem-popup-content">
						<h3>{t('common:opps')}</h3>
						<p className="redeem-popup-description">
							{t('redemption:inputCodeDescFromReports', { name: `${user?.firstName ?? ''} ${user?.lastName ?? ''}` })}
						</p>
						<input
							type="text"
							value={codeInput}
							onChange={(e) => setCodeInput(e.target.value)}
							placeholder={t('redemption:inputCodePlaceholder')}
						/>
					</div>
				) : (
					<div className="redeem-popup-content">
						<h3>{t('redemption:almostThere')}</h3>
						<p className="redeem-popup-description">
							{t('redemption:confirmRedemption')}
						</p>
					</div>
				)}
				<RoundButton
					text={t('reports:redeem')}
					onClick={() => {
						setRedeeming(true);
						onRedeem().finally(() => setRedeeming(false));
					}}
					loading={redeeming}
				/>
			</div>
		</InfoModal>
	);
};

const mapStateToProps = (state: RootState) => ({
	user: selectUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchRedeemedCodeList: () =>
	  dispatch(RedemptionActions.fetchRedeemedCodeList()),
	clearInuseRedeemCode: () =>
	  dispatch(RedemptionActions.clearInuseRedemptionCode()),
	fetchAllBookmarks: () => dispatch(ProfileActions.fetchAllBookmarks()),
	fetchRedeemedReportsList: () =>
	  dispatch(RedemptionActions.fetchRedeemedReportsList()),
	fetchTransactionHistory: () =>
	  dispatch(ProfileActions.fetchTransactionHistory()),
	fetchUser: () => dispatch(AuthActions.fetchUser()),
	fetchSingleReports: () => dispatch(ReportsActions.fetchSingleReports()),
	fetchAllReports: () => dispatch(ReportsActions.fetchAllReports()),
	fetchTrendingReports: () => dispatch(ReportsActions.fetchTrendingReports()),
  });

export default connect(mapStateToProps, mapDispatchToProps)(RedeemPopup);
