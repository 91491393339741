import axios from './AxiosInstance';

const fetchMail = (page: number) =>
  axios.get(`user/inbox?page=${page}&pageSize=10`);

const markRead = (messageId: string) =>
  axios.post('v1/mailbox/readMessage', {messageId});

const MailService = {
  fetchMail,
  markRead,
};

export default MailService;
