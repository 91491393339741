import { minBy } from 'lodash';
import { RootState } from 'MyTypes';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import CommonHeader from '../../../components/CommonHeader/CommonHeader';
import InfoModal from '../../../components/InfoModal/InfoModal';
import LongItemCard from '../../../components/LongItemCard/LongItemCard';
import MainLayout from '../../../components/MainLayout/MainLayout';
import SuccessContainer from '../../../components/SuccessContainer/SuccessContainer';
import PackageType from '../../../models/Package';
import ProductType from '../../../models/ProductType';
import RedemptionCode from '../../../models/RedemptionCode';
import { RedeemCodeResponse } from '../../../models/RedemptionCodeResponse';
import ProductsService from '../../../services/ProductsService';
import RedemptionService from '../../../services/RedemptionService';
import { selectProductsType } from '../../../store/Products/selectors';
import { RedemptionActions } from '../../../store/Redemption';
import { selectPendingRedeemedCodeList } from '../../../store/Redemption/selectors';
import { ReportsActions } from '../../../store/Reports';
import RedeemPackageModal from '../components/RedeemPackageModal/RedeemPackageModal';

interface Props {
	productTypes: ProductType[];
	redeemedCodeList?: RedemptionCode[];
	fetchProductTypes: () => void;
	fetchRedeemedCodeList: () => void;
}

const Packages = ({ productTypes, redeemedCodeList, fetchProductTypes, fetchRedeemedCodeList }: Props): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();
	const history = useHistory();

	const [packages, setPackages] = useState<PackageType[] | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [fetchError, setFetchError] = useState<string | null>(null);
	
	const [selectedPackage, setSelectedPackage] = useState<PackageType | null>(null);
	const [redeeming, setRedeeming] = useState(false);
	const [redeemError, setRedeemError] = useState<string | null>(null);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);

	useEffect(() => {
		if (!productTypes) fetchProductTypes();
	}, [fetchProductTypes, productTypes]);

	useEffect(() => {
		fetchRedeemedCodeList();
	}, [fetchRedeemedCodeList]);

	/* Infinite Scroll */
	const onFetchPackages = useCallback(() => {
		ProductsService.fetchPackageAll(currentPage)
			.then(({ data: { data: { data, totalPage: resTotalPage } } }) => {
				setPackages([...(packages ?? []), ...data]);
				setCurrentPage(currentPage + 1);
				setTotalPage(resTotalPage);
			})
			.catch((err) => setFetchError(err.message));
	}, [currentPage, packages]);

	// Initial fetch
	useEffect(() => {
		if (!packages && !fetchError) {
			onFetchPackages();
		}
	}, [fetchError, onFetchPackages, packages]);

	const onClickRedeem = async (code?: string) => {
		if (!selectedPackage) return;

		setRedeeming(true);
		setRedeemError(null);

		try {
			let redeemResponse: RedeemCodeResponse | null;
			/* Using redeemed code */
			if (code === undefined) {
				const usableCodes = redeemedCodeList.filter((code) => (
					code.token?.content?.packageTierKey === selectedPackage.tier && code.isPending
				));
				if (usableCodes.length === 0) throw new Error ('No usable codes');

				const expireSoonest = minBy(usableCodes, (code) => new Date(code.token.endDate));
				redeemResponse = (await RedemptionService.redeemPackage(expireSoonest.tokenCode, selectedPackage.id)).data.data;
				
			/* Entered new code */
			} else {
				const { data: { data: validationResponse } } = await RedemptionService.validateCode(code);
				if (validationResponse.content?.packageTierKey === selectedPackage.tier) {
					const { data: { data: { token } } } = await RedemptionService.redeemCode(code);
					if (!token) {
						throw new Error('This package cannot be redeemed by your token.');
					}
					redeemResponse = (await RedemptionService.redeemPackage(code, selectedPackage.id)).data.data;
				} else {
					throw new Error('This package cannot be redeemed by your token.');
				}
			}
			setSuccessMessage(t('systemMsg:redeemPackageSuccess', {
				codeName: redeemResponse.token.title[language],
				redemptionCode: redeemResponse.tokenCode,
			}));
			setSelectedPackage(null);
		} catch (err) {
			setRedeemError(err.message);
		}
		setRedeeming(false);
	};

	return (
		<>
			<InfoModal isOpen={!!successMessage} onClose={() => setSuccessMessage(null)}>
				<SuccessContainer
					title={t('redemption:redeemSuccess').toUpperCase()}
					content={successMessage}
					okText={t('common:ok')}
					onOk={() => history.push('/redemption')}
				/>
			</InfoModal>
			<RedeemPackageModal
				isOpen={!!selectedPackage}
				onClose={() => setSelectedPackage(null)}
				packageTitle={selectedPackage?.name?.[language]}
				packageContents={selectedPackage?.reports?.map((r) => r.title?.[language])}
				packageBottomText={
					!redeemedCodeList?.some((r) => r.token?.content?.packageTierKey === selectedPackage?.tier)
						? t('redemption:inputCodeDesc') : undefined
				}
				buttonText={selectedPackage?.isRedeemed ? t('reports:unlocked') : t('reports:redeem')}
				buttonDisabled={selectedPackage?.isRedeemed}
				buttonLoading={redeeming}
				buttonOnClick={onClickRedeem}
				showInput={!redeemedCodeList?.some((r) => r.token?.content?.packageTierKey === selectedPackage?.tier)}
				errorMsg={redeemError}
			/>
			<MainLayout
				title={productTypes?.find((productType) => productType.key === 'package')?.name?.[language]?? 'Packages'}
			>
				<CommonHeader />
				<div className="page-main tabs-page-align">
					<div className="width-control">
						<InfiniteScroll
							loader={<div className="infinite-scroll-center"><ClipLoader /></div>}
							hasMore={currentPage <= totalPage}
							next={onFetchPackages}
							dataLength={packages?.length ?? 0}
						>
							{packages?.map((pack) => (
								<LongItemCard
									key={pack.id}
									img={pack.imageUrl[language]}
									title={pack.name[language]}
									roundImg
									onClick={() => setSelectedPackage(pack)}
								/>
							))}
							{!!packages && packages.length === 0 && (
								<div className="infinite-scroll-center">
									<span>No packages</span>
								</div>
							)}
						</InfiniteScroll>
					</div>
				</div>
			</MainLayout>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	productTypes: selectProductsType(state),
	redeemedCodeList: selectPendingRedeemedCodeList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchProductType: () => dispatch(ReportsActions.fetchAllReports()),
	fetchRedeemedCodeList: () => dispatch(RedemptionActions.fetchRedeemedCodeList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
