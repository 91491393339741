import React from 'react';
import RoundButton from '../RoundButton/RoundButton';
import TICK_ICON from '../../assets/images/tick.png';
import './SuccessContainer.scss';

interface Props {
	title?: string;
	content?: string;
	okText?: string;
	onOk?: () => void;
}

const SuccessContainer = ({ title = '', content = '', okText = 'OK', onOk }: Props): JSX.Element => (
	<div className="success-container">
		<h2>{title}</h2>
		<img src={TICK_ICON} alt="Success" />
		<p>{content}</p>
		<RoundButton text={okText} onClick={onOk} />
	</div>
);

export default SuccessContainer;
