import React from 'react';
import { Link } from 'react-router-dom';
import ARROW_ICON from '../../../../assets/images/nextBtn.png';
import './PendingReportCard.scss';

interface Props {
	to: string;
	img?: string;
	title?: string;
	subtitle?: string;
	unread?: boolean;
	onRemove?: () => void;
}

const PendingReportCard = ({
	to, img, title, subtitle, unread, onRemove,
}: Props): JSX.Element => (
	<div className="pending-report-card">
		<Link to={to} className="pending-report-card-content">
			<img className="pending-report-card-img" src={img} alt={title} />
			<span className="pending-report-card-title">
				{title}
			</span>
			<span className="pending-report-card-subtitle">
				{subtitle}
			</span>
			<div className="pending-report-card-arrow">
				<img src={ARROW_ICON} alt=">" />
			</div>
		</Link>
		<button type="button" onClick={onRemove} className="pending-report-card-remove">
			{' '}
		</button>
		{unread && (<div className="pending-report-card-unread" />)}
	</div>
);

export default PendingReportCard;
