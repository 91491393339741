import * as React from 'react';

export const navigationRef: any = React.createRef();

export function navigate(name: string, params?: any, goBack?: boolean) {
  console.log('navigationRef.current', navigationRef.current);
  if (goBack) {
    navigationRef.current?.goBack();
  } else navigationRef.current?.navigate(name, params);
}
