import {takeLatest, put, call} from 'redux-saga/effects';
import * as RedemptionActions from './actions';
// import {ActionType} from 'typesafe-actions';
import RedemptionService from '../../services/RedemptionService';
import {ActionType} from 'typesafe-actions';

export function* handleRedeemCode(
  action: ActionType<typeof RedemptionActions.createRedeemedCode>,
) {
  try {
    const {
      payload: {token},
    } = action;

    const response = yield call(RedemptionService.redeemCode, token);

    const {
      data: {
        data: {data},
      },
    } = response;

    // console.log('handleRedeemCode', data);

    yield put(RedemptionActions.createRedeemedCodeSuccess(data));
  } catch (err) {
    yield put(RedemptionActions.createRedeemedCodeFail(err.message));
  }
}

export function* watchRedeemCode() {
  yield takeLatest(
    RedemptionActions.CREATE_REDEEMED_CODE_FAIL,
    handleRedeemCode,
  );
}

export function* handleRedeemCodeList() {
  try {
    const response = yield call(RedemptionService.fetchRedeemedCodeList);

    const {
      data: {
        data: {data},
      },
    } = response;

    // console.log('handleRedeemCodeList', data);

    yield put(RedemptionActions.fetchRedeemedCodeListSuccess(data));
  } catch (err) {
    yield put(RedemptionActions.fetchRedeemedCodeListFail(err.message));
  }
}

export function* watchRedeemCodeList() {
  yield takeLatest(
    RedemptionActions.FETCH_REDEEMED_CODE_LIST,
    handleRedeemCodeList,
  );
}

export function* handleRedeemedReportsList() {
  try {
    const response = yield call(RedemptionService.fetchRedeemedReportsList);

    const {
      data: {
        data: {data},
      },
    } = response;

    yield put(RedemptionActions.fetchRedeemedReportsListSuccess(data));
    // console.log('fetchRedeemedReportsList123', data);
  } catch (err) {
    // console.log('fetchRedeemedReportsList456', err);
    yield put(RedemptionActions.fetchRedeemedReportsListFail(err.message));
  }
}

export function* watchRedeemedReportsList() {
  yield takeLatest(
    RedemptionActions.FETCH_REDEEMED_REPORTS_LIST,
    handleRedeemedReportsList,
  );
}
