import React from 'react';
import './SearchBar.scss';

interface Props {
	placeholder?: string;
	value: string;
	onChange: (v: string) => void;
}

const SearchBar = ({ placeholder, value, onChange }: Props): JSX.Element => (
	<div className="search-bar">
		<input
			type="text" 
			placeholder={placeholder}
			value={value}
			onChange={(e) => onChange(e.target.value)}
		/>
	</div>
);

export default SearchBar;

