import {
  watchChangeLanguage,
  watchFetchUser,
  watchLogin,
  watchReplaceToken,
  watchSetGuest,
} from './saga';
import {all} from 'redux-saga/effects';
import {reducer, State as AuthReducerState} from './reducer';
import * as AuthActions from './actions';

function* AuthSaga() {
  yield all([
    watchLogin(),
    watchReplaceToken(),
    watchSetGuest(),
    watchChangeLanguage(),
    watchFetchUser(),
  ]);
}

export {AuthSaga, AuthActions};
export type {AuthReducerState};
export default reducer;
