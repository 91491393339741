import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { selectIsGuest } from "../store/Auth/selectors";
import { ErrorModalActions } from "../store/ErrorModal";
import { LoadingOverlayActions } from "../store/LoadingOverlay";

export function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const useQuery = (): URLSearchParams => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const useErrorModal = (): {
  setErrorModal: (msg?: string, onClose?: () => void) => void;
} => {
  const dispatch = useDispatch();
  const setErrorModal = (msg?: string, onClose?: () => void) =>
    dispatch(ErrorModalActions.setErrorModal(msg, onClose));

  return { setErrorModal };
};

export const useLoadingOverlay = (): {
  setShowLoadingOverlay: (show: boolean) => void;
} => {
  const dispatch = useDispatch();
  const setShowLoadingOverlay = (show: boolean) =>
    dispatch(LoadingOverlayActions.setShowLoadingOverlay(show));

  return { setShowLoadingOverlay };
};

export const useIsGuest = (): boolean => {
  const isGuest = useSelector(selectIsGuest);
  return isGuest;
};
