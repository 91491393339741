import React, { useCallback } from "react";
import { connect } from "react-redux";
import { RootState } from "MyTypes";
import ErrorModal from "../components/ErrorModal/ErrorModal";
import {
  selectErrorModalMsg,
  selectErrorModalOnClose,
} from "../store/ErrorModal/selector";
import { useErrorModal } from "../helpers/CustomHook";

interface Props {
  msg?: string;
  onClose?: () => void;
}

const ErrorModalController = ({ msg, onClose }: Props): JSX.Element => {
  const { setErrorModal } = useErrorModal();
  const onCloseModal = useCallback(() => {
    onClose?.();
    setErrorModal(undefined, undefined);
  }, [onClose, setErrorModal]);

  return (
    <ErrorModal msg={msg} isOpen={msg !== undefined} onClose={onCloseModal} />
  );
};

const mapStateToProps = (state: RootState) => ({
  msg: selectErrorModalMsg(state),
  onClose: selectErrorModalOnClose(state),
});

export default connect(mapStateToProps)(ErrorModalController);
