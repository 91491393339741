import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import { AuthSaga } from "./Auth";
import { NewsSaga } from "./News";
import { ProductsSaga } from "./Products";
import { ProfileSaga } from "./Profile";
import { RedemptionSaga } from "./Redemption";
import { ReportsSaga } from "./Reports";
import { reducer, ReduxReducerState } from "./RootReducer";

export const buildStore = (initialState?: ReduxReducerState) => {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore<ReduxReducerState, any, any, any>(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  function* rootSaga() {
    yield all([AuthSaga(), NewsSaga(), ReportsSaga(), ProductsSaga(), ProfileSaga(), RedemptionSaga()]);
  }

  sagaMiddleware.run(rootSaga);

  return store;
};

const store = buildStore();

export default store;
