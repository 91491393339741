import {all} from 'redux-saga/effects';
import {reducer, State as RedemptionState} from './reducer';
import * as RedemptionActions from './actions';
import {
  watchRedeemCode,
  watchRedeemCodeList,
  watchRedeemedReportsList,
} from './saga';

function* RedemptionSaga() {
  yield all([
    watchRedeemCodeList(),
    watchRedeemCode(),
    watchRedeemedReportsList(),
  ]);
}

export {RedemptionSaga, RedemptionActions};
export type {RedemptionState};
export default reducer;
