import moment from 'moment';
import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import FormErrorContainer from '../../../components/FormErrorContainer/FormErrorContainer';
import InfoModal from '../../../components/InfoModal/InfoModal';
import LongItemCard from '../../../components/LongItemCard/LongItemCard';
import RoundButton from '../../../components/RoundButton/RoundButton';
import SuccessContainer from '../../../components/SuccessContainer/SuccessContainer';
import { Report } from '../../../models/Report';
import TransactionRecord from '../../../models/TransactionRecord';
import { UserV2 } from '../../../models/User';
import RedemptionService from '../../../services/RedemptionService';
import { selectUser } from '../../../store/Auth/selectors';
import { ProfileActions } from '../../../store/Profile';
import { RedemptionActions } from '../../../store/Redemption';
import { selectRedeemedReportsList } from '../../../store/Redemption/selectors';
import styles from '../../../styles/modules/RedemptionTab.module.scss';

interface Props {
	user?: UserV2;
	redeemedReportsList?: TransactionRecord[];
	onSeeAllReports: () => void;
	fetchRedeemedReportsList: () => void;
	fetchRedeemedCodeList: () => void;
	fetchTransactionHistory: () => void;
}

const RedemptionMainTab = ({
	user,
	redeemedReportsList,
	onSeeAllReports,
	fetchRedeemedReportsList,
	fetchRedeemedCodeList,
	fetchTransactionHistory,
}: Props): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();

	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	const [inputCode, setInputCode] = useState('');
	const [redeeming, setRedeeming] = useState(false);
	const [recentRedeem, setRecentRedeem] = useState<Report[] | null>(null);
	const [fetchError, setFetchError] = useState<string | null>(null);

	useEffect(() => {
		fetchRedeemedReportsList();
	}, [fetchRedeemedReportsList]);

	useEffect(() => {
		if (redeemedReportsList) {
			setRecentRedeem(redeemedReportsList.flatMap((r) => r.reports).slice(0, 4));
		}
	}, [redeemedReportsList]);

	const onSubmit = async () => {
		setRedeeming(true);
		setFetchError(null);
		try {
			// Validate code
			const { data: { data: validateResponse } } = await RedemptionService.validateCode(inputCode);
			if (!validateResponse) {
				throw new Error('Failed to validate code');
			}

			// Redeem code
			const { data: { data: redeemResponse } } = await RedemptionService.redeemCode(inputCode);
			if (!redeemResponse) {
				throw new Error('Failed to redeem code');
			}

			// Refresh profile store
			fetchRedeemedCodeList();
			fetchTransactionHistory();

			// Generate success message
			const { content: { membershipId, packageTierKey } } = validateResponse;
			if (membershipId) {
				const { title } = validateResponse;
				setSuccessMessage(t(
					'systemMsg:redeemMembershipCodeSuccess',
					{ codeName: title[language], redemptionCode: membershipId },
				));
			} else if (packageTierKey) {
				const { title, token } = validateResponse;
				setSuccessMessage(t(
					'systemMsg:redeemPackageCodeSuccessFromRedemptionPage',
					{ codeName: title[language], redemptionCode: token },
				));
			} else {
				const {
					token: { title, content: { freeRedemptionChance } },
					tokenCode,
					redeemedProductIds: { length: redeemedQuota },
				} = redeemResponse;
				setSuccessMessage(t(
					'systemMsg:redeemReportCodeSuccessFromRedemptionPage',
					{
						codeName: title[language],
						redemptionCode: tokenCode,
						quotaLeft: Number(freeRedemptionChance ?? 0) - (redeemedQuota ?? 0),
					}
				));
			}
			setRedeeming(false);
			setInputCode('');
		} catch (error) {
			setFetchError(error.message);
			setRedeeming(false);
		}
	};

	return (
		<>
			<InfoModal isOpen={!!successMessage} onClose={() => setSuccessMessage(null)}>
				<SuccessContainer
					title={t('redemption:redeemSuccess').toUpperCase()}
					content={successMessage}
					okText={t('common:ok')}
					onOk={() => setSuccessMessage(null)}
				/>
			</InfoModal>
			<div className={styles.redemptionTab}>
				<section className={styles.redeemCodeSection}>
					<p className={styles.redeemCodeDesc}>
						{`${t('common:thankYou')} ${user?.firstName} ${user?.lastName}\n${t('redemption:inputCodeDesc')}`}
					</p>
					<input
						className={styles.redeemCodeInput}
						value={inputCode}
						onChange={(e) => setInputCode(e.target.value)}
						type="text"
						placeholder={t('redemption:inputCodePlaceholder')}
					/>
					<RoundButton
						className={styles.roundButton}
						text={t('common:submit')}
						disabled={inputCode.length < 1}
						loading={redeeming}
						onClick={onSubmit}
					/>
					<FormErrorContainer msg={fetchError} />
				</section>
				<section className={styles.recentRedeemSection}>
					<div className={styles.recentRedeemHeader}>
						<span className={styles.recentRedeemTitle}>
							{t('redemption:redeemed')}
						</span>
						<button type="button" className={styles.seeAll} onClick={onSeeAllReports}>
							<span>{t('redemption:seeAll')}</span>
						</button>
					</div>
					<div className={styles.redemptionTabList}>
						{recentRedeem?.map((r) => (
							<LongItemCard
								key={r.id}
								img={r.imageUrl[language]}
								title={r.title[language]}
								subTitle={t('redemption:redeemedOn', { date: moment(r.createdAt).format('DD/MM/YYYY') })}
								showArrow
								to={`/reports/${r.id}`}
							/>
						))}
					</div>
				</section>
			</div>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	user: selectUser(state),
	redeemedReportsList: selectRedeemedReportsList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchRedeemedCodeList: () =>
	  dispatch(RedemptionActions.fetchRedeemedCodeList()),
	fetchRedeemedReportsList: () => dispatch(RedemptionActions.fetchRedeemedReportsList()),
	fetchTransactionHistory: () =>
	  dispatch(ProfileActions.fetchTransactionHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionMainTab);
