import React from "react";
import { Backdrop, Fade } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/styles";
import CLOSE_BUTTON from '../../assets/images/closeBtn.png';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: 'relative',
    maxWidth: "90%",
    maxHeight: "80%",
    overflow: "auto",
    backgroundColor: "#ffffff",
    boxShadow: "5px 1px 5px gray",
    padding: "1.5rem",
    zIndex: 999,
    borderRadius: '10px',
  },
  closeButton: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    width: '12x',
    height: '12px',
    '&>img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  }
}));

const InfoModal = ({ children, isOpen, onClose }: Props) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <button className={classes.closeButton} onClick={onClose}>
            <img src={CLOSE_BUTTON} alt="x" />
          </button>
          {children}
        </div>
      </Fade>
    </Modal>
  );
};

export default InfoModal;
