import {action} from 'typesafe-actions';
// import {Mail} from '../../../types/Mail';

// export const FETCH_MAIL = 'profile/FETCH_MAIL';
// export const FETCH_MAIL_SUCCESS = 'profile/FETCH_MAIL_SUCCESS';
// export const FETCH_MAIL_FAIL = 'profile/FETCH_MAIL_FAIL';

// export const fetchMail = () => action(FETCH_MAIL);
// export const fetchMailSuccess = (mail: Mail[]) =>
//   action(FETCH_MAIL_SUCCESS, {mail});
// export const fetchMailFail = (error: string) =>
//   action(FETCH_MAIL_FAIL, {error});

export const SET_INUSE_REDEMPTION_CODE = 'redemption/SET_INUSE_REDEMPTION_CODE';
export const CLEAR_INUSE_REDEMPTION_CODE =
  'redemption/CLEAR_INUSE_REDEMPTION_CODE';

export const CREATE_REDEEMED_CODE = 'redemption/CREATE_REDEEMED_CODE';
export const CREATE_REDEEMED_CODE_SUCCESS =
  'redemption/CREATE_REDEEMED_CODE_SUCCESS';
export const CREATE_REDEEMED_CODE_FAIL = 'redemption/CREATE_REDEEMED_CODE_FAIL';

export const REDEEM_REPORT = 'redemption/REDEEM_REPORT';
export const REDEEM_REPORT_SUCCESS = 'redemption/REDEEM_REPORT_SUCCESS';
export const REDEEM_REPORT_FAIL = 'redemption/REDEEM_REPORT_FAIL';

export const REDEEM_PACKAGE = 'redemption/REDEEM_PACKAGE';
export const REDEEM_PACKAGE_SUCCESS = 'redemption/REDEEM_PACKAGE_SUCCESS';
export const REDEEM_PACKAGE_FAIL = 'redemption/REDEEM_PACKAGE_FAIL';

export const FETCH_REDEEMED_CODE_LIST = 'redemption/FETCH_REDEEMED_CODE_LIST';
export const FETCH_REDEEMED_CODE_LIST_SUCCESS =
  'redemption/FETCH_REDEEMED_CODE_LIST_SUCCESS';
export const FETCH_REDEEMED_CODE_LIST_FAIL =
  'redemption/FETCH_REDEEMED_CODE_LIST_FAIL';

export const FETCH_REDEEMED_REPORTS_LIST =
  'redemption/FETCH_REDEEMED_REPORTS_LIST';
export const FETCH_REDEEMED_REPORTS_LIST_SUCCESS =
  'redemption/FETCH_REDEEMED_REPORTS_LIST_SUCCESS';
export const FETCH_REDEEMED_REPORTS_LIST_FAIL =
  'redemption/FETCH_REDEEMED_REPORTS_LIST_FAIL';

export const setInuseRedemptionCode = (inUseCode: string) =>
  action(SET_INUSE_REDEMPTION_CODE, {inUseCode});
export const clearInuseRedemptionCode = () => {
  // console.log('clearInuseRedemptionCode123');
  return action(CLEAR_INUSE_REDEMPTION_CODE);
};

export const createRedeemedCode = (token: string) =>
  action(CREATE_REDEEMED_CODE, {token});
export const createRedeemedCodeSuccess = (successMsg: any) =>
  action(CREATE_REDEEMED_CODE_SUCCESS, {successMsg});
export const createRedeemedCodeFail = (err: string) =>
  action(CREATE_REDEEMED_CODE_FAIL, {err});

export const redeemReport = (token: string, reportId: string) =>
  action(REDEEM_REPORT, {token, reportId});
export const redeemReportSuccess = (response: any) =>
  action(REDEEM_REPORT_SUCCESS, {response});
export const redeemReportFail = (err: string) =>
  action(REDEEM_REPORT_FAIL, {err});

export const redeemPackage = (token: string, packageId: string) =>
  action(REDEEM_PACKAGE, {token, packageId});
export const redeemPackageSuccess = (response: any) =>
  action(REDEEM_PACKAGE_SUCCESS, {response});
export const redeemPackageFail = (err: string) =>
  action(REDEEM_PACKAGE_FAIL, {err});

export const fetchRedeemedCodeList = () => action(FETCH_REDEEMED_CODE_LIST);
export const fetchRedeemedCodeListSuccess = (redeemedCodeList: any[]) =>
  action(FETCH_REDEEMED_CODE_LIST_SUCCESS, {redeemedCodeList});
export const fetchRedeemedCodeListFail = (err: string) =>
  action(FETCH_REDEEMED_CODE_LIST_FAIL, {err});

export const fetchRedeemedReportsList = () =>
  action(FETCH_REDEEMED_REPORTS_LIST);
export const fetchRedeemedReportsListSuccess = (redeemedReportsList: any[]) =>
  action(FETCH_REDEEMED_REPORTS_LIST_SUCCESS, {redeemedReportsList});
export const fetchRedeemedReportsListFail = (err: string) =>
  action(FETCH_REDEEMED_REPORTS_LIST_FAIL, {err});
