import { Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const CommonTabs = withStyles({
	root: {
		borderStyle: 'solid',
		borderColor: '#ece9e9',
		borderWidth: '0px 0px 1px 0px',
	},
	indicator: {
		backgroundColor: '#593565',
	},
})(Tabs);

export const RoundTabs = withStyles({
	indicator: {
		display: 'none',
	},
})(Tabs);

export default CommonTabs;
