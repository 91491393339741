import {ReduxReducerState} from '../../store/RootReducer';
import {createSelector} from 'reselect';

export const selectProducts = (state: ReduxReducerState) => state.products;

export const selectProductsType = createSelector(
  selectProducts,
  (products) => products.productTypes,
);

export const selectMembership = createSelector(
  selectProducts,
  (products) => products.membership,
);

export const selectPackageAll = createSelector(
  selectProducts,
  (products) => products.packageAll,
);

export const selectMoreContent = createSelector(selectProducts, (products) => {
  const rearrangedArr = products.moreContent?.sort((a, b) => a.index - b.index) ?? [];
  return rearrangedArr;
});

export const selectLabContent = createSelector(
  selectMoreContent,
  (moreContent) =>
    moreContent.find((content: any) => content && content.key === 'experience'),
);

export const selectBioTechContent = createSelector(
  selectMoreContent,
  (moreContent) =>
    moreContent.find(
      (content: any) => content && content.key === 'bioTestingTech',
    ),
);

export const selectProductStoreType = createSelector(
  selectProducts,
  (products) => products.productStoreType,
);
