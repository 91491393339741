import { MenuItem, Select } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClipLoader } from 'react-spinners';
import CommonHeader from '../../../components/CommonHeader/CommonHeader';
import MainLayout from '../../../components/MainLayout/MainLayout';
import Bookmarked from '../../../models/Bookmarked';
import BookmarkService from '../../../services/BookmarkService';
import moment from 'moment';
import EntryCard from '../components/EntryCard/EntryCard';
import './SavedItems.scss';

const SavedItems = (): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();
	const [filter, setFilter] = useState('all');
	
	const [bookmarked, setBookmarked] = useState<Bookmarked[] | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [fetchError, setFetchError] = useState<string | null>(null);

	/* Infinite Scroll */
	const onFetchBookmarked = useCallback(() => {
		BookmarkService.fetchAllBookmarks(currentPage)
			.then(({ data: { data: { data, totalPage: resTotalPage } } }) => {
				let result = [...(bookmarked ?? []), ...data];
				switch (filter) {
					case 'news':
						result = result.filter((b) => b.newsId);
						break;
					case 'reports':
						result = result.filter((b) => b.reportId);
						break;
					default:
				}
				setBookmarked(result);
				setCurrentPage(currentPage + 1);
				setTotalPage(resTotalPage ?? 1);
			})
			.catch((err) => setFetchError(err.message));
	}, [bookmarked, currentPage, filter]);

	// Reset when switching filter
	useEffect(() => {
		setCurrentPage(1);
		setTotalPage(1);
		setBookmarked(null);
		setFetchError(null);
	}, [filter]);

	// Initial fetch
	useEffect(() => {
		if (!bookmarked && !fetchError) {
			onFetchBookmarked();
		}
	}, [bookmarked, fetchError, onFetchBookmarked]);

	return (
		<MainLayout title="Saved Items">
			<CommonHeader />
			<div className="page-main tabs-page-align">
				<div className="width-control">
					<div className="saved-items-page-header">
						<h2>Saved Items</h2>
						<Select value={filter} onChange={(e) => setFilter(`${e.target.value}`)}>
							<MenuItem value="all">All</MenuItem>
							<MenuItem value="news">{t('navigation:news')}</MenuItem>
							<MenuItem value="reports">{t('navigation:reports')}</MenuItem>
						</Select>
					</div>
					<div className="saved-items-page-content">
						<InfiniteScroll
							loader={<div className="infinite-scroll-center"><ClipLoader /></div>}
							hasMore={currentPage <= totalPage}
							next={onFetchBookmarked}
							dataLength={bookmarked?.length ?? 0}
						>
							{bookmarked?.map((b) => (
								<EntryCard
									key={b.id}
									title={b.news?.title?.[language] ?? b.report?.title?.[language] ?? ''}
									subtitle={`Saved ${moment(b.createdAt).fromNow()}`}
								/>
							))}
							{(bookmarked?.length ?? 0) === 0 && (
								<div className="infinite-scroll-center">
									<span>No Saved Items</span>
								</div>
							)}
						</InfiniteScroll>
					</div>
				</div>
			</div>
		</MainLayout>
	);
};

export default SavedItems;
