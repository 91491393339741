import React from 'react';
import { Link } from 'react-router-dom';
import './MyReportCard.scss';

interface Props {
	img: string;
	categoryName: string;
	categoryIcon: string;
	expiryDate: string;
	title: string;
	to: string;
}

const MyReportCard = ({ img, categoryName, categoryIcon, expiryDate, title, to }: Props): JSX.Element => (
	<Link to={to} className="my-report-card">
		<img className="my-report-card-img" src={img} alt="" />
		<div className="my-report-card-content">
			<img className="my-report-card-category-icon" src={categoryIcon} alt="" />
			<span className="my-report-card-category-name">{categoryName}</span>
			<span className="my-report-card-expiry">{expiryDate}</span>
			<p className="my-report-card-title">{title}</p>
		</div>
	</Link>
);

export default MyReportCard;
