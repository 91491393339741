import {takeLatest, put, call} from 'redux-saga/effects';
import * as ReportsActions from './actions';
// import {ActionType} from 'typesafe-actions';
import ReportService from '../../services/ReportService';

export function* handleFetchAllReports() {
  try {
    const response = yield call(ReportService.fetchAllReports);

    const {
      data: {
        data: {data},
      },
    } = response;

    // console.log('fetchAllReportsSaga2', data);

    yield put(ReportsActions.fetchAllReportsSuccess(data));
  } catch (err) {
    yield put(ReportsActions.fetchAllReportsFail(err.message));
  }
}

export function* watchFetchAllReports() {
  yield takeLatest(ReportsActions.FETCH_ALL_REPORTS, handleFetchAllReports);
}

export function* handleFetchSingleReports() {
  try {
    const response = yield call(ReportService.fetchSingleReports);

    const {
      data: {
        data: {data},
      },
    } = response;

    // console.log('fetchSingleReportsSaga2', data);

    yield put(ReportsActions.fetchSingleReportsSuccess(data));
  } catch (err) {
    yield put(ReportsActions.fetchSingleReportsFail(err.message));
  }
}

export function* watchFetchSingleReports() {
  yield takeLatest(
    ReportsActions.FETCH_SINGLE_REPORTS,
    handleFetchSingleReports,
  );
}

export function* handleFetchTrendingReports() {
  try {
    const response = yield call(ReportService.fetchTrendingReports);

    const {
      data: {
        data: {data},
      },
    } = response;

    // console.log('fetchTrendingReportsSaga2', data);

    yield put(ReportsActions.fetchTrendingReportsSuccess(data));
  } catch (err) {
    yield put(ReportsActions.fetchTrendingReportsFail(err.message));
  }
}

export function* watchFetchTrendingReports() {
  yield takeLatest(
    ReportsActions.FETCH_TRENDING_REPORTS,
    handleFetchTrendingReports,
  );
}

export function* handleFetchGradingDetails() {
  try {
    const response = yield call(ReportService.fetchGradingDetails);

    const {
      data: {data},
    } = response;

    console.log('handleFetchGradingDetails', data);
    yield put(ReportsActions.fetchGradingDetailsSuccess(data));
  } catch (err) {
    yield put(ReportsActions.fetchGradingDetailsFail(err.message));
  }
}

export function* watchFetchGradingDetails() {
  yield takeLatest(
    ReportsActions.FETCH_GRADING_DETAILS,
    handleFetchGradingDetails,
  );
}
