import { RootState } from "MyTypes";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import Logo from "../../assets/images/logo.png";
import { UserV2 } from "../../models/User";
import { selectUser } from "../../store/Auth/selectors";
import PROFILE_ICON from '../../assets/images/myProfileIcon.png';
import MOBILE_MENU_ICON from '../../assets/images/mobileMenu.png';
import CLOSE_BUTTON_ICON from '../../assets/images/closeBtn.png';
import "./CommonHeader.scss";
import { Dialog, Slide } from "@material-ui/core";

const PATHS = [
  { path: '/', key: 'home' },
  { path: '/news', key: 'news' },
  { path: '/products', key: 'products' },
  { path: '/redemption', key: 'redemption' },
  { path: '/settings', key: 'settings' },
];

const CustomTransition = React.forwardRef((props: { children: React.ReactElement }, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

interface Props {
  user?: UserV2;
}

const CommonHeader = ({ user }: Props) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [currentRoute, setCurrentRoute] = useState('/');
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    setCurrentRoute(`/${pathname.split('/')[1]}`);
  }, [pathname]);

  return (
    <header id="common-header">
      <div className="common-header-container justify-align-center">
        <div className="width-control common-header-flex">
          <Link to="/" className="common-header-logo">
            <img src={Logo} alt={t('common:title')} />
          </Link>
          <div className="common-header-top">
            <div className="common-header-items">
              {PATHS.map(({ path, key }) => (
                <Link
                  className={`common-header-item ${path === currentRoute ? 'mod-active' : ''}`}
                  to={path}
                  key={key}
                >
                  {t(`navigation:${key}`)}
                </Link>
              ))}
              {user ? (
                <Link to="/profile" className="common-header-item">
                  <img className="common-header-item-icon" src={PROFILE_ICON} alt="" />
                </Link>
              ) : <Link className="common-header-item" to="/">{t('settings:signupLogin')}</Link>}
            </div>
          </div>
          <button type="button" onClick={() => setShowMobileMenu(true)} className="common-header-mobile-menu-button">
            <img src={MOBILE_MENU_ICON} alt="Menu" />
          </button>
        </div>
      </div>

      <Dialog
        fullScreen
        open={showMobileMenu}
        onClose={() => setShowMobileMenu(false)}
        TransitionComponent={CustomTransition}
      >
        <div className="common-mobile-menu-container">
          <button type="button" onClick={() => setShowMobileMenu(false)} className="common-mobile-menu-close">
            <img src={CLOSE_BUTTON_ICON} alt="Close" />
          </button>
          <div className="common-mobile-menu-list">
            {PATHS.map(({ path, key }) => (
              <Link
                className={`common-mobile-menu-item ${path === currentRoute ? 'mod-active' : ''}`}
                to={path}
                key={key}
                onClick={() => setShowMobileMenu(false)}
              >
                {t(`navigation:${key}`)}
              </Link>
            ))}
            {user ? (
              <Link
                to="/profile"
                className={`common-mobile-menu-item ${currentRoute === '/profile' ? 'mod-active' : ''}`}
                onClick={() => setShowMobileMenu(false)}
              >
                <img className="common-mobile-menu-item-icon" src={PROFILE_ICON} alt="" />
                {t('myProfile:myProfile')}
              </Link>
            ) : (
              <Link
                className="common-mobile-menu-item"
                to="/"
                onClick={() => setShowMobileMenu(false)}
              >
                {t('settings:signupLogin')}
              </Link>
            )}
          </div>
        </div>
      </Dialog>
    </header>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(CommonHeader);
