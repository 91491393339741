import { takeLatest, put, call, select } from "redux-saga/effects";
import * as AuthActions from "./actions";
import { ActionType } from "typesafe-actions";
import UserService from "../../services/UserService";
import { setToken } from "../../services/AxiosInstance";
import { selectUser } from "./selectors";
import i18next from "i18next";
import * as RootNavigation from "../../services/RootNavigation";
import { ResponseGenerator } from "../../models/PreferenceKey";
import moment from "moment";

export function* handleLogin(
  action: ActionType<typeof AuthActions.login>
): Generator<any, any, ResponseGenerator> {
  try {
    const {
      payload: { email, password, fromGuest },
    } = action;

    const response = yield call(UserService.login, {
      email,
      password,
      // platform,
      // appVersion,
    });

    if (response.status >= 200 && response.status < 400) {
      const {
        data: { data },
      } = response;

      // Also set access token on successful login
      setToken(data.token);

      yield put(AuthActions.switchLanguageFromApp(data.user.language));
      i18next.changeLanguage(data.user.language);
      moment.locale(data.user.language === 'zh' ? 'zh-hk' : data.user.language === 'cn' ? 'zh-cn' : 'en-gb')
      yield put(AuthActions.loginSuccess(data.user, data.token));
      if (fromGuest)
        RootNavigation.navigate("Main", {
          screen: "Home",
        });
    } else throw new Error("Error occurred while signing in");
  } catch (err) {
    yield put(AuthActions.loginFail(err.message));
  }
}

export function* watchLogin() {
  yield takeLatest(AuthActions.LOGIN, handleLogin);
}

export function* handleReplaceToken(
  action: ActionType<typeof AuthActions.replaceToken>
): Generator<any, any, any> {
  const {
    payload: { token },
  } = action;

  const user = yield select(selectUser);

  yield put(AuthActions.loginSuccess(user, token));
}

export function* watchReplaceToken() {
  yield takeLatest(AuthActions.REPLACE_TOKEN, handleReplaceToken);
}

export function* handleSetGuest(): Generator<any, any, ResponseGenerator> {
  const response = yield call(UserService.loginGuest);
  if (response.status >= 200 && response.status < 400) {
    const {
      data: { data },
    } = response;
    setToken(data.token);
    // yield put(AuthActions.setGuestSuccess(true));
    yield put(AuthActions.switchLanguageFromApp(data.user.language));
    i18next.changeLanguage(data.user.language);
    yield put(AuthActions.loginSuccess(data.user, data.token));
  } else {
    yield put(
      AuthActions.setGuestFail(
        `Unexpected Error: ERR CODE ${response.status.toString()}`
      )
    );
  }
}

export function* watchSetGuest() {
  yield takeLatest(AuthActions.SET_GUEST, handleSetGuest);
}

export function* handleChangeLanguage(
  action: ActionType<typeof AuthActions.changeLanguage>
): Generator<any, any, ResponseGenerator> {
  try {
    const {
      payload: { language },
    } = action;

    const response = yield call(UserService.changeLanguage, language);

    // if (response.status >= 200 && response.status < 400) {
    const {
      data: { data },
    } = response;

    // console.log('handleChangeLanguage response', data);

    yield put(AuthActions.switchLanguageFromApp(data.language));
    i18next.changeLanguage(data.language);
    yield put(AuthActions.changeLanguageSuccess(data));
    // } else throw new Error('Error occurred while signing in');
  } catch (err) {
    yield put(AuthActions.changeLanguageFail(err.message));
  }
}

export function* watchChangeLanguage() {
  yield takeLatest(AuthActions.CHANGE_LANGUAGE, handleChangeLanguage);
}

export function* handleFetchUser(): Generator<any, any, ResponseGenerator> {
  try {
    const response = yield call(UserService.getUser);

    // if (response.status >= 200 && response.status < 400) {
    const {
      data: { data },
    } = response;

    // console.log('handleFetchUser response', data);

    yield put(AuthActions.fetchUserSuccess(data));
    // } else throw new Error('Error occurred while signing in');
  } catch (err) {
    yield put(AuthActions.fetchUserFail(err.message));
  }
}

export function* watchFetchUser() {
  yield takeLatest(AuthActions.FETCH_USER, handleFetchUser);
}
