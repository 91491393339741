import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ClipLoader } from 'react-spinners';
import InfoModal from '../../../components/InfoModal/InfoModal';
import GradingDetail from '../../../models/GradingDetail';
import ProductMoreContent from '../../../models/ProductMoreContent';
import styles from '../../../styles/modules/HomeTab.module.scss';

interface Props {
	gradingDetails?: GradingDetail[];
	moreContent?: ProductMoreContent[];
}

const HomeMore = ({ gradingDetails, moreContent }: Props): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();
	const history = useHistory();

	const [showModal, setShowModal] = useState(false);

	const onClickMoreItem = (moreItem: ProductMoreContent) => {
		const { key, id, webUrl } = moreItem;
		switch (key) {
			case 'productStore':
				history.push('/products/store');
				break;
			case 'mark':
				setShowModal(true);
				break;
			case 'experience':
				history.push(`/products/experience/${id}`);
				break;
			case 'bioTestingTech':
				if (webUrl[language]) window.open(webUrl[language], '_blank').focus();
				break;
			default:
		}
	};

	return (
		<>
			<InfoModal isOpen={showModal} onClose={() => setShowModal(false)}>
				<div className={styles.gradingDetailsModal}>
					{gradingDetails?.map((grading) => (
						<React.Fragment key={grading.id}>
							<img src={grading.imageUrl[language]} alt="" />
							<h3>{grading.name[language]}</h3>
							<p>{grading.description[language]}</p>
						</React.Fragment>
					))}
				</div>
			</InfoModal>
			<div className={styles.homeTab}>
				<h2 className={styles.title}>{t('navigation:more')}</h2>
				<div className={styles.moreList}>
					{moreContent.map((moreItem) => (
						<button
							type="button"
							className={styles.moreListItem}
							key={moreItem.id}
							onClick={() => onClickMoreItem(moreItem)}
						>
							<div className={styles.moreListItemImg}>
								{moreItem.imageUrl[language]
									? (
										<img src={moreItem.imageUrl[language]} alt={moreItem.name[language]} />
									)
									: (
										<ClipLoader size={24} />
									)
								}
							</div>
							<span>{moreItem.name[language]}</span>
						</button>
					))}
				</div>
			</div>
		</>
	);
}

export default HomeMore;
