import moment from 'moment';
import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import { RoundTab } from '../../../components/CommonTab/CommonTab';
import { RoundTabs } from '../../../components/CommonTabs/CommonTabs';
import Bookmarked from '../../../models/Bookmarked';
import RedemptionCode from '../../../models/RedemptionCode';
import BookmarkService from '../../../services/BookmarkService';
import { ProfileActions } from '../../../store/Profile';
import { selectSavedReports } from '../../../store/Profile/selectors';
import { RedemptionActions } from '../../../store/Redemption';
import { selectPendingRedeemedCodeList } from '../../../store/Redemption/selectors';
import styles from '../../../styles/modules/RedemptionTab.module.scss';
import PendingCodeCard from '../components/PendingCodeCard/PendingCodeCard';
import PendingReportCard from '../components/PendingReportCard/PendingReportCard';

interface Props {
	savedReports?: Bookmarked[];
	fetchAllSavedReports: () => void;
	redeemedCodeList?: RedemptionCode[];
	fetchRedeemedCodeList: () => void;
	setInuseRedemptionCode: (inUseCode: string) => void;
}

const PendingTab = ({
	savedReports, fetchAllSavedReports, redeemedCodeList, fetchRedeemedCodeList, setInuseRedemptionCode,
}: Props): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();
	const history = useHistory();
	const [filter, setFilter] = useState<string>('reports');
	const [targetList, setTargetList] = useState<Bookmarked[] | RedemptionCode[] | null>(null);

	const onDeleteSavedReport = (reportId: string) => {
		BookmarkService.deleteSavedReport(reportId)
			.then(() => fetchAllSavedReports())
			.catch((err) => alert(err.message));
	};

	const onSelectCode = (code: RedemptionCode) => {
		const codeId = code.tokenCode;
		const codeType = !!code.token.content.packageTierKey ? 'package' : code.type;
		const packageTierKey = code.token.content.packageTierKey;
		setInuseRedemptionCode(codeId);
		if (codeType === 'report') {
			history.push('/reports');
			return;
		} else if (codeType === 'ranking') {
			history.push('/?tab=reports');
			return;
		}
		history.push(`/redemption/packages/${packageTierKey}`);
	};

	useEffect(() => {
		fetchAllSavedReports();
		fetchRedeemedCodeList();
	}, [fetchAllSavedReports, fetchRedeemedCodeList]);

	useEffect(() => {
		switch (filter) {
			case 'reports':
				setTargetList(savedReports ?? null);
				break;
			case 'codes':
				setTargetList(redeemedCodeList ?? null);
				break;
			default:
				setTargetList(null);
		}
	}, [filter, redeemedCodeList, savedReports]);

	return (
		<div className={styles.redemptionTab}>
			<RoundTabs value={filter} onChange={(_, i) => setFilter(i)} variant="scrollable">
				<RoundTab value="reports" label={t('navigation:reports')} />
				<RoundTab value="codes" label={t('reports:code')} />
			</RoundTabs>
			<div className={styles.redemptionTabList}>
				{targetList?.map((item: Bookmarked | RedemptionCode) => {
					const checkIsCode = (target: Bookmarked | RedemptionCode): target is RedemptionCode => 'token' in target;
					if (filter === 'reports' && !checkIsCode(item) && !!item.report && !item.report.isRedeemed) {
						return (
							<PendingReportCard
								key={item.id}
								img={item.report.imageUrl[language]}
								title={item.report.title[language]}
								subtitle={item.report.summary?.[language]}
								unread
								onRemove={() => onDeleteSavedReport(item.reportId)}
								to={`/reports/${item.reportId}`}
							/>
						);
					} else if (filter === 'codes' && checkIsCode(item)) {
						return (
							<PendingCodeCard
								key={item.id}
								title={item.token.title?.[language]}
								expiryDate={`${t('redemption:expiryDate')} ${moment(item.token?.endDate).format('DD/MM/YYYY')}`}
								usedChance={item.redeemedProductIds?.length ?? 0}
								totalChance={item.token?.content?.freeRedemptionChance ?? 1}
								onChoose={() => onSelectCode(item)}
								canChoose={(item.redeemedProductIds?.length ?? 0) < (item.token?.content?.freeRedemptionChance ?? 1) || !!item.token.content.packageTierKey}
							/>
						);
					}
					return <React.Fragment key={item.id} />
				})}
			</div>
			{targetList === null ? (
				<div className={styles.pendingTabLoading}>
					<ClipLoader />
				</div>
			) : targetList.length === 0 ? (
				<div className={styles.pendingTabLoading}>
					{t(filter === 'reports' ? 'redemption:noSavedReport' : 'redemption:noPendingCode')}
				</div>
			) : null}
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	savedReports: selectSavedReports(state),
	redeemedCodeList: selectPendingRedeemedCodeList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchAllSavedReports: () => dispatch(ProfileActions.fetchAllBookmarks()),
	fetchRedeemedCodeList: () => dispatch(RedemptionActions.fetchRedeemedCodeList()),
	setInuseRedemptionCode: (inUseCode: string) =>
	  dispatch(RedemptionActions.setInuseRedemptionCode(inUseCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingTab);
