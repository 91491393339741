import {watchFetchNews, watchFetchNewsById} from './saga';
import {all} from 'redux-saga/effects';
import {reducer, State as NewsReducerState} from './reducer';
import * as NewsActions from './actions';

function* NewsSaga() {
  yield all([watchFetchNews(), watchFetchNewsById()]);
}

export {NewsSaga, NewsActions};
export type {NewsReducerState};
export default reducer;
