import React from 'react';
import CommonHeader from '../../components/CommonHeader/CommonHeader';
import MainLayout from '../../components/MainLayout/MainLayout';

const NotFound = (): JSX.Element => (
	<MainLayout title="Page Not Found">
		<CommonHeader />
		<div className="page-main justify-align-center">
			<div className="width-control">
				Page Not Found
			</div>
		</div>
	</MainLayout>
);

export default NotFound;
