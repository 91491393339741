import {takeLatest, put, call, select} from 'redux-saga/effects';
import * as NewsActions from './actions';
import NewsService from '../../services/NewsService';
import {ActionType} from 'typesafe-actions';
import {selectNewsItems} from './selectors';
import {News} from '../../models/News';

export function* handleFetchNews() {
  try {
    const response = yield call(NewsService.fetchNews);

    const {
      data: {
        data: {data},
      },
    } = response;

    // console.log('fetchNewsSaga', data);

    yield put(NewsActions.fetchNewsSuccess(data));
  } catch (err) {
    yield put(NewsActions.fetchNewsFail(err.message));
  }
}

export function* watchFetchNews() {
  yield takeLatest(NewsActions.FETCH_NEWS, handleFetchNews);
}

export function* handleFetchOneNews(
  action: ActionType<typeof NewsActions.fetchNewsById>,
) {
  try {
    const {
      payload: {id},
    } = action;

    const existingNews = yield select(selectNewsItems);

    const response = yield call(NewsService.fetchOneNews, id);

    const {data} = response;

    const filteredNews = existingNews.filter((x: News) => x.id !== data.id);

    yield put(NewsActions.fetchNewsByIdSuccess([...filteredNews, data]));
  } catch (err) {
    yield put(NewsActions.fetchNewsByIdFail(err.message));
  }
}

export function* watchFetchNewsById() {
  yield takeLatest(NewsActions.FETCH_NEWS_BY_ID, handleFetchOneNews);
}
