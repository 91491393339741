import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import Product from '../../../../models/Product';
import ProductsService from '../../../../services/ProductsService';
import { LoadingOverlayActions } from '../../../../store/LoadingOverlay';
import styles from '../../../../styles/modules/ProductStore.module.scss';

interface Props {
	category: string;
	searchText: string;
	setShowLoadingOverlay: (show: boolean) => void;
}

const ProductsByCategory = ({ category, searchText, setShowLoadingOverlay }: Props): JSX.Element => {
	const { i18n: { language } } = useTranslation();
	const [productsListByCat, setProductsListByCat] = useState<Product[] | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [fetchError, setFetchError] = useState<string | null>(null);

	/* Infinite scroll */
	const onFetchProducts = useCallback(() => {
		ProductsService.fetchProductStore(category, searchText, currentPage)
			.then(({ data: { data: { data, totalPage: resTotalPage } } }) => {
				setProductsListByCat([...(productsListByCat ?? []), ...data]);
				setCurrentPage(currentPage + 1);
				setTotalPage(resTotalPage);
			})
			.catch((err) => setFetchError(err.message))
	}, [category, searchText, currentPage, productsListByCat]);

	useEffect(() => {
		setCurrentPage(1);
		setTotalPage(1);
		setFetchError(null);
		setProductsListByCat(null);
	}, [category, searchText]);

	useEffect(() => {
		if (!productsListByCat && !fetchError) {
			onFetchProducts();
		}
	}, [fetchError, productsListByCat, onFetchProducts]);

	/* Redirect user to product page */
	const onClickProduct = (productId: string) => {
		setShowLoadingOverlay(true);
		ProductsService.fetchProductUrl(productId)
			.then(({ data: { data: url } }) => {
				const link = document.createElement('a');
				link.href = url;
				link.target = '_blank';
				link.rel = 'noreferrer';
				link.click();
			})
			.catch((err) => setFetchError(err.message))
			.finally(() => setShowLoadingOverlay(false));
	};

	return (
		<div className={styles.storeContents} id="products-store-content">
			<InfiniteScroll
				scrollableTarget="products-store-content"
				scrollThreshold="400px"
				className={styles.storeList}
				loader={<div className={styles.storeListCenter}><ClipLoader /></div>}
				hasMore={currentPage <= totalPage}
				next={onFetchProducts}
				dataLength={productsListByCat?.length ?? 0}
			>
				{fetchError
					? <div className={styles.storeListCenter}>{fetchError}</div>
					: productsListByCat?.length ? productsListByCat?.map((product) => (
						<button key={product.id} className={styles.storeItem} onClick={() => onClickProduct(product.productId)}>
							<img src={product.img[language]} alt={product.prdName[language]} />
							<span>{product.prdName[language]}</span>
						</button>
					)) : !!productsListByCat ? (
						<div className={styles.storeListCenter}>
							No available products
						</div>
					) : null
				}
			</InfiniteScroll>
		</div>
	);
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setShowLoadingOverlay: (show: boolean) => dispatch(LoadingOverlayActions.setShowLoadingOverlay(show)),
});

export default connect(null, mapDispatchToProps)(ProductsByCategory);
