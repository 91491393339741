import React from 'react';
import moment from 'moment';
import { Mail } from '../../../../models/Mail';
import PROFILE_ICON from '../../../../assets/images/myProfileIcon.png';
import styles from '../../../../styles/modules/ProfileTab.module.scss';

interface Props {
	mail: Mail;
}

const formatTime = (scheduleSend: string): string => (
	moment(scheduleSend).isBefore(moment().startOf('day'))
		? moment(scheduleSend).format('DD/MM')
		: moment(scheduleSend).format('HH:mm')
);

const MailCard = ({ mail }: Props): JSX.Element => (
	<div className={`${styles.mailCard} ${mail.isUserRead ? '' : styles.mailCardUnread}`}>
		<img src={PROFILE_ICON} alt="" />
		<span className={styles.mailBody}>{mail.message}</span>
		<span className={styles.mailTime}>{formatTime(mail.scheduleSend)}</span>
	</div>
);

export default MailCard;
