import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CommonHeader from '../../components/CommonHeader/CommonHeader';
import InfoModal from '../../components/InfoModal/InfoModal';
import MainLayout from '../../components/MainLayout/MainLayout';
import ProductType from '../../models/ProductType';
import { ProductsActions } from '../../store/Products';
import { selectProductsType } from '../../store/Products/selectors';
import ProductTypeCard from './components/ProductTypeCard/ProductTypeCard';
import './Products.scss';

interface Props {
	productTypes: ProductType[];
	fetchProductTypes: () => void;
}

const TYPE_PATH: Record<string, string> = {
	single: '/?tab=reports',
	trending: '/reports',
	package: '/products/packages',
	membership: '/products/membership',
};

const Products = ({ productTypes, fetchProductTypes }: Props): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();
	const [detailItem, setDetailItem] = useState<ProductType | null>(null);

	useEffect(() => {
		fetchProductTypes();
	}, [fetchProductTypes]);

	return (
		<>
			<InfoModal isOpen={!!detailItem} onClose={() => setDetailItem(null)}>
				<div className="product-type-modal">
					<img src={detailItem?.imageUrl?.[language]} alt="" />
					<span className="product-type-modal-title">{detailItem?.name?.[language]}</span>
					<p className="product-type-modal-desc">{detailItem?.description?.[language]}</p>
				</div>
			</InfoModal>
			<MainLayout title={t('navigation:products')}>
				<CommonHeader />
				<div className="page-main justify-align-center">
					<div className="width-control">
						<div className="product-types-list">
							{productTypes?.map((productType) => (
								<ProductTypeCard
									key={productType.id}
									imgUrl={productType.imageUrl[language]}
									title={productType.name[language]}
									subtitle={productType.description[language]}
									to={TYPE_PATH[productType.key] ?? ''}
									onDetail={() => setDetailItem(productType)}
								/>
							))}
						</div>
					</div>
				</div>
			</MainLayout>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	productTypes: selectProductsType(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchProductTypes: () => dispatch(ProductsActions.fetchProductTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
