import React from 'react';
import { Link } from 'react-router-dom';
import HomeTabStyles from '../../styles/modules/HomeTab.module.scss';

const WrappedLink = ({ to, children }: { to: string, children: React.ReactNode }) => (
	to?.startsWith('/') ? (
		<Link to={to} className={HomeTabStyles.reportItem}>
			{children}
		</Link>
	) : (
		<a
			href={`${to.startsWith('http') ? '' : 'https://'}${to}`}
			className={HomeTabStyles.reportItem}
			target="_blank"
			rel="noreferrer"
		>
			{children}
		</a>
	)
);

interface Props {
	to?: string;
	img?: string;
	name?: string;
	createdAt?: string;
}

const HomeCard = ({ to, img, name, createdAt }: Props): JSX.Element => (
	<WrappedLink to={to}>
		<img src={img} alt={name} />
		<span>{name}</span>
		<p>{createdAt}</p>
		<div><div /></div>
	</WrappedLink>
);

export default HomeCard;
