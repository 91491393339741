import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import CommonHeader from '../../components/CommonHeader/CommonHeader';
import CommonTab from '../../components/CommonTab/CommonTab';
import CommonTabs from '../../components/CommonTabs/CommonTabs';
import MainLayout from '../../components/MainLayout/MainLayout';
import { useIsGuest, useQuery } from '../../helpers/CustomHook';
import PendingTab from './RedemptionTabs/PendingTab';
import RedemptionMainTab from './RedemptionTabs/RedemptionMainTab';
import ReportsTab from './RedemptionTabs/ReportsTab';

const Redemption = (): JSX.Element => {
	const { t } = useTranslation();
	const history = useHistory();
	const isGuest = useIsGuest();
	const query = useQuery();

	const [currentTab, setCurrentTab] = useState('redempt');

	useEffect(() => {
		if (isGuest) {
			history.replace('/login');
		}
	}, [history, isGuest]);

	useEffect(() => {
		const urlTab = query.get('tab');
		if (urlTab) {
			setCurrentTab(urlTab);
		}
	}, [query]);

	return !isGuest ? (
		<MainLayout title={t('navigation:redemption')}>
			<CommonHeader />
			<div className="page-main tabs-page-align">
				<div className="width-control">
					<CommonTabs value={currentTab} onChange={(_, i) => setCurrentTab(i)} variant="scrollable">
						<CommonTab value="redempt" label={t('navigation:redemption')} />
						<CommonTab value="pending" label={t('navigation:pending')} />
						<CommonTab value="reports" label={t('navigation:myReports')} />
					</CommonTabs>
					{(() => {
						switch (currentTab) {
							case 'redempt':
								return <RedemptionMainTab onSeeAllReports={() => setCurrentTab('reports')} />;
							case 'pending':
								return <PendingTab />;
							case 'reports':
								return <ReportsTab />
							default:
								return null;
						}
					})()}
				</div>
			</div>
		</MainLayout>
	) : null;
};

export default Redemption;
