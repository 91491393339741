import moment from 'moment';
import { RootState } from 'MyTypes';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import CommonHeader from '../../components/CommonHeader/CommonHeader';
import CommonTab from '../../components/CommonTab/CommonTab';
import CommonTabs from '../../components/CommonTabs/CommonTabs';
import LongItemCard from '../../components/LongItemCard/LongItemCard';
import MainLayout from '../../components/MainLayout/MainLayout';
import ProductCategory from '../../models/ProductCategory';
import ProductType from '../../models/ProductType';
import { Report } from '../../models/Report';
import ReportService from '../../services/ReportService';
import { selectProductsType } from '../../store/Products/selectors';
import { ReportsActions } from '../../store/Reports';
import './Reports.scss';

interface Props {
	productTypes: ProductType[];
	fetchProductTypes: () => void;
}

const Reports = ({ productTypes, fetchProductTypes }: Props) => {
	const { t, i18n: { language } } = useTranslation();
	const [currentTab, setCurrentTab] = useState<string>('all');

	const [allReportCategories, setAllReportCategories] = useState<ProductCategory[] | null>(null);
	const [fetchError, setFetchError] = useState<string | null>(null);

	const [reports, setReports] = useState<Report[] | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);

	/* Get available categories */
	useEffect(() => {
		if (!allReportCategories && !fetchError) {
			ReportService.fetchReportCategories()
				.then(({ data: { data } }) => setAllReportCategories(data))
				.catch((err) => setFetchError(err.message));
		}
	}, [allReportCategories, fetchError]);

	useEffect(() => {
		if (!productTypes) {
			fetchProductTypes();
		}
	}, [productTypes, fetchProductTypes]);

	/* Infinite Scroll */
	const onFetchReports = useCallback(() => {
		ReportService.fetchTrendingReports(currentPage, currentTab === 'all' ? undefined : currentTab)
			.then(({ data: { data: { data, totalPage: resTotalPage } } }) => {
				setReports([...(reports ?? []), ...data]);
				setCurrentPage(currentPage + 1);
				setTotalPage(resTotalPage ?? 0);
			})
			.catch((err) => setFetchError(err.message));
	}, [currentPage, currentTab, reports]);

	// Reset when switching category
	useEffect(() => {
		setCurrentPage(1);
		setTotalPage(1);
		setReports(null);
		setFetchError(null);
	}, [currentTab]);

	// Initial fetch
	useEffect(() => {
		if (!reports && !fetchError) {
			onFetchReports();
		}
	}, [fetchError, onFetchReports, reports]);

	return (
		<MainLayout title={productTypes?.find((t) => t.key === 'trending')?.name?.[language] ?? t('navigation:reports')}>
			<CommonHeader />
			<div className="page-main tabs-page-align">
				<div className="width-control">
					<CommonTabs value={currentTab} onChange={(_, i) => setCurrentTab(i)} variant="scrollable">
						<CommonTab value="all" label={t('navigation:allReports')} />
						{allReportCategories?.map((cat) => (
							<CommonTab key={cat.id} value={cat.key} label={cat.name[language]} />
						))}
					</CommonTabs>
					<div className="reports-page-list">
						<h2>{t('navigation:reports')}</h2>
						<InfiniteScroll
							loader={<div className="infinite-scroll-center"><ClipLoader /></div>}
							hasMore={currentPage <= totalPage}
							next={onFetchReports}
							dataLength={reports?.length ?? 0}
						>
							{reports?.map((r) => (
								<LongItemCard
									key={r.id}
									img={r.imageUrl[language]}
									title={r.title[language]}
									subTitle={moment(r.createdAt).format('YYYY-MM-DD')}
									roundImg
									to={`/reports/${r.id}`}
								/>
							))}
							{!!reports && reports.length === 0 && (
								<div className="infinite-scroll-center">
									<span>No reports</span>
								</div>
							)}
						</InfiniteScroll>
					</div>
				</div>
			</div>
		</MainLayout>
	);
};

const mapStateToProps = (state: RootState) => ({
	productTypes: selectProductsType(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchProductType: () => dispatch(ReportsActions.fetchAllReports()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);

