import React from 'react';
import { useTranslation } from 'react-i18next';
import Membership from '../../../../../models/Membership';
import './MembershipCard.scss';

interface Props {
	membership?: Membership;
	backgroundColor?: string;
	onDetails?: (membership?: Membership) => void;
}

const MembershipCard = ({ membership, backgroundColor, onDetails }: Props): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();

	return (
		<div className="membership-card">
			<svg width={0} height={0}>
				<defs>
					<clipPath id={`membership-clip-${membership?.id ?? 'path'}`} clipPathUnits="objectBoundingBox">
						<path
							d="
								M 0 1
								L 0 0.7
								q 0.25,-0.2 0.5,-0.15
								T 1 0.4
								L 1 1
								Z
							"
						/>
					</clipPath>
				</defs>
			</svg>
			<div className="membership-card-background" style={{ backgroundColor, clipPath: `url(#membership-clip-${membership?.id ?? 'path'})` }} />
			<span className="membership-card-title" style={{ color: backgroundColor?.substr(0, 7) }}>
				{membership.title[language]}
			</span>
			<span className="membership-card-desc">{membership.description[language]}</span>
			<button
				type="button"
				className="membership-card-details"
				onClick={onDetails ? () => onDetails(membership) : undefined}
			>
				{t('common:details')}
			</button>
		</div>
	)
}

export default MembershipCard;
