import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormErrorContainer from '../../../../components/FormErrorContainer/FormErrorContainer';
import InfoModal from '../../../../components/InfoModal/InfoModal';
import RoundButton from '../../../../components/RoundButton/RoundButton';
import { emailRegex } from '../../../../helpers/helpers';
import UserService from '../../../../services/UserService';
import styles from '../../../../styles/modules/FormStyles.module.scss';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: () => void;

	email: string;
	setEmail: (email: string) => void;
}

const ForgetPasswordModal = ({ isOpen, onClose, onSuccess, email, setEmail }: Props): JSX.Element => {
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const onSubmit = () => {
		if (!emailRegex.test(email)) {
			setError(t('systemMsg:invalidEmailAddress'));
			return;
		}
		setLoading(true);
		UserService.forgetPassword(email)
			.then(onSuccess)
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false))
	};

	useEffect(() => setError(null), [email]);

	return (
		<InfoModal isOpen={isOpen} onClose={onClose}>
			<div className="login-reset-password-modal">
				<h3>{t('login:forgotPw').toUpperCase()}</h3>
				<p>{t('login:forgotPwDesc')}</p>
				<div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputEmail}`}>
					<input
						type="text"
						placeholder={t('common:emailAddr')}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<RoundButton
					text={t('common:submit').toUpperCase()}
					onClick={onSubmit}
					loading={loading}
				/>
				{error && <FormErrorContainer msg={error} />}
			</div>
		</InfoModal>
	);
};

export default ForgetPasswordModal;
