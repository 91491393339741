import { createSelector } from "reselect";
import { ReduxReducerState } from "../../store/RootReducer";

export const selectAuth = (state: ReduxReducerState) => state.auth;

export const selectUser = createSelector(selectAuth, (auth) => auth.user);

export const selectAccessToken = createSelector(
  selectAuth,
  (auth) => auth.accessToken
);

export const selectLang = createSelector(
  selectAuth,
  (auth) => auth.selectedLang
);

export const selectRedeemQuota = createSelector(
  selectAuth,
  (auth) => auth.user?.redeemChance
);

export const selectReportQuota = createSelector(
  selectAuth,
  (auth) => (auth.user?.reportRedeemChance ?? 0) + (auth.user?.redeemChance ?? 0)
);

export const selectRankingQuota = createSelector(
  selectAuth,
  (auth) => (auth.user?.rankingRedeemChance ?? 0) + (auth.user?.redeemChance ?? 0)
);

export const selectIsGuest = createSelector(
  selectAuth,
  (auth) => auth.user?.type === "Guest"
);

export const selectAuthError = createSelector(selectAuth, (auth) => auth.error);
