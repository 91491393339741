import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Report, ReportCategory } from '../../../models/Report';
import ReportService from '../../../services/ReportService';
import styles from '../../../styles/modules/HomeTab.module.scss';
import { ClipLoader } from 'react-spinners';
import CommonTabs from '../../../components/CommonTabs/CommonTabs';
import CommonTab from '../../../components/CommonTab/CommonTab';
import moment from 'moment';
import HomeCard from '../../../components/HomeCard/HomeCard';

const HomeReports = (): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();
	const [reports, setReports] = useState<Report[] | null>(null);
	const [categories, setCategories] = useState<ReportCategory[] | null>(null);
	const [currentCategory, setCurrentCategory] = useState<string>('all');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [fetchError, setFetchError] = useState<string | null>(null);

	useEffect(() => {
		ReportService.fetchReportCategories()
			.then(({ data: { data } }) => setCategories(data))
			.catch((err) => console.log(err.message));
	}, []);

	/* Infinite Scroll */
	const onFetchReports = useCallback(() => {
		ReportService.fetchAllReports(currentPage, 20, { reportId: null, categoryKey: currentCategory === 'all' ? undefined : currentCategory })
			.then(({ data: { data: { data, totalPage: resTotalPage } }}) => {
				setReports([...(reports ?? []), ...data]);
				setCurrentPage(currentPage + 1);
				setTotalPage(resTotalPage);
			})
			.catch((err) => setFetchError(err.message));
	}, [currentCategory, currentPage, reports]);

	// Initial fetch
	useEffect(() => {
		if (!reports && !fetchError) {
			onFetchReports();
		}
	}, [onFetchReports, reports, fetchError]);

	useEffect(() => {
		setTotalPage(1);
		setCurrentPage(1);
		setFetchError(null);
		setReports(null);
	}, [currentCategory]);

	return (
		<div className={styles.homeTab}>
			<CommonTabs value={currentCategory} onChange={(_, i) => setCurrentCategory(i)} variant="scrollable">
				<CommonTab value="all" label={t('navigation:allLists')} />
				{categories?.map((cat) => (
					<CommonTab key={cat.id} value={cat.key} label={cat.name[language]} />
				))}
			</CommonTabs>
			<h2>{t('navigation:lists')}</h2>
			<InfiniteScroll
				className={styles.reportList}
				loader={<div className={styles.reportLoading}><ClipLoader /></div>}
				hasMore={currentPage <= totalPage}
				next={onFetchReports}
				dataLength={reports?.length ?? 0}
			>
				{reports?.map((report) => (
					<HomeCard
						key={report.id}
						to={`/reports/${report.id}`}
						img={report.imageUrl[language]}
						name={report.title[language]}
						createdAt={moment(report.createdAt).format('YYYY-MM-DD')}
					/>
				))}
			</InfiniteScroll>
		</div>
	);
};

export default HomeReports;
