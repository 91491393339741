import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormErrorContainer from '../../../../components/FormErrorContainer/FormErrorContainer';
import InfoModal from '../../../../components/InfoModal/InfoModal';
import RoundButton from '../../../../components/RoundButton/RoundButton';
import { emailRegex } from '../../../../helpers/helpers';
import { UserV2 } from '../../../../models/User';
import UserService from '../../../../services/UserService';
import styles from '../../../../styles/modules/FormStyles.module.scss';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	user?: UserV2;
	onSuccess: () => void;
}

interface EditProfileInput {
	email?: string;
	areaCode?: string;
	phone?: string;
	firstName?: string;
	lastName?: string;
}

const EditProfileModal = ({ isOpen, onClose, user, onSuccess }: Props): JSX.Element => {
	const { t } = useTranslation();
	const {
		register,
		setValue,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm<EditProfileInput>({ mode: 'onChange', defaultValues: { areaCode: '-' } });

	const [submitting, setSubmitting] = useState(false);
	const [submitError, setSubmitError] = useState<string | null>(null);
	const onSubmit = (data: EditProfileInput) => {
		let { email, areaCode, phone, firstName, lastName } = data;
		if (!email || !areaCode || !phone || !firstName || !lastName) return;
		const submitBody = { email, firstName, lastName, phone: `${areaCode} ${phone}`};
		setSubmitting(true);
		UserService.updateProfile(submitBody)
			.then(() => onSuccess())
			.catch((err) => setSubmitError(err.message))
			.finally(() => setSubmitting(false));
	}

	useEffect(() => {
		if (user) {
			setValue('email', user.email);
			const [areaCode, phone] = user.phone.split(' ');
			if ((areaCode ?? '') !== '') {
				setValue('areaCode', areaCode);
				setValue('phone', phone);
			}
			setValue('firstName', user.firstName);
			setValue('lastName', user.lastName);
		}
	}, [user, setValue]);

	return (
		<InfoModal isOpen={isOpen} onClose={onClose}>
			<div className={`${styles.formContainer} edit-profile-modal`}>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.generalInputContainer}>
						<div className={styles.signupInputItem}>
							<div className={`${styles.generalInput} ${styles.loginInput} ${styles.editProfileInputEmail}`}>
								<input
									{...register(
										'email',
										{
											required: { value: true, message: t('systemMsg:emptyEmail') },
											pattern: { value: emailRegex, message: t('systemMsg:invalidEmailAddress') },
										},
									)}
									type="text"
									placeholder={t('register:fields:email')}
								/>
							</div>
							<FormErrorContainer errors={errors} name="email" />
						</div>
						<div className={styles.signupInputItem}>
							<div className={`${styles.generalInput} ${styles.loginInput} ${styles.editProfileInputPhone}`}>
								<select {...register('areaCode', { validate: (v: string) => v !== '-' || `${t('register:errors:selectPhoneZip')}` })}>
									<option value="-" disabled>{t('register:fields:areaCode')}</option>
									<option value="852">852</option>
									<option value="853">853</option>
									<option value="86">86</option>
								</select>
							</div>
							<div className={`${styles.generalInput} ${styles.loginInput} ${styles.editProfileInputPhone}`}>
								<input
									{...register(
									'phone',
									{
										required: { value: true, message: t('register:errors:enterPhoneNumber') },
										validate: (v: string) => watch('areaCode') === '-'
										? `${t('register:errors:selectPhoneZip')}`
										: watch('areaCode') === '86'
											? v.length === 11 || `${t('register:errors:cnPhoneNumberPattern')}`
											: v.length === 8 || `${t('register:errors:mc&hkPhoneNumberPattern')}`,
									},
									)}
									type="text"
									placeholder={t('register:fields:phoneNumber')}
									maxLength={13}
								/>
							</div>
							<FormErrorContainer msg={errors.areaCode?.message ?? errors.phone?.message} />
						</div>
						<div className={styles.signupInputItem}>
							<div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputEmail}`}>
							<input
								{...register(
								'firstName',
								{
									required: { value: true, message: t('register:errors:firstNameRequired') },
									validate: (v: string) => v.length <= 32 || `${t('register:errors:nameMaxLimit')}`,
								},
								)}
								type="text"
								placeholder={t('register:fields:firstName')}
								maxLength={32}
							/>
							</div>
							<FormErrorContainer errors={errors} name="firstName" />
						</div>
						<div className={styles.signupInputItem}>
							<div className={`${styles.generalInput} ${styles.loginInput} ${styles.loginInputEmail}`}>
							<input
								{...register(
								'lastName',
								{
									required: { value: true, message: t('register:errors:lastNameRequired') },
									validate: (v: string) => v.length <= 32 || `${t('register:errors:nameMaxLimit')}`,
								},
								)}
								type="text"
								placeholder={t('register:fields:lastName')}
								maxLength={32}
							/>
							</div>
							<FormErrorContainer errors={errors} name="lastName" />
						</div>
					</div>
					<RoundButton text={t('common:submit')} type="submit" loading={submitting} />
					<FormErrorContainer msg={submitError} />
				</form>
			</div>
		</InfoModal>
	);
};

export default EditProfileModal;
