import {all} from 'redux-saga/effects';
import {reducer, State as ProductsReducerState} from './reducer';
import {
  watchFetchMembership,
  watchFetchMoreContent,
  watchFetchPackageAll,
  watchFetchProductsType,
} from './saga';
import * as ProductsActions from './actions';

function* ProductsSaga() {
  yield all([
    watchFetchProductsType(),
    watchFetchMembership(),
    watchFetchPackageAll(),
    watchFetchMoreContent(),
  ]);
}

export {ProductsSaga, ProductsActions};
export type {ProductsReducerState};
export default reducer;
