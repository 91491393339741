import { action } from "typesafe-actions";
import { UserV2 } from "../../models/User";

export const LOGIN = "auth/LOGIN";
export const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
export const LOGIN_FAIL = "auth/LOGIN_FAIL";

export const SET_GUEST = "auth/SET_GUEST";
export const SET_GUEST_SUCCESS = "auth/SET_GUEST_SUCCESS";
export const SET_GUEST_FAIL = "auth/SET_GUEST_FAIL";

export const CHANGE_LANGUAGE = "auth/CHANGE_LANGUAGE";
export const CHANGE_LANGUAGE_SUCCESS = "auth/CHANGE_LANGUAGE_SUCCESS";
export const CHANGE_LANGUAGE_FAIL = "auth/CHANGE_LANGUAGE_FAIL";

export const FETCH_USER = "auth/FETCH_USER";
export const FETCH_USER_SUCCESS = "auth/FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "auth/FETCH_USER_FAIL";

export const SWITCH_LANGUAGE_FROM_APP = "auth/SWITCH_LANGUAGE_FROM_APP";

export const REPLACE_TOKEN = "auth/REPLACE_TOKEN";

export const LOGOUT = "auth/LOGOUT";

export const login = ({
  email,
  password,
  fromGuest,
}: {
  email: string;
  password: string;
  fromGuest?: boolean;
}) => action(LOGIN, { email, password, fromGuest });
export const loginSuccess = (user: UserV2, accessToken: string) =>
  action(LOGIN_SUCCESS, { user, accessToken });
export const loginFail = (error: string) => action(LOGIN_FAIL, { error });

export const setGuest = () => action(SET_GUEST);
export const setGuestSuccess = (isGuest: boolean) =>
  action(SET_GUEST_SUCCESS, { isGuest });
export const setGuestFail = (error: string) =>
  action(SET_GUEST_FAIL, { error });

export const replaceToken = (token: string) => action(REPLACE_TOKEN, { token });

export const changeLanguage = (language: string) =>
  action(CHANGE_LANGUAGE, { language });
export const changeLanguageSuccess = (user: UserV2) =>
  action(CHANGE_LANGUAGE_SUCCESS, { user });
export const changeLanguageFail = (error: string) =>
  action(CHANGE_LANGUAGE_FAIL, { error });

export const fetchUser = () => action(FETCH_USER);
export const fetchUserSuccess = (user: UserV2) =>
  action(FETCH_USER_SUCCESS, { user });
export const fetchUserFail = (error: string) =>
  action(FETCH_USER_FAIL, { error });

export const switchLanguageFromApp = (language: string) =>
  action(SWITCH_LANGUAGE_FROM_APP, { language });

export const logout = () => action(LOGOUT);
