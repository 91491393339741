import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store/RootStore";
import MainRouter from "./router";
import "./locales/i18n";

function App() {
  return (
    <Provider store={store}>
      <MainRouter />
    </Provider>
  );
}

export default App;
