import { ActionType } from "typesafe-actions";
import * as ErrorModalActions from "./action";

export interface State {
  msg?: string;
  onClose?: () => void;
}

const initialState: State = {};

type ErrorModalActionsType = ActionType<typeof ErrorModalActions>;

export function reducer(
  state: State = initialState,
  action: ErrorModalActionsType
): State {
  switch (action.type) {
    case ErrorModalActions.SET_ERROR_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
