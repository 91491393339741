import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import CommonHeader from '../../components/CommonHeader/CommonHeader';
import MainLayout from '../../components/MainLayout/MainLayout';
import { selectCopyRight, selectDisclaimer, selectPrivacyPolicy } from '../../store/Profile/selectors';

interface Props {
	privacyPolicy: {[lang: string]: string};
	copyright: {[lang: string]: string};
	personalInfo: {[lang: string]: string};
}

const Policies = ({ privacyPolicy, copyright, personalInfo }: Props): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();
	const params = useParams<{ policy: string }>();

	const [titleKey, setTitleKey] = useState('');
	const [content, setContent] = useState('');

	useEffect(() => {
		const { policy } = params;
		switch (policy) {
			case 'privacy':
				setTitleKey('privacyPolicy');
				setContent(privacyPolicy[language]);
				break;
			case 'copyright':
				setTitleKey('copyrightAndDisclaimer');
				setContent(copyright[language]);
				break;
			case 'personalInfo':
				setTitleKey('personalDataCollection');
				setContent(personalInfo[language]);
				break;
			default:
				break;
		}
	}, [copyright, language, params, personalInfo, privacyPolicy]);

	return (
		<MainLayout title={titleKey !== '' ? t(`settings:${titleKey}`) : undefined}>
			<CommonHeader />
			<div className="page-main justify-align-center">
				<div className="width-control">
					<div dangerouslySetInnerHTML={{ __html: content }} />
				</div>
			</div>
		</MainLayout>
	);
};

const mapStateToProps = (state: RootState) => ({
	privacyPolicy: selectPrivacyPolicy(state),
	copyright: selectCopyRight(state),
	personalInfo: selectDisclaimer(state),
});

export default connect(mapStateToProps)(Policies);
