import axios from './AxiosInstance';

const fetchNews = (page = 1, pageSize = 20) => axios.get(`news`, { params: { page, pageSize }});

const fetchOneNews = (id: string) => axios.get(`news/${id}`);

const fetchHotNews = (page = 1, pageSize = 20) => axios.get('news/hot', { params: { page, pageSize } });

const createBookmark = (id: string) =>
  axios.post(`user/bookmark`, {newsId: id});

const deleteBookmark = (id: string) =>
  axios.delete(`user/bookmark?newsId=${id}`);

const fetchBookmarkList = (page = 1, pageSize = 20) => axios.get(`user/bookmark`, { params: { page, pageSize } });

const NewsService = {
  fetchNews,
  fetchOneNews,
  fetchHotNews,
  createBookmark,
  deleteBookmark,
  fetchBookmarkList,
};

export default NewsService;
