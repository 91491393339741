import { RootState } from 'MyTypes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CommonHeader from '../../../components/CommonHeader/CommonHeader';
import MainLayout from '../../../components/MainLayout/MainLayout';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { selectProductStoreType } from '../../../store/Products/selectors';
import styles from '../../../styles/modules/ProductStore.module.scss';
import ProductsByCategory from '../components/ProductsByCategory/ProductsByCategory';

interface Props {
	productStoreType: string[];
}

const Store = ({ productStoreType }: Props): JSX.Element => {
	const { t } = useTranslation();
	const [search, setSearch] = useState('');
	const [category, setCategory] = useState(productStoreType[0]);

	return (
		<MainLayout title={t('navigation:productStore')}>
			<CommonHeader />
			<div className="page-main justify-align-center">
				<div className="width-control" id="product-store">
					<div className={styles.header}>
						<h2 className={styles.title}>{t('navigation:productStore')}</h2>
						<SearchBar value={search} onChange={setSearch} placeholder={t('common:search')} />
					</div>
					<div className={styles.storeContainer}>
						<div className={styles.storeSidebar}>
							{productStoreType.map((storeType) => (
								<button
									key={storeType}
									className={`${styles.categoryButton} ${storeType === category ? styles.activeCategory : ''}`}
									type="button"
									onClick={() => setCategory(storeType)}
								>
									{t(`navigation:${storeType}`)}
								</button>
							))}
						</div>
						<ProductsByCategory category={category} searchText={search} />
					</div>
				</div>
			</div>
		</MainLayout>
	);
};

const mapStateToProps = (state: RootState) => ({
	productStoreType: selectProductStoreType(state),
});

export default connect(mapStateToProps)(Store);
