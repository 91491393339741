import axios from "axios";
import i18next from "i18next";
// import {Alert} from 'react-native';
import Config from "../configs/Config";
// import * as RootNavigation from '../services/RootNavigation';

const instance = axios.create({
  baseURL: Config.API_URL,
  timeout: 5000,
  headers: { "Content-Type": "application/json" },
});

export enum AppErrorCode {
  UNKNOWN_ERROR = 10000,
  INVALID_PARAMS = 10001,
  LOGIN_FAIL = 10010,
  FORBIDDEN = 10002,
  MAIL_ERROR = 10003,
  TOKEN_INVALID = 10004,
  PASSWORD_USED_RECENTLY = 10005,
  UNAUTHORIZED = 10006,
  OBJECT_EXISTED = 10007,
  REGISTERED_USER_EMAIL = 10008,
  EMAIL_NOT_EXIST = 10011,
  // Redemption Related
  REDEMPTION_TOKEN_INVALID = 30001,
  INSUFFICIENT_REDEMPTION_CHANCE = 30002,
  REDEMPTION_TOKEN_USED = 30003,
  NONREDEEMED_ITEM = 30004,
  UNMATCH_TIER = 30005,
  UNLOCKED_CONTENT = 30006,
}

export const setToken = (token: string) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

instance.interceptors.request.use(
  (config) => {
    // console.log("[AXIOS-REQUEST]: ", config);
    return config;
  },
  (error) => {
    // console.log("[AXIOS-REQUEST-ERROR]: ", error, error.response.data);

    return Promise.reject(error);
  }
);

// Overrided in RootNavigator effect
instance.interceptors.response.use(
  function (response) {
    // console.log(
    //   "[AXIOS-RESPONSE]: ",
    //   `$$$method$$$ = ${response.config.method}, $$$url$$$ = ${
    //     (response.config.baseURL, response.config.url)
    //   }, $$$header$$$ = ${JSON.stringify(
    //     response.headers
    //   )}, $$$data$$$ = ${JSON.stringify(response.data.data)}`
    // );

    return response;
  },
  function (error) {
    // console.log(
    //   "[AXIOS-RESPONSE-ERROR]: ",
    //   error,
    //   error.response.data,
    //   "$$$url$$$ = ",
    //   (error.response.config.baseURL, error.response.config.url)
    // );
    if ((error.code === undefined && error.message === 'Network Error') || error.code === 'ECONNABORTED') {
      return Promise.reject(new Error(i18next.t('systemMsg:NETWORK_ERROR')));
    }
    if (
      Object.values(AppErrorCode).includes(error.response?.data?.errorCode) &&
      error.response.data.errorCode !== 30004 &&
      error.response.data.errorCode !== 10002 &&
      error.response.data.errorCode !== 10010
    ) {
      const message = Object.keys(AppErrorCode)
        .find((key: any) => AppErrorCode[key] === error.response.data.errorCode)
        ?.toString();
      const msgString = i18next.t(`systemMsg:${message}`);
      window.alert(
        msgString
        // Object.keys(AppErrorCode).find(
        //   (key) => AppErrorCode[key] === error.response.data.errorCode,
        // ),
      );
      return Promise.reject(new Error(msgString));
    }
    const errMsg = error.response?.data?.message ?? error.message;
    return Promise.reject(new Error(errMsg));
  }
);

export default instance;
