import React from 'react';
import { useTranslation } from 'react-i18next';
import RoundButton from '../../../../components/RoundButton/RoundButton';
import './PendingCodeCard.scss';

interface Props {
	title?: string;
	usedChance?: number;
	totalChance?: number;
	expiryDate?: string;
	onChoose?: () => void;
	canChoose?: boolean;
}

const PendingCodeCard = ({
	title, usedChance, totalChance, expiryDate, onChoose, canChoose = true,
}: Props): JSX.Element => {
	const { t } = useTranslation();
	return (
		<div className="pending-code-card">
			<div className="pending-code-card-usage-container">
				<div className="pending-code-card-usage">
					{`(${usedChance}/${totalChance})`}
				</div>
			</div>
			<span className="pending-code-card-title">
				{title}
			</span>
			<span className="pending-code-card-date">
				{expiryDate}
			</span>
			<RoundButton
				className="pending-code-card-select"
				onClick={onChoose}
				text={t('redemption:choose')}
				disabled={!canChoose}
			/>
		</div>
	);
}

export default PendingCodeCard;
