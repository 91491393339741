import { AxiosResponse } from 'axios';
import { RedeemCodeResponse, ValidateCodeResponse } from '../models/RedemptionCodeResponse';
import axios from './AxiosInstance';

const redeemCode = (token: string): Promise<AxiosResponse<{ data: RedeemCodeResponse }>> => axios.post('redemption', {token});

const redeemReport = (reportId: string, token?: string) => {
  if (token) {
    return axios.post('redemption/unlock_report', {token, reportId});
  } else {
    return axios.post('redemption/unlock_report', {reportId});
  }
};
const redeemPackage = (token: string, packageId: string) =>
  axios.post('redemption/unlock_package', {token, packageId});

const fetchRedeemedCodeList = () => axios.get('redemption');

const fetchRedeemedReportsList = () => axios.get('transaction/report');

const validateCode = (token: string): Promise<AxiosResponse<{ data: ValidateCodeResponse }>> =>
  axios.post('redemption/validate_token', {token});

const RedemptionService = {
  redeemCode,
  redeemReport,
  redeemPackage,
  fetchRedeemedCodeList,
  fetchRedeemedReportsList,
  validateCode,
};

export default RedemptionService;
