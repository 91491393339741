// import {sha256} from 'js-sha256';
import CryptoHelper from "../helpers/CryptoHelper";
import axios from "./AxiosInstance";
import publicAxios from "axios";
import { PresignedUrlResponse } from "../models/PresignedUrlResponse";
import { Photo } from "../models/Photo";
import Config from "../configs/Config";

const login = ({
  email,
  password,
}: // platform,
// appVersion,
{
  email: string;
  password: string;
  // platform: string;
  // appVersion: string;
}) => {
  const newPW = CryptoHelper.pwEncrypt(password);
  return axios.post("auth/login_user", {
    email: email.toLowerCase().trim(),
    password: newPW,
    // platform,
    // appVersion,
  });
  // console.log('password is', sha256(password));
  // console.log('platform is', platform);
  // console.log('appVersion', appVersion);
};

const register = ({
  email,
  password,
  firstName,
  lastName,
  phone,
}: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
}) => {
  return axios.post("auth/register_user", {
    email: email.toLowerCase().trim(),
    password: CryptoHelper.pwEncrypt(password),
    firstName,
    lastName,
    phone,
  });
  // console.log('password is', sha256(password));
  // console.log('phone is', phone);
  // console.log('firstName is', firstName);
  // console.log('lastName is', lastName);
};

const updateProfile = ({
  email, firstName, lastName, phone,
}: { email: string, firstName: string; lastName: string; phone: string }) => (
  axios.put('user', { email, firstName, lastName, phone })
);

const loginGuest = () => {
  return axios.post("auth/login_guest");
};

const forgetPassword = (email: string) =>
  axios.post("auth/reset_password", {
    email: email.toLowerCase().trim(),
  });

const refreshToken = (_refreshToken: string) =>
  axios.post("v1/user/refreshToken", {
    _refreshToken,
  });

const resetPassword = (email: string, newPassword: string, token: string) => {
  const newPW = CryptoHelper.pwEncrypt(newPassword);
  return axios.put("auth/reset_password", {
    email,
    password: newPW,
    token,
  });
};

const getUser = () => axios.get("user/self");

const changeLanguage = (language: string) => axios.put("user", { language });

const changePropic = (propicUrl: string) => axios.put("user", { propicUrl });

const fetchPresignedUrl = (
  fileExtension: string
): Promise<{ data: { message: string; data: PresignedUrlResponse } }> =>
  axios.post(`user/propic`, {
    fileExtension,
  });

const uploadImage = (
  s3Data: PresignedUrlResponse,
  currentImage: File,
  imgType: string
): Promise<string> => {
  // console.log("uploadImage s3Data", s3Data);
  const { fileName, presignedUrl } = s3Data;
  const options = {
    headers: {
      "Content-Type": imgType,
      "x-amz-acl": "public-read",
      // 'x-amz-meta-key': '12345',
      // 'x-amz-meta-otherInfo': 'other_data',
    },
  };

  // console.log("uploadImage presignedUrl", presignedUrl);
  // console.log(
  //   "upload file name",
  //   `${Config.IMAGE_BUCKET_URL}${fileName}`
  // );

  return publicAxios
    .put(presignedUrl, currentImage, options)
    .then(() => `${Config.IMAGE_BUCKET_URL}${fileName}`);
};

const postImage = (
  imageFile: any
  // gameRecordId?: string,
): Promise<Photo> => {
  return new Promise((resolve) => {
    // console.log("postImage imageFile", imageFile);
    // imageFile.then((file: any) => {
    fetchPresignedUrl("jpeg").then((s3Data) => {
      // console.log("fetchPresignedUrl s3Data", s3Data.data.data);
      uploadImage(s3Data.data.data, imageFile, imageFile.type)
        .then((imgUrl: string) => {
          // console.log("uploadImage return imgUrl is", imgUrl);
          changePropic(imgUrl).then((res: any) => resolve(res));
        })
        .catch((err) => console.log("uploadImage Error", err.response));
    });
    // });
  });
};

const getSystemProperty = () => axios.get("system");

const getTransactionHistory = (page = 1) => axios.get("transaction/history", { params: { page } });

const UserService = {
  login,
  loginGuest,
  register,
  updateProfile,
  forgetPassword,
  refreshToken,
  resetPassword,
  changeLanguage,
  fetchPresignedUrl,
  postImage,
  getSystemProperty,
  getUser,
  getTransactionHistory,
};

export default UserService;
