import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import CommonHeader from '../../../components/CommonHeader/CommonHeader';
import MainLayout from '../../../components/MainLayout/MainLayout';
import TransactionRecord from '../../../models/TransactionRecord';
import UserService from '../../../services/UserService';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClipLoader } from 'react-spinners';
import EntryCard from '../components/EntryCard/EntryCard';
import './ProfileHistory.scss';

interface DisplayRecord {
	month: string;
	records: TransactionRecord[];
}

const ProfileHistory = (): JSX.Element => {
	const { t, i18n: { language } } = useTranslation();

	const [records, setRecords] = useState<TransactionRecord[] | null>(null);
	const [recordsByMonth, setRecordsByMonth] = useState<DisplayRecord[]| null>(null);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [fetchError, setFetchError] = useState<string | null>(null);

	/* Infinite Scroll */
	const onFetchRecord = useCallback(() => {
		UserService.getTransactionHistory(currentPage)
			.then(({ data: { data: { data, totalPage: resTotalPage } } }) => {
				setRecords([...(records ?? []), ...data]);
				setCurrentPage(currentPage + 1);
				setTotalPage(resTotalPage ?? 1);
			})
			.catch((err) => setFetchError(err.message))
	}, [currentPage, records]);

	// Group records by month
	useEffect(() => {
		if (records) {
			const grouped = groupBy(records, (record) => moment(record.createdAt).format('YYYY MMMM|YYYYMM'));
			// Sort in desc order
			const display: DisplayRecord[] = Object.entries(grouped)
				.sort((a, b) => Number(b[0].split('|')[1]) - Number(a[0].split('|')[1]))
				.map(([month, records]) => ({ month: month.split('|')[0], records }));
			setRecordsByMonth(display);
		}
	}, [records]);

	// Initial fetch
	useEffect(() => {
		if (!records && !fetchError) {
			onFetchRecord();
		}
	}, [fetchError, onFetchRecord, records]);

	const renderRecords = (entry: TransactionRecord): React.ReactNode => {
		if (entry.reports?.length) {
			return entry.reports.map((r) => (
				<EntryCard
					key={r.id}
					title={r.title[language] ?? ''}
					subtitle={moment(entry.createdAt).format('DD/MM/YYYY')}
				/>
			));
		}
		if (entry.membership || entry.package) {
			return (
				<EntryCard
					title={entry.membership?.title?.[language] ?? entry.package?.name?.[language] ?? ''}
					subtitle={moment(entry.createdAt).format('DD/MM/YYYY')}
				/>
			);
		}
		return null;
	};

	return (
		<MainLayout title={t('myProfile:history')}>
			<CommonHeader />
			<div className="page-main tabs-page-align">
				<div className="width-control">
					<h2>{t('myProfile:history')}</h2>
					<div className="profile-history-page-content">
						<InfiniteScroll
							loader={<div className="profile-history-list-center"><ClipLoader /></div>}
							hasMore={currentPage <= totalPage}
							next={onFetchRecord}
							dataLength={records?.length ?? 0}
						>
							{recordsByMonth?.map((rs) => (
								<div key={rs.month} className="profile-history-list-group">
									<h3>{rs.month}</h3>
									<div className="profile-history-list-records">
										{rs.records.map((r) => renderRecords(r))}
									</div>
								</div>
							))}
						</InfiniteScroll>
					</div>
				</div>
			</div>
		</MainLayout>
	);
};

export default ProfileHistory;
