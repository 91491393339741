import React from "react";
// import { StaticImage } from 'gatsby-plugin-image';
import InfoModal from "../InfoModal/InfoModal";
import RoundButton from "../RoundButton/RoundButton";
import "./ErrorModal.scss";

interface Props {
  isOpen: boolean;
  msg?: string;
  onClose: () => void;
}

const ErrorModal = ({ isOpen, msg, onClose }: Props) => (
  <InfoModal isOpen={isOpen} onClose={onClose}>
    <div className="error-modal">
      <img
        src="../../images/cancel.png"
        alt="Error"
        width={80}
        height={80}
        placeholder="none"
      />
      <p className="error-modal-msg">{msg}</p>
      <RoundButton text="OK" onClick={onClose} />
    </div>
  </InfoModal>
);

export default ErrorModal;
