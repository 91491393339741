import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

interface Props {
	title?: string;
	path?: string;
}

const SEO = ({ title, path }: Props): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Helmet
			title={title ? `${title} - ${t('common:title')}` : t('common:title')}	
		/>
	);
};

export default SEO;
