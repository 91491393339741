import React from "react";
import moment from 'moment';
import { Link } from "react-router-dom";
import ItemCard from "../../../components/ItemCard/ItemCard";
import ItemSlider from "../../../components/ItemSlider/ItemSlider";
import { News } from "../../../models/News";
import { Report } from "../../../models/Report";
import styles from '../../../styles/modules/HomeTab.module.scss';
import { useTranslation } from "react-i18next";

interface Props {
  news?: News[];
  hotReports?: Report[];
  onViewAllReport?: () => void;
}

const HomeNews = ({ news, hotReports, onViewAllReport }: Props) => {
  const { t, i18n: { language } } = useTranslation();

  return (
    <div className={styles.homeTab}>
      <div className={styles.newsRow}>
        <div className={styles.newsHeader}>
          <h2 className={styles.title}>
            {t('home:hotReports')}
          </h2>
          <button type="button" className={styles.viewAll} onClick={onViewAllReport}>
            {t('home:viewAll')}
          </button>
        </div>
        <div className={styles.newsSlider}>
          {hotReports && (
            <ItemSlider>
              {hotReports.map((item) => (
                <ItemCard key={item.id} to={`/reports/${item.id}`} title={item.title[language]} img={item.imageUrl[language]} />
              ))}
            </ItemSlider>
          )}
        </div>
      </div>
      <div className={styles.newsRow}>
        <div className={styles.newsHeader}>
          <h2 className={styles.title}>
            {t('home:news')}
          </h2>
          <Link className={styles.viewAll} to="/news">
            {t('home:viewAll')}
          </Link>
        </div>
        <div className={styles.newsSlider}>
          {news && (
            <ItemSlider>
              {news.map((newsItem) => (
                <ItemCard
                  key={newsItem.id}
                  to={`/news/${newsItem.id}`}
                  title={newsItem.title[language]}
                  subTitle={moment(newsItem.updatedAt).format('DD-MM-YYYY, hh:mm a')}
                  arrow
                  img={newsItem.imageUrl[language]}
                />
              ))}
            </ItemSlider>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeNews;
