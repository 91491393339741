import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import CommonHeader from '../../../components/CommonHeader/CommonHeader';
import MainLayout from '../../../components/MainLayout/MainLayout';
import ProductMoreContent from '../../../models/ProductMoreContent';
import { ProductsActions } from '../../../store/Products';
import { selectMoreContent } from '../../../store/Products/selectors';
import './Experience.scss';

interface Props {
	moreContent?: ProductMoreContent[];
	fetchMoreContent: () => void;
}

const Experience = ({ moreContent, fetchMoreContent }: Props): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	const { t, i18n: { language } } = useTranslation();
	const [target, setTarget] = useState<ProductMoreContent>();
	const [error, setError] = useState<string | null>(null);

	useEffect(() => { 
		if (!moreContent) {
			fetchMoreContent();
		}
	}, [fetchMoreContent, moreContent]);
	useEffect(() => {
		if (moreContent) {
			const t = moreContent.find((m) => m.id === id);
			if (!t) {
				setError('Failed to find experience');
			} else {
				setTarget(t);
			}
		}
	}, [id, moreContent]);

	return (
		<MainLayout title={t('navigation:experience')}>
			<CommonHeader />
			<div className="page-main justify-align-center">
				<div className="width-control" id="product-experience-page">
					{error ? (
						<p>{error}</p>
					) : target ? (
						<>
							<video controls>
								<source src={target.videoUrl[language]} height={400} />
							</video>
							<h2>{target.name[language]}</h2>
						</>
					) : (
						<ClipLoader />
					)}
				</div>
			</div>
		</MainLayout>
	);
};

const mapStateToProps = (state: RootState ) => ({
	moreContent: selectMoreContent(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchMoreContent: () => dispatch(ProductsActions.fetchMoreContent()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Experience);
