import {action} from 'typesafe-actions';
import ProductMoreContent from '../../models/ProductMoreContent';

export const FETCH_PRODUCT_TYPES = 'product/FETCH_PRODUCT_TYPES';
export const FETCH_PRODUCT_TYPES_SUCCESS =
  'product/FETCH_PRODUCT_TYPES_SUCCESS';
export const FETCH_PRODUCT_TYPES_FAIL = 'product/FETCH_PRODUCT_TYPES_FAIL';

export const FETCH_MEMBERSHIP = 'product/FETCH_MEMBERSHIP';
export const FETCH_MEMBERSHIP_SUCCESS = 'product/FETCH_MEMBERSHIP_SUCCESS';
export const FETCH_MEMBERSHIP_FAIL = 'product/FETCH_MEMBERSHIP_FAIL';

export const FETCH_PACKAGE_ALL = 'product/FETCH_PACKAGE_ALL';
export const FETCH_PACKAGE_ALL_SUCCESS = 'product/FETCH_PACKAGE_ALL_SUCCESS';
export const FETCH_PACKAGE_ALL_FAIL = 'product/FETCH_PACKAGE_ALL_FAIL';

export const FETCH_MORE_CONTENT = 'product/FETCH_MORE_CONTENT';
export const FETCH_MORE_CONTENT_SUCCESS = 'product/FETCH_MORE_CONTENT_SUCCESS';
export const FETCH_MORE_CONTENT_FAIL = 'product/FETCH_MORE_CONTENT_FAIL';

// export const FETCH_STORE_PRODUCTS = 'product/FETCH_STORE_PRODUCTS';
// export const FETCH_STORE_PRODUCTS_SUCCESS =
//   'product/FETCH_STORE_PRODUCTS_SUCCESS';
// export const FETCH_STORE_PRODUCTS_FAIL = 'product/FETCH_STORE_PRODUCTS_FAIL';

export const fetchProductTypes = () => action(FETCH_PRODUCT_TYPES);
export const fetchProductTypesSuccess = (productTypes: any[]) =>
  action(FETCH_PRODUCT_TYPES_SUCCESS, {productTypes});
export const fetchProductTypesFail = (error: string) =>
  action(FETCH_PRODUCT_TYPES_FAIL, {error});

export const fetchMembership = () => action(FETCH_MEMBERSHIP);
export const fetchMembershipSuccess = (membership: any[]) =>
  action(FETCH_MEMBERSHIP_SUCCESS, {membership});
export const fetchMembershipFail = (error: string) =>
  action(FETCH_MEMBERSHIP_FAIL, {error});

export const fetchPackageAll = () => action(FETCH_PACKAGE_ALL);
export const fetchPackageAllSuccess = (packageAll: any[]) =>
  action(FETCH_PACKAGE_ALL_SUCCESS, {packageAll});
export const fetchPackageAllFail = (error: string) =>
  action(FETCH_PACKAGE_ALL_FAIL, {error});

export const fetchMoreContent = () => action(FETCH_MORE_CONTENT);
export const fetchMoreContentSuccess = (moreContent: ProductMoreContent[]) =>
  action(FETCH_MORE_CONTENT_SUCCESS, {moreContent});
export const fetchMoreContentFail = (error: string) =>
  action(FETCH_MORE_CONTENT_FAIL, {error});

// export const fetchStoreProducts = () => action(FETCH_STORE_PRODUCTS);
// export const fetchStoreProductsSuccess = (storeProducts: any[]) =>
//   action(FETCH_STORE_PRODUCTS_SUCCESS, {storeProducts});
// export const fetchStoreProductsFail = (error: string) =>
//   action(FETCH_STORE_PRODUCTS_FAIL, {error});
